import { FC, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { formatCurrency } from '../utils/formatCurrency'
import { Tracking, TrackingEvent } from '../utils/tracking'

import coinsIcon from '@/assets/coins-mobile.png'
import desktopBg from '@/assets/trading-competition-banner-desktop.png'
import mobileBg from '@/assets/trading-competition-banner-mobile.png'
import { appConfig } from '@/config'

type TradingCompetitionBannerProps = {
  prizePool: number
}

export const TradingCompetitionBanner: FC<TradingCompetitionBannerProps> = ({
  prizePool,
}) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const navigateToLeaderboard = useCallback(() => {
    navigate('/leaderboard')

    Tracking.triggerEvent(TrackingEvent.ClickTradingCompetitionBanner, {
      pathname,
    })
  }, [navigate, pathname])

  return (
    <div onClick={navigateToLeaderboard} className="w-full py-2 cursor-pointer">
      <div className="relative flex items-center justify-center h-[60px] md:h-[75px]">
        <picture className="w-full h-full absolute z-20 bg-cover bg-center bg-no-repeat">
          <source media="(max-width: 767px)" srcSet={mobileBg} />
          <source media="(min-width: 768px)" srcSet={desktopBg} />
          <img
            loading="lazy"
            fetchpriority="low"
            className="w-full rounded max-h-[60px] md:max-h-[75px]"
            src={desktopBg}
            alt="banner"
          />
        </picture>
        <div className="grow-1 pl-10 z-30 md:hidden">
          <img
            loading="lazy"
            className="transform scale-50"
            fetchpriority="low"
            src={coinsIcon}
            alt="coins"
          />
        </div>
        <div className="w-full h-full absolute z-30 flex justify-between items-center px-4 md:px-6">
          <div className="hidden md:flex flex-col justify-center font-semibold text-sm md:text-xl">
            <span>Trade {appConfig.marketName} to claim</span>
            <span>your {appConfig.currency} prize</span>
          </div>
          <div className="md:hidden flex flex-col justify-center font-semibold text-base">
            <span>Win your share of</span>
            <span className="text-primary-500">
              {formatCurrency(String(prizePool))}{' '}
              <span>{appConfig.currency}</span>!
            </span>
          </div>
          <div className="hidden ml-36 md:flex flex-col justify-center">
            <div className="flex flex-col font-bold">
              <span>Prize pool:</span>
              <span className="text-2xl text-primary-500">
                {formatCurrency(String(prizePool))}{' '}
                <span className="text-lg">{appConfig.currency}</span>
              </span>
            </div>
          </div>
          <div className="flex flex-col justify-center font-semibold md:font-bold">
            <span className="text-xs md:text-base py-1.5 px-2.5 md:py-1 md:px-5 rounded-full border-[1px] border-white">
              Race now
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
