import { FC, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { cx } from 'class-variance-authority'

import { SortColumn, SortMethod } from '../types'
import { Button } from './common/Button'

import { TriangleDown } from '@/assets/TriangleDown'
import { TriangleUp } from '@/assets/TriangleUp'

type SortControllerProps = {
  className?: string
  title: string
  columnName: SortColumn
  changeSortMethod: (sortMethodOption: SortMethod) => void
}

export const SortController: FC<SortControllerProps> = ({
  className = '',
  title,
  columnName,
  changeSortMethod,
}) => {
  const [searchParams] = useSearchParams()
  const sortType = searchParams.get('type')

  const toggleSort = useCallback(() => {
    let nextSortOrder: SortMethod
    const ascending: SortMethod = `${columnName}-asc`
    const descending: SortMethod = `${columnName}-desc`

    if (columnName === 'price') {
      if ((sortType && sortType !== descending) || sortType === ascending) {
        nextSortOrder = descending
      } else if (!sortType || sortType === descending) {
        nextSortOrder = ascending
      }
    } else if (columnName === 'change-24h') {
      if (sortType !== 'gainers') {
        nextSortOrder = 'gainers'
      } else if (sortType === 'gainers') {
        nextSortOrder = 'losers'
      }
    } else {
      if (sortType !== descending) {
        nextSortOrder = descending
      } else if (sortType === descending) {
        nextSortOrder = ascending
      }
    }

    changeSortMethod(nextSortOrder)
  }, [changeSortMethod, columnName, sortType])

  return (
    <Button
      className={`!h-auto !p-0 text-cool-gray-400 text-wrap ${className}`}
      size="sm"
      variant="ghost"
      onClick={toggleSort}
    >
      {title}
      <div className="flex flex-col -mr-1">
        {columnName === 'change-24h' &&
        (sortType === 'gainers' || sortType === 'losers') ? (
          <>
            <TriangleUp
              className={cx('-mb-1', {
                'text-white': sortType === `losers`,
              })}
            />
            <TriangleDown
              className={cx('-mt-1', {
                'text-white': sortType === `gainers`,
              })}
            />
          </>
        ) : (
          <>
            <TriangleUp
              className={cx('-mb-1', {
                'text-white': sortType === `${columnName}-asc`,
              })}
            />
            <TriangleDown
              className={cx('-mt-1', {
                'text-white':
                  (columnName === 'price' && !sortType) ||
                  sortType === `${columnName}-desc`,
              })}
            />
          </>
        )}
      </div>
    </Button>
  )
}
