import { FC, useMemo } from 'react'
import { cx } from 'class-variance-authority'

type RankProps = {
  rank: number
  isActiveTrader: boolean
}

export const Rank: FC<RankProps> = ({ rank, isActiveTrader }) => {
  const bgColor = useMemo(() => {
    if (rank === 1)
      return {
        base: 'bg-gold-500',
        dark: 'bg-gradient-to-b from-gold-800 to-gold-600',
      }
    if (rank === 2)
      return {
        base: 'bg-silver-500',
        dark: 'bg-gradient-to-b from-silver-800 to-silver-600',
      }
    if (rank === 3)
      return {
        base: 'bg-bronze-500',
        dark: 'bg-gradient-to-b bg-bronze-800 to-bronze-600',
      }

    return {
      base: '',
      dark: '',
    }
  }, [rank])

  if (rank < 4)
    return (
      <div
        className={`relative w-11 h-12 flex items-end rounded-md ${bgColor.base} p-[2px]`}
      >
        <div
          className={`w-full h-1/2 rounded-b-[5px] rounded-t-sm ${bgColor.dark}`}
        >
          <div className="w-full h-full animate-skew bg-[linear-gradient(75deg,transparent_35%,rgba(255,255,255,.3)_50%,transparent_65%)] bg-[length:250%,250%,100%_100%] bg-[position:-200%_0,0_0] bg-no-repeat transition-[background-position_0s_ease]" />
        </div>
        <span
          className={`absolute w-[1.375rem] h-[1.375rem] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center font-bold rounded-full ${bgColor.base}`}
        >
          {rank}
        </span>
      </div>
    )

  return (
    <div
      className={cx('flex justify-center items-center p-[2px]', {
        'w-11 h-12': isActiveTrader,
      })}
    >
      <span
        className={cx(
          '!w-10 !h-10 flex justify-center items-center font-bold',
          {
            'rounded-md bg-cool-gray-300 text-cool-gray-900': isActiveTrader,
          }
        )}
      >
        {rank}
      </span>
    </div>
  )
}
