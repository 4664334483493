import { useState } from 'react'
import { ExternalLinkIcon } from '@radix-ui/react-icons'
import truncateEthAddress from 'truncate-eth-address'
import { useAccount } from 'wagmi'

import { Button } from '@/core/components/common/Button'
import { Skeleton } from '@/core/components/common/Skeleton'
import { SubHeader as CoreSubHeader } from '@/core/components/SubHeader'

import { useVirtualList } from '../hooks/useVirtualList'
import { ShareDialog } from './ShareDialog'

import { ShareArrowIcon } from '@/assets/ShareArrowIcon'
import { appConfig } from '@/config'

export const SubHeader = ({
  walletId,
}: {
  walletId: `0x${string}` | null | undefined
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const account = useAccount()
  const accountWalletId = account?.address
  const { isLoading, isError } = useVirtualList({
    walletId,
    enabled: false,
  })

  return (
    <CoreSubHeader
      title="Portfolio"
      right={
        isLoading ? (
          <Skeleton className="w-24 h-2" />
        ) : walletId != accountWalletId && !isError ? (
          <Button
            variant="link"
            size="md"
            onClick={() =>
              window.open(`${appConfig.arbiscanUrl}/address/${walletId}`)
            }
          >
            <ExternalLinkIcon className="mr-1" />
            {truncateEthAddress(walletId || '')}
          </Button>
        ) : (
          <>
            <ShareDialog isOpen={isDialogOpen} setIsOpen={setIsDialogOpen} />
            {walletId && !isError && (
              <Button
                variant="link"
                size="md"
                className="cursor-pointer flex"
                onClick={() => setIsDialogOpen(true)}
              >
                <ShareArrowIcon className="text-white w-7 h-7 p-1 -ml-1" />
                Share
              </Button>
            )}
          </>
        )
      }
    />
  )
}
