import { FC } from 'react'

import { cn } from '@/core/utils/classNames'

interface ProgressBarProps {
  progress: number
}

export const ProgressBar: FC<ProgressBarProps> = ({ progress = 0 }) => {
  return (
    <div className="h-[0.125rem] w-full bg-cool-gray-700 relative">
      {progress > 0 && (
        <div
          className={cn(
            'bg-cool-gray-300 h-[0.125rem] animate-immediateFadeIn',
            {
              'transition-[width] duration-300': progress < 98,
            }
          )}
          style={{ width: `${progress < 98 ? progress.toFixed(0) : '100'}%` }}
        />
      )}
    </div>
  )
}
