import { useMemo } from 'react'

import { useShare as useCoreShare } from '@/core/hooks/useShare'

import { appConfig } from '@/config'

export const useShare = () => {
  const titles = useMemo(
    () => [
      'Road to $RPK ballin’ with #CreatorSTOX 💰💥',
      'Their influence pays me off big time 🤫 #CreatorSTOX',
      'Invested in the future of creators, and it’s looking bright ✨',
      'My #CreatorSTOX trades are paying off, LFG here guys 🫡',
      'Supporting the visionaries, and reaping the rewards 🎯',
      'Riding the #CreatorSTOX wave. You coming?',
      'Influence pays. Literally. So does trading influence. 😎 #CreatorSTOX',
      'Flexing my #CreatorSTOX portfolio, who’s on yours?',
      'Holding onto talent, cashing in on creativity ✨💰',
    ],
    []
  )
  const tags = useMemo(
    () =>
      [
        `#Creator${appConfig.marketName}`,
        '#RepubliK',
        '@RepubliK_GG',
        '$RPK',
        '#CryptoFlex',
        '#HODL',
      ].join(' '),
    []
  )

  const {
    onShareClick,
    randomizeTitle,
    title,
    url,
    onNativeShareClick,
    hasNativeShare,
  } = useCoreShare({
    titles,
    tags,
  })

  return {
    onShareClick,
    randomizeTitle,
    title,
    url,
    tags,
    onNativeShareClick,
    hasNativeShare,
  }
}
