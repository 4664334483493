import { useMemo } from 'react'

import { useShare as useCoreShare } from '@/core/hooks/useShare'

import { Socials } from './useTokenDetails'

import { appConfig } from '@/config'

export const useShare = ({
  username,
  socials,
}: {
  username?: string
  socials: Socials | undefined
}) => {
  const twitterHandle = useMemo(
    () => socials?.['twitter']?.username || '',
    [socials]
  )
  const instagramHandle = useMemo(
    () => socials?.['instagram']?.username || '',
    [socials]
  )
  const tiktokHandle = useMemo(
    () => socials?.['tiktok']?.username || '',
    [socials]
  )

  const titles = useMemo(
    () => [
      `💎 HODL tight, trade right with @${username}’s #${appConfig.marketName} 💎`,
      `Bullish on this one! Snag @${username}’s #${appConfig.marketName} 🐂📈🔥`,
      `Who wants some moonbags? 🌕💰 Get ’em trading @${username}’s #${appConfig.marketName}!`,
      `Scary 🐻 bears 🐻 ahead? Not with @${username}’s #${appConfig.marketName}. Let the bulls stampede! 🐂🐂`,
      `Well, whale, well. Grab a chunk of @${username}’s #${appConfig.marketName} before 🐳 turns into 🐋🐋.`,
      `🗣️ Ape! 🗣️ Ape! 🗣️ Ape! 🗣️ Ape in before @${username}’s #${appConfig.marketName} leaves orbit 🪐🦍`,
      `Wen moon? Maybe now! @${username}’s #${appConfig.marketName} just hit the market. 🚀🌕`,
      `Ape strong! 🦍 Ape think. 🦍 Ape smart! 🦍 You’re better cause you’re about to ape in on @${username}’s #${appConfig.marketName} 💰💰`,
      `Listen up. Don’t sleep on this one, go. full. degen. @${username}’s #${appConfig.marketName} just went live 🔥🔥`,
      `🤫 Here’s the alpha: @${username}’s #${appConfig.marketName} newly minted. Now move fast. Don’t trade last ⏳💥`,
      `Tiktok tiktok, sorry that’s just the clock before @${username}’s #${appConfig.marketName} bends the market. ⏰📈`,
    ],
    [username]
  )
  const tags = useMemo(() => {
    const rawTags = new Set([
      `#Creator${appConfig.marketName}`,
      '#RepubliK',
      '@RepubliK_GG',
      '$RPK',
    ])

    if (twitterHandle) {
      rawTags.add(`@${twitterHandle}`)
    }

    if (instagramHandle) {
      rawTags.add(`@${instagramHandle}`)
    }

    if (tiktokHandle) {
      rawTags.add(`@${tiktokHandle}`)
    }

    return Array.from(rawTags).join(' ')
  }, [twitterHandle, instagramHandle, tiktokHandle])

  const {
    onShareClick,
    randomizeTitle,
    title,
    url,
    onNativeShareClick,
    hasNativeShare,
  } = useCoreShare({
    titles,
    tags,
  })

  return {
    onShareClick,
    randomizeTitle,
    twitterHandle,
    title,
    url,
    tags,
    onNativeShareClick,
    hasNativeShare,
  }
}
