import { formatDistanceToNowStrict } from 'date-fns'

export const getTimeDifferenceString = (dateString: string) => {
  if (!dateString) return ''
  const givenDate = new Date(dateString)

  const distance = formatDistanceToNowStrict(givenDate, { addSuffix: false })
  const formattedDistance = distance
    .replace(/ months?/g, 'M')
    .replace(/ years?/g, 'Y')
    .replace(/ weeks?/g, 'W')
    .replace(/ days?/g, 'D')
    .replace(/ hours?/g, 'h')
    .replace(/ minutes?/g, 'm')
    .replace(/ seconds?/g, 's')
    .replace(/\s/g, '')

  return formattedDistance
}
