import { useEffect, useRef } from 'react'
import {
  createChart,
  LastPriceAnimationMode,
  LineStyle,
  Time,
} from 'lightweight-charts'

import { Activity } from '../../hooks/useActivities'

export const ChartComponent = ({
  type,
  activities,
}: {
  type: 'SELL' | 'BUY'
  activities: Activity[]
}) => {
  const chartContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const chart = createChart(chartContainerRef.current || '', {
      autoSize: true,
      layout: {
        background: { color: '#131820' },
        textColor: '#DDD',
      },
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
        },
      },
      timeScale: {
        borderVisible: false,
      },
      rightPriceScale: {
        borderVisible: false,
      },
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: true,
          labelBackgroundColor: '#7000ff',
        },
        vertLine: {
          labelVisible: false,
          style: LineStyle.Solid,
          width: 1,
        },
      },
      handleScale: false,
      handleScroll: false,
    })

    const areaSeries = chart.addAreaSeries({
      lineColor: '#7000ff',
      topColor: '#7000ff',
      bottomColor: 'rgba(112, 0, 255, 0)',
      lastPriceAnimation: LastPriceAnimationMode.Continuous,
      priceLineVisible: true,
      priceLineColor: type == 'SELL' ? '#ff4961' : '#00c080',
      priceLineStyle: LineStyle.Dashed,
    })

    const data = activities
      .map((item) => {
        const date = new Date(item.time)
        const time = date.getTime() / 1000
        return { time, value: item.price }
      })
      .sort((a, b) => a.time - b.time)
      .filter((item, index, self) => {
        return index === 0 || item.time !== self[index - 1].time
      }) as unknown as {
      time: Time
      value: number
    }[]

    areaSeries.setData(data)

    chart.timeScale().fitContent()

    chart.applyOptions({
      watermark: {
        visible: true,
        fontSize: 16,
        horzAlign: 'left',
        vertAlign: 'top',
        color: 'rgb(158,173,199, .10)',
        text: 'RepubliK STOX Market',
      },
    })

    return () => chart.remove()
  }, [type, activities])

  return <div className="w-full h-40" ref={chartContainerRef} />
}
