import { FC } from 'react'

import { Skeleton } from '@/core/components/common/Skeleton'

export const RowSkeleton: FC = () => {
  return (
    <div className="flex items-center gap-x-2">
      <div className="flex items-center flex-auto">
        <Skeleton className="w-8 h-8 !rounded-full my-2 p-2" />
        <div className="flex flex-col justify-start">
          <Skeleton className="w-28 h-3 ml-2 md:h-2.5" />
          <Skeleton className="w-20 h-3 ml-2 md:h-3 mt-2 md:hidden" />
        </div>
      </div>
      <div className="w-10 md:w-20 hidden md:block">
        <Skeleton className="w-full h-2.5" />
      </div>
      <div className="md:w-16 hidden md:block">
        <Skeleton className="w-full h-2.5" />
      </div>
      <div className="flex-none md:w-24 text-right hidden md:block">
        <Skeleton className="flex flex-row w-full h-2.5" />
      </div>
      <div className="flex-none md:w-24 text-right hidden md:block">
        <Skeleton className="w-full h-2.5 my-2" />
        <Skeleton className="w-full h-2.5 my-2" />
      </div>
      <div className="w-14 md:w-20 md:hidden">
        <Skeleton className="w-full h-2.5 my-2" />
        <Skeleton className="w-full h-2.5 my-2" />
      </div>
      <div className="w-24 md:w-28 flex justify-end">
        <Skeleton className="w-full h-[28px] md:w-24" />
      </div>
    </div>
  )
}
