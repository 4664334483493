import { FC } from 'react'

import { Skeleton } from '@/core/components/common/Skeleton'

export const RowSkeleton: FC = () => {
  return (
    <div className="w-full h-12 flex flex-row items-center">
      <div className="w-24">
        <Skeleton className="w-6 h-2 flex" />
      </div>
      <div className="w-full h-12 flex flex-row justify-between items-center border-b border-b-cool-gray-850">
        <Skeleton className="w-24 md:w-32 h-2" />
        <Skeleton className="w-20 md:w-24 h-2 mr-6" />
      </div>
    </div>
  )
}
