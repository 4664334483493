import { FC, useEffect, useMemo } from 'react'
import { Navigate, useOutletContext, useParams } from 'react-router-dom'
import { useAccount } from 'wagmi'

import { SubHeaderType } from '@/core/layouts/MainLayout'

import { VirtualList } from '../components'
import { SubHeader } from '../components/SubHeader'

export const Portfolio: FC = () => {
  const { setSubHeader } = useOutletContext<SubHeaderType>()
  const account = useAccount()
  const walletId = account?.address
  const { walletId: walletIdParam } = useParams<{ walletId: `0x${string}` }>()

  const currentWalletId = useMemo(
    () => (walletIdParam || walletId || null) as `0x${string}`,
    [walletId, walletIdParam]
  )
  useEffect(() => {
    setSubHeader(<SubHeader walletId={currentWalletId} />)
  }, [walletId, currentWalletId, setSubHeader])

  if (!walletIdParam && account.isConnected) {
    return <Navigate replace to={`/portfolio/${walletId}`} />
  }

  return <VirtualList walletId={currentWalletId} />
}
