export const formatPercentageValue = (
  value: number,
  min = 2,
  hasPrefix = true
) => {
  const option = {
    style: 'percent',
    minimumFractionDigits: min,
    maximumFractionDigits: 2,
  } as Intl.NumberFormatOptions

  const formatter = new Intl.NumberFormat('en-US', option)
  const formattedNumber = formatter.format(value / 100)
  return Number(value?.toFixed(2)) > 0
    ? `${hasPrefix ? '+' : ''}${formattedNumber}`
    : formattedNumber
}
