import { FC } from 'react'

import { Skeleton } from '@/core/components/common/Skeleton'

export const RowSkeleton: FC = () => {
  return (
    <div className="flex items-center gap-x-2">
      <div className="flex-none w-8">
        <Skeleton className="w-full h-2.5" />
        <Skeleton className="w-full md:hidden h-2.5 mt-2" />
      </div>
      <div className="flex items-center flex-auto">
        <Skeleton className="w-8 h-8 !rounded-full my-2 float-start p-2" />
        <Skeleton className="w-16 md:w-32 h-2.5 ml-2 md:h-2.5" />
      </div>

      <div className="flex-none w-10 md:w-16 hidden md:block">
        <Skeleton className="w-full h-2.5" />
      </div>
      <div className="flex-none w-32 hidden md:block">
        <Skeleton className="w-full h-2.5" />
      </div>

      <div className="flex-none w-16 md:w-20 md:hidden">
        <Skeleton className="w-full h-2.5 my-2" />
        <Skeleton className="w-full h-2.5 my-2" />
      </div>
      <div className="hidden md:flex md:w-20 justify-end">
        <Skeleton className="w-16 md:w-28 h-2.5" />
      </div>
      <div className="flex-none w-24 md:w-36 flex justify-end">
        <Skeleton className="w-full h-2.5" />
      </div>
    </div>
  )
}
