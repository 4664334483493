import { useQuery } from '@tanstack/react-query'

import API from '@/core/services/api'
import { SocialMedia } from '@/core/types'

export interface Socials
  extends Record<
    SocialMedia,
    { followers: number; username: string; type: string }
  > {}

export type TokenDetailsData = {
  userId: string
  tokenId: number
  username: string
  socials: Socials
  price: number
  priceChange24h: number
  priceChange7d: number
  priceChange30d: number
  holders: number
  createdAt: Date
  updatedAt: Date
  revenueShareInPercentage?: number
  accumulativeRevenueShare?: number
  totalBoost: number
}

export const useTokenDetails = (username?: string) => {
  const { data, isLoading, error, refetch } = useQuery<TokenDetailsData>({
    queryKey: ['tokenDetails', String(username)],
    queryFn: () => API.get(`/market/tokens/${username}`),
  })

  if (data) {
    const socials = { republik: { username: data?.username, followers: -1 } }

    Object.assign(data, { socials: { ...data?.socials, ...socials } })
  }

  return {
    data,
    isLoading,
    error,
    refetch,
  }
}
