import { useEffect } from 'react'
import { useOutletContext, useSearchParams } from 'react-router-dom'

import { SubHeaderType } from '@/core/layouts/MainLayout'

import { EmptyList } from '../components/EmptyList'
import { StickyList } from '../components/StickyList'
import { SubHeader } from '../components/SubHeader'
import { useLeaderboard } from '../hooks/useLeaderboard'

export const Leaderboard = () => {
  const [searchParams] = useSearchParams()
  const { setSubHeader } = useOutletContext<SubHeaderType>()
  const { data, isLoading, isError, isSuccess, refetch } = useLeaderboard({
    race: searchParams.get('race') ?? '',
  })

  useEffect(() => {
    setSubHeader(
      <SubHeader data={data} isLoading={isLoading} isError={isError} />
    )
  }, [data, setSubHeader, isLoading, isError])

  if (isError) {
    return (
      <EmptyList
        refetch={refetch}
        title="Race Not Found 🏁"
        subtitle="Something went wrong..."
        withRedirectButton={false}
      />
    )
  }

  if (isSuccess && data?.items?.length === 0) {
    return (
      <EmptyList
        refetch={refetch}
        title={`Trading Season ${data?.race} has started!`}
        subtitle="No trades have been made yet this season. Start trading now to lead the
  pack and set the pace for others to follow!"
      />
    )
  }

  return <StickyList refetch={refetch} data={data} isLoading={isLoading} />
}
