import { FC, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useAccount } from 'wagmi'

import { Button } from './common/Button'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from './common/Sheet'
import { GetCurrencyButton } from './GetCurrencyButton'
import { GetCurrencyDrawer } from './GetCurrencyDrawer'

import { CloseIcon } from '@/assets/CloseIcon'
import logo from '@/assets/logo.svg'
import { appConfig } from '@/config'

export const SidebarMenu: FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const account = useAccount()
  const walletId = account?.address

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger
        data-collapse-toggle="navbar-sticky"
        type="button"
        className="inline-flex items-center py-2 w-10 h-10 justify-center text-sm text-cool-gray-300 rounded-lg md:hidden"
        aria-controls="navbar-sticky"
        aria-expanded="false"
      >
        <span className="sr-only">Open main menu</span>
        <svg
          className="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </SheetTrigger>
      <SheetContent
        side="left"
        className="flex flex-col justify-between bg-cool-gray-900 border-none"
      >
        <SheetHeader>
          <SheetTitle className="ml-4 mb-12 safe-area-top">
            <Link to="/" onClick={() => setIsOpen(false)}>
              <div className="flex flex-row justify-end">
                <CloseIcon
                  className="w-7 h-7 mb-2 mr-4 cursor-pointer"
                  onClick={() => setIsOpen(false)}
                />
              </div>
              <div className="w-8 mb-3">
                <img
                  fetchpriority="high"
                  className="w-full"
                  src={logo}
                  width={30}
                  height={30}
                  alt="RepubliK logo"
                />
              </div>
              <div className="flex flex-col text-left">
                <span className="uppercase text-sm text-cool-gray-300 font-normal mb-1">
                  RepubliK
                </span>
                <span className="text-2xl font-bold">
                  {appConfig.marketName} Market
                </span>
              </div>
            </Link>
          </SheetTitle>
          <SheetDescription className="flex flex-col items-start gap-4 text-lg text-cool-gray-200 text-left">
            <NavLink
              to={walletId ? `/portfolio/${walletId}` : '/portfolio'}
              onClick={() => setIsOpen(false)}
              className={({ isActive }) => (isActive ? 'text-white' : '')}
            >
              <Button variant="ghost">My Portfolio</Button>
            </NavLink>
            <NavLink
              to="/history"
              onClick={() => setIsOpen(false)}
              className={({ isActive }) => (isActive ? 'text-white' : '')}
            >
              <Button variant="ghost">My Trades</Button>
            </NavLink>
            <NavLink
              to="/leaderboard"
              onClick={() => setIsOpen(false)}
              className={({ isActive }) => (isActive ? 'text-white' : '')}
            >
              <Button variant="ghost">Leaderboard</Button>
            </NavLink>
            {appConfig.showBuyCurrencyDrawer ? (
              <GetCurrencyDrawer className="mt-8 mx-4" />
            ) : (
              <GetCurrencyButton className="mt-8 mx-4" />
            )}
          </SheetDescription>
        </SheetHeader>
        <SheetFooter className="safe-area-bottom">
          <span className="text-xs text-cool-gray-400">
            © {new Date().getFullYear()} Rad RepubliK Pte Ltd.
            <br />
            All Rights Reserved.
          </span>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
