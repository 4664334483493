import { FC, useEffect } from 'react'

import { Card } from '@/core/components/common/Card'
import { InfoHoverCard } from '@/core/components/InfoHoverCard'

import { useCountdown } from '../hooks/useCountdown'
import { TransactionData } from '../hooks/useTransaction'
import { Countdown } from './Countdown'
import { PriceCountdown } from './PriceCountdown'
import { BoosterCardSkeleton } from './skeletons/BoosterCardSkeleton'

import { Hourglass } from '@/assets/Hourglass'
import { appConfig } from '@/config'

interface BoosterCardProps {
  transactionData: TransactionData | null
  isTransactionLoading: boolean
  refetchTransaction: () => void
}

export const BoosterCard: FC<BoosterCardProps> = ({
  transactionData,
  isTransactionLoading,
  refetchTransaction,
}) => {
  const { timeLeft } = useCountdown({
    distributionTimeElapsed: transactionData?.distributionTimeElapsed || 0n,
    distributionTimePeriod: transactionData?.distributionTimePeriod || 0n,
  })

  useEffect(() => {
    if (timeLeft === 0n) {
      refetchTransaction()
    }
  }, [timeLeft, refetchTransaction])

  if (isTransactionLoading) {
    return <BoosterCardSkeleton />
  }

  return (
    <Card hasAnimatedBg={!!transactionData?.pendingDistribution}>
      <div className="-m-4 sm:-m-6 sm:-my-6 absolute w-full h-full flex items-center justify-center -z-0 text-6xl bg-gradient-to-r bg-clip-text text-transparent from-cool-gray-800 via-cool-gray-700 to-cool-gray-800 animate-text border-1 bg-[length:200%_auto] text-right animate-linear">
        ⚡
      </div>
      <ul role="list" className="-my-2">
        <li>
          <div className="flex flex-row">
            <div className="pt-1 flex flex-col min-w-0 justify-start text-cool-gray-300 z-10">
              <div>
                <InfoHoverCard title="Price boost">
                  This displays the amount of {appConfig.currency} in the
                  running Price Boost. It increases whenever the creator
                  contributes earnings to the {appConfig.marketName}
                  Price Booster, thereby raising the creator&apos;s{' '}
                  {appConfig.marketName} price in real-time.
                </InfoHoverCard>
              </div>
              <div>
                <span className="flex items-center">
                  <Hourglass
                    className="mr-1"
                    animated={!!transactionData?.pendingDistribution}
                  />
                  <Countdown timeLeft={timeLeft || 0n} />
                </span>
              </div>
            </div>
            <div className="flex-1">
              <div className="text-2xl font-semibold text-white">
                <div className="w-full justify-end">
                  <div className="text-2xl font-semibold border-1 flex flex-row items-baseline justify-end">
                    <div className="flex">
                      <PriceCountdown
                        key={transactionData?.pendingDistribution || 0n}
                        price={transactionData?.pendingDistribution || 0n}
                        timeLeft={timeLeft || null}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </Card>
  )
}
