import { intervalToDuration } from 'date-fns'

export const formatDurationFromSeconds = (seconds: bigint | number) => {
  const duration = intervalToDuration({ start: 0, end: Number(seconds) * 1000 })
  const parts = []

  if (duration.months && duration.months > 0) parts.push(`${duration.months}M`)
  if (duration.weeks && duration.weeks > 0) parts.push(`${duration.weeks}W`)
  if (duration.days && duration.days > 0) parts.push(`${duration.days}D`)
  if (duration.hours && duration.hours > 0) parts.push(`${duration.hours}h`)
  if (duration.minutes && duration.minutes > 0)
    parts.push(`${duration.minutes}m`)
  if ((duration.seconds && duration.seconds > 0) || parts.length === 0)
    parts.push(`${duration.seconds || 0}s`)

  return parts.join(' ')
}
