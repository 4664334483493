import { Button } from './Button'

interface NumberSelectorProps {
  step?: number
  min: number
  max: number
  value: number
  onChange: (value: number) => void
  label: string
}

export const NumberSelector = ({
  step = 1,
  min,
  max,
  value,
  onChange,
  label,
}: NumberSelectorProps) => {
  const handleIncrement = () => {
    if (value < max) {
      onChange(value + step)
    }
  }

  const handleDecrement = () => {
    if (value > min) {
      onChange(value - step)
    }
  }

  return (
    <div className="w-full">
      <div className="flex items-center justify-center space-x-2">
        <Button
          variant="outline"
          size="icon"
          className="h-8 w-8 shrink-0 rounded-full"
          onClick={handleDecrement}
          disabled={value <= min}
        >
          {/* <MinusIcon className="h-4 w-4" /> */} -
          <span className="sr-only">Decrease</span>
        </Button>
        <div className="flex-1 text-center">
          <div className="text-7xl font-bold tracking-tighter">{value}</div>
          <div className="text-[0.70rem] text-muted-foreground">{label}</div>
        </div>
        <Button
          variant="outline"
          size="icon"
          className="h-8 w-8 shrink-0 rounded-full"
          onClick={handleIncrement}
          disabled={value >= max}
        >
          {/* <PlusIcon className="h-4 w-4" /> */} +
          <span className="sr-only">Increase</span>
        </Button>
      </div>
    </div>
  )
}
