import { FC } from 'react'

import { Skeleton } from '@/core/components/common/Skeleton'

export const RowSkeleton: FC = () => {
  return (
    <div className="flex items-center text-xs md:text-sm gap-x-2">
      <div className="flex items-center flex-auto">
        <Skeleton className="w-8 h-8 !rounded-full m-2 ml-0 float-start p-2" />
        <div className="flex flex-col">
          <Skeleton className="w-28 h-3 ml-2 md:h-2.5" />
          <Skeleton className="w-20 h-3 ml-2 md:hidden mt-1" />
        </div>
      </div>
      <div className="flex-none w-10 md:w-20 hidden md:block">
        <div className="flex flex-row items-center justify-end">
          <Skeleton className="flex flex-row w-full h-2.5 items-center justify-end" />
        </div>
      </div>
      <div className="flex-none md:w-16 hidden md:block">
        <Skeleton className="w-full h-2.5" />
      </div>
      <div className="md:w-24 hidden md:flex flex-col justify-end items-end ">
        <Skeleton className="w-full h-2.5 my-1" />
        <Skeleton className="w-full h-2.5 my-1" />
      </div>
      <div className="flex-col md:w-24 hidden md:flex justify-end items-end">
        <Skeleton className="w-full h-2.5 my-1" />
        <Skeleton className="w-full h-2.5 my-1" />
      </div>
      <div className="flex-col w-16 md:hidden ">
        <Skeleton className="w-full h-2.5 my-1" />
        <Skeleton className="w-full h-2.5 my-1" />
      </div>
      <div className="flex-col w-20 md:w-32 md:hidden justify-end items-end">
        <Skeleton className="w-full h-2.5 my-1" />
        <Skeleton className="w-full h-2.5 my-1" />
      </div>
      <div className="flex-none w-24 md:w-32 md:flex justify-end hidden">
        <Skeleton className="w-full md:w-24 h-[28px]" />
      </div>
    </div>
  )
}
