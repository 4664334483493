import { FC, ReactNode } from 'react'

import { BackButton } from './BackButton'

type SubHeaderProps = {
  title?: ReactNode
  left?: ReactNode
  right?: ReactNode
  bottom?: ReactNode
}

export const SubHeader: FC<SubHeaderProps> = ({
  title,
  left,
  right,
  bottom,
}) => {
  return (
    <div className="max-w-3xl mx-auto px-4 md:px-6">
      <div className="flex-row pt-1 justify-center items-center grid grid-cols-3">
        <div className="flex-1 space-x-2 w-10 md:w-auto">
          {left ?? <BackButton />}
        </div>
        <div className="flex flex-1 text-lg font-bold items-center justify-center">
          {title}
        </div>
        {right != null && (
          <div className="flex flex-1 justify-end">{right}</div>
        )}
      </div>
      {bottom != null && (
        <div className="flex justify-center pb-2">{bottom}</div>
      )}
    </div>
  )
}
