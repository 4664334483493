import { FC } from 'react'

import { Button } from '@/core/components/common/Button'
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@/core/components/common/Dialog'

import { ShareArrowIcon } from '@/assets/ShareArrowIcon'
import { XIcon } from '@/assets/XIcon'
import { appConfig } from '@/config'
interface ShareDialogProps {
  isOpen: boolean
  handleDismiss: (open: boolean) => void
  randomizeTitle: () => void
  hasNativeShare: boolean
  title: string
  url: string
  tags: string
  onNativeShareClick: (e: React.MouseEvent<HTMLSpanElement>) => void
  handleShareClick: (
    e: React.MouseEvent<HTMLSpanElement>,
    urlOnly?: boolean
  ) => void
}

export const ShareDialog: FC<ShareDialogProps> = ({
  isOpen,
  handleDismiss,
  randomizeTitle,
  hasNativeShare,
  title,
  url,
  tags,
  onNativeShareClick,
  handleShareClick,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={handleDismiss}>
      <DialogContent className="max-w-[27rem]">
        <DialogTitle>
          <div className="absolute -z-10 w-full h-full bg-cool-gray-850 bg-[url('/src/assets/mobile-bg@2x.png')] md:bg-[url('/src/assets/desktop-bg@2x.png')] bg-cover bg-center bg-no-repeat" />
          <div className="flex flex-col justify-center items-center text-center py-8 px-4">
            <span className="text-lg font-bold mb-4">Post Preview</span>
            <span className="text-xs pb-2 text-cool-gray-300">
              Tap on the the field to get random title
            </span>
            <div
              onClick={() => randomizeTitle()}
              className="cursor-pointer select-none mb-2 bg-cool-gray-800 border-[1px] border-cool-gray-700 p-4 text-left rounded-lg min-h-32 w-full"
            >
              <p className="text-sm">{title}</p>
              <p className="text-sm mb-4">
                <span className="underline break-all">{url}</span>
              </p>
              <p className="text-sm">{tags}</p>
            </div>
            <div className="space-x-2 space-y-2 pt-2">
              {hasNativeShare ? (
                <Button
                  size="sm"
                  variant="outline"
                  onClick={(e) => onNativeShareClick(e)}
                >
                  <ShareArrowIcon className="text-white w-7 h-7 p-1 -ml-1" />{' '}
                  Share {appConfig.marketName}
                </Button>
              ) : (
                <>
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={(e) => handleShareClick(e)}
                  >
                    Copy Post to Clipboard
                  </Button>
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={(e) => handleShareClick(e, true)}
                  >
                    Copy Link to Clipboard
                  </Button>
                  <Button
                    size="sm"
                    variant="purpleAnimatedOutline"
                    onClick={(e) => {
                      e.preventDefault()
                      window.open(
                        `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}%0a${encodeURIComponent(url)}%0a%0a${encodeURIComponent(tags)}`,
                        '_blank'
                      )
                    }}
                  >
                    Share on <XIcon className="ml-1" />
                  </Button>
                </>
              )}
            </div>
          </div>
        </DialogTitle>
      </DialogContent>
    </Dialog>
  )
}
