import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { VirtualItem } from '@tanstack/react-virtual'
import truncateEthAddress from 'truncate-eth-address'

import { TokenAvatar } from '@/core/components/TokenAvatar'
import { cn } from '@/core/utils/classNames'
import { formatCurrency } from '@/core/utils/formatCurrency'
import { getTimeDifferenceString } from '@/core/utils/getTimeDifferenceString'
import { rem } from '@/core/utils/utils'

import { IDataItem } from '../hooks/useVirtualList'

import { appConfig } from '@/config'

type RowProps = {
  item: IDataItem
  virtualRow: VirtualItem
}

export const Row: FC<RowProps> = ({ item, virtualRow }) => {
  const navigate = useNavigate()

  if (!item) return null

  return (
    <div
      key={virtualRow.index}
      className="flex items-center text-xs md:text-sm gap-x-2"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: rem(virtualRow.size),
        transform: `translateY(${rem(virtualRow.start)})`,
      }}
    >
      <span className="w-8 flex-none items-center hidden md:block">
        {getTimeDifferenceString(item?.time)}
      </span>

      <div className="flex-none md:hidden">
        <div
          className={cn(
            'font-bold',
            item?.side === 'BUY' ? 'text-success' : 'text-danger'
          )}
        >
          {item?.side}
        </div>
        <span className="w-8 flex items-center">
          {getTimeDifferenceString(item?.time)}
        </span>
      </div>

      <div
        className="flex items-center flex-auto cursor-pointer"
        style={{ height: rem(virtualRow.size) }}
        onClick={() => navigate(`/@${item.username}`)}
      >
        <TokenAvatar
          userId={item?.userId}
          username={item?.username}
          className="-ml-2"
        />
        <div className="flex flex-col justify-start">{item?.username}</div>
      </div>
      <div className="flex-none w-10 md:w-32 text-right hidden md:block">
        {item?.amount}
      </div>
      <div className="flex-none w-32 text-right hidden md:block">
        {formatCurrency(String(item?.value), 2)}
      </div>
      <div className="flex-none md:w-20 text-right md:hidden">
        <div className="font-bold">
          {formatCurrency(String(item?.value), 2)}
        </div>
        <div className="text-xs text-cool-gray-300">{item?.amount}</div>
      </div>
      <div
        className={cn(
          'w-20 md:block text-right font-bold hidden',
          item?.side === 'BUY' ? 'text-success' : 'text-danger'
        )}
      >
        {item?.side}
      </div>
      <div className="flex-none w-24 md:w-36 flex justify-end">
        <span
          className="underline cursor-pointer"
          onClick={() =>
            window.open(`${appConfig.arbiscanUrl}/tx/${item?.txHash}`)
          }
        >
          {item?.txHash && truncateEthAddress(item?.txHash)}
        </span>
      </div>
    </div>
  )
}
