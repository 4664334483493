import { FC } from 'react'

import { TokenAvatar } from '@/core/components/TokenAvatar'

import { TokenDetailsData } from '../hooks/useTokenDetails'
import { TokenDetailsSkeleton } from './skeletons/TokenDetailsSkeleton'
import { SocialMediaDetails } from './SocialMediaDetails'

import { appConfig } from '@/config'

type TokenInfoProps = {
  data?: TokenDetailsData
  isLoading: boolean
}

export const TokenInfo: FC<TokenInfoProps> = ({ data, isLoading }) => {
  if (isLoading || !data) {
    return <TokenDetailsSkeleton />
  }

  return (
    <div className="w-full block md:py-4">
      <div className="relative mb-2">
        <TokenAvatar
          variant="large"
          userId={data?.userId}
          username={data?.username}
          className="mr-4"
        />
        <div className="flex flex-col flex-1 mb-2">
          <span className="text-xs text-cool-gray-300 font-normal">
            {appConfig.marketName} ID: {data?.tokenId}
          </span>
          <div
            className="w-[90%] text-3xl font-bold mb-3 truncate"
            title={data?.username}
          >
            {data?.username}
          </div>
          <div className="text-sm text-cool-gray-300 mb-2">
            Meet {data?.username} on social media
          </div>
          <div className="w-full">
            <SocialMediaDetails socials={data?.socials} />
          </div>
        </div>
      </div>
    </div>
  )
}
