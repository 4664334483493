import { SocialMedia } from '@/core/types'

import { TokenModel } from '../hooks/useVirtualList'

export const getTotalFollowers = (
  socials = {} as TokenModel['socials'] | null | undefined
) => {
  if (!socials) return { connectedSocials: [], totalFollowers: 0 }
  let totalFollowers = 0
  const connectedSocials: SocialMedia[] = []

  for (const [key, value] of Object.entries(socials)) {
    connectedSocials.push(key as SocialMedia)
    totalFollowers += value.followers
  }

  return { connectedSocials: connectedSocials.sort(), totalFollowers }
}
