import { FC } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useAccount } from 'wagmi'

import { cn } from '@/core/utils/classNames'

import { GetCurrencyButton } from '../GetCurrencyButton'
import { GetCurrencyDrawer } from '../GetCurrencyDrawer'
import { SidebarMenu } from '../SidebarMenu'
import { ConnectButton } from './ConnectButton'

import logo from '@/assets/logo.svg'
import { appConfig } from '@/config'

export const TopNavBar: FC = () => {
  const account = useAccount()
  const walletId = account?.address

  return (
    <nav className="w-full z-20 top-0 start-0">
      <div className="max-w-screen-xl flex flex-nowrap items-center justify-between mx-auto px-4 md:px-6">
        <Link
          className="items-center space-x-3 rtl:space-x-reverse hidden md:flex md:order-1 cursor-pointer"
          to="/"
        >
          <div className="flex flex-row">
            <div className="w-8 md:w-12">
              <img
                fetchpriority="high"
                className="w-full"
                src={logo}
                width={30}
                height={30}
                alt="RepubliK logo"
              />
            </div>
            <div className="w-full pt-0 pl-2">
              <div className="uppercase text-xs text-cool-gray-400">
                RepubliK
              </div>
              <div className="font-bold text-xs">
                {appConfig.marketName} Market
              </div>
            </div>
          </div>
        </Link>
        <div className="flex md:order-2 rtl:space-x-reverse justify-between w-full md:max-w-fit items-center">
          <div className="order-1 flex flex-nowrap">
            {appConfig.showBuyCurrencyDrawer ? (
              <GetCurrencyDrawer className="order-1 mr-2" />
            ) : (
              <GetCurrencyButton className="order-1 mr-2" />
            )}
            <div className="order-2">
              <ConnectButton />
            </div>
          </div>
          <SidebarMenu />
        </div>
        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="navbar-sticky"
        >
          <ul className="flex flex-col items-center p-4 md:p-0 mt-4 text-center font-medium border rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:space-x-3">
            <li>
              <NavLink
                className={({ isActive }) =>
                  cn(
                    'block py-2 px-3 text-xs text-cool-gray-300 rounded md:bg-transparent md:p-2 cursor-pointer',
                    isActive ? 'text-white' : ''
                  )
                }
                to={walletId ? `/portfolio/${walletId}` : '/portfolio'}
              >
                Portfolio
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  cn(
                    'block py-2 px-3 text-xs text-cool-gray-300 rounded md:bg-transparent md:p-2 cursor-pointer',
                    isActive ? 'text-white' : ''
                  )
                }
                to="/history"
              >
                Trades
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  cn(
                    'block py-2 px-3 text-xs text-cool-gray-300 rounded md:bg-transparent md:p-2 cursor-pointer',
                    isActive ? 'text-white' : ''
                  )
                }
                to="/leaderboard"
              >
                Leaderboard
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
