import { forwardRef } from 'react'

import { cn } from '@/core/utils/classNames'

import { Button } from './Button'

import { CloseCircleIcon } from '@/assets/CloseCircleIcon'
import { SearchIcon } from '@/assets/SearchIcon'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  handleClear?: () => void
  searchQuery?: string
}

const SearchInput = forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, handleClear, searchQuery, ...props }, ref) => {
    return (
      <div className={cn('relative', className)}>
        <SearchIcon className="lucide lucide-search absolute left-2 top-2 h-4 w-4 text-muted-foreground" />
        <input
          type={type}
          className="flex bg-cool-gray-900 h-8 w-full rounded-md border border-input border-cool-gray-300 px-8 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50"
          ref={ref}
          {...props}
        />
        {searchQuery && (
          <Button
            variant="link"
            className="absolute top-0 right-0 p-0"
            size="sm"
            onClick={handleClear}
          >
            <CloseCircleIcon />
          </Button>
        )}
      </div>
    )
  }
)

SearchInput.displayName = 'SearchInput'

export { SearchInput }
