import { useMemo } from 'react'

import { Avatar, AvatarFallback, AvatarImage } from './common/Avatar'

import { appConfig } from '@/config'

export const TokenAvatar = ({
  variant = 'default',
  userId,
  className,
  username,
}: {
  variant?: 'default' | 'large'
  userId?: string
  className?: string
  username?: string
}) => {
  const user = useMemo(() => {
    if (!username) return ''
    const split = username.split('')

    const secondLetter = split[Math.floor(split.length / 2)]

    return String(split[0] + secondLetter).toLocaleUpperCase()
  }, [username])

  return (
    <Avatar variant={variant} className={className}>
      <AvatarImage
        src={`${appConfig.avatarBaseURl}/users/${userId}/avatar/x1.jpeg`}
        alt={username}
      />
      <AvatarFallback variant={variant} delayMs={600}>
        {user}
      </AvatarFallback>
    </Avatar>
  )
}
