export const LeafIcon = ({
  isActive = false,
  className = '',
}: {
  isActive?: boolean
  className?: string
}) => {
  return (
    <svg
      className={className}
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_467_7078)">
        <path
          d="M1.20628 12.6536C1.20628 12.6536 -3.58195 0.296385 14.638 0C14.638 0 13.5873 1.55997 12.8362 4.35844C12.0851 7.15691 11.262 13.532 3.11741 13.4283C3.11741 13.4283 2.76023 7.57557 8.59339 3.53183C8.59339 3.53183 1.99757 5.88854 1.83127 15.951L0.740356 16C0.740356 16 0.858934 13.2255 1.20686 12.6536H1.20628Z"
          fill={isActive ? '#000000' : '#CCFF00'}
        />
      </g>
      <defs>
        <clipPath id="clip0_467_7078">
          <rect
            width="14"
            height="16"
            fill="white"
            transform="translate(0.637939)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
