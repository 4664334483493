import { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import copy from 'copy-to-clipboard'

import { useToast } from './useToast'

export const useShare = ({
  titles,
  tags,
}: {
  titles: string[]
  tags: string
}) => {
  const { toast } = useToast()
  const [title, setTitle] = useState('')
  const url = window.location.href

  const randomizeTitle = () => {
    setTitle(titles[Math.floor(Math.random() * titles.length)])
  }

  useEffect(() => {
    randomizeTitle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCopy = useCallback(
    (urlOnly: boolean) => {
      urlOnly ? copy(url) : copy(`${title}\n${url}\n\n${tags}`)
      toast({
        description: <span className="font-semibold">Copied to clipboard</span>,
      })
    },
    [toast, title, url, tags]
  )

  const onShareClick = async (
    e: React.MouseEvent<HTMLSpanElement>,
    urlOnly: boolean = false
  ) => {
    e.stopPropagation()

    handleCopy(urlOnly)
  }

  const onNativeShareClick = async (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation()

    if (navigator?.share) {
      return navigator
        .share({
          text: `${title}\n${url}\n\n${tags} `,
          url,
        })
        .catch(() => '')
    }
  }

  return {
    title,
    url,
    tags,
    onShareClick,
    randomizeTitle,
    onNativeShareClick,
    hasNativeShare: !!navigator?.share && isMobile,
  }
}
