import { FC, SVGProps } from 'react'

export const CloseCircleIcon: FC<SVGProps<SVGSVGElement>> = ({
  className = '',
  ...props
}) => {
  return (
    <svg
      className={className}
      {...props}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
    >
      <path
        d="M512 128a384 384 0 1 0 0 768A384 384 0 0 0 512 128z m164.672 498.88a35.2 35.2 0 0 1-49.792 49.792L512 561.792l-114.88 114.88a35.2 35.2 0 1 1-49.792-49.792L462.208 512 347.328 397.12a35.2 35.2 0 1 1 49.792-49.792L512 462.208l114.88-114.88a35.2 35.2 0 0 1 49.792 49.792L561.792 512l114.88 114.88z"
        p-id="1579"
      ></path>
    </svg>
  )
}
