import { FC } from 'react'

import { Card } from '@/core/components/common/Card'
import { InfoHoverCard } from '@/core/components/InfoHoverCard'
import { formatCurrency } from '@/core/utils/formatCurrency'
import { formatPercentageValue } from '@/core/utils/formatPercentageValue'

import { TokenDetailsData } from '../hooks/useTokenDetails'
import { BoosterDetailsCardSkeleton } from './skeletons/BoosterDetailsCardSkeleton'

import { appConfig } from '@/config'

interface BoosterDetailsCardProps {
  isTransactionLoading: boolean
  tokenDetails?: TokenDetailsData
}

export const BoosterDetailsCard: FC<BoosterDetailsCardProps> = ({
  isTransactionLoading,
  tokenDetails,
}) => {
  if (isTransactionLoading) {
    return <BoosterDetailsCardSkeleton />
  }

  return (
    <Card>
      <h3 className="text-center pb-2 -mt-2">Price Boost Details</h3>
      <ul role="list" className="-mb-2">
        <li className="py-1">
          <div className="flex items-center">
            <div className="flex-1 min-w-0 text-cool-gray-300">
              <InfoHoverCard title="Earnings for Boost">
                This percentage shows how much of the creator&apos;s earnings
                from the RepubliK SOCIAL app are allocated to the{' '}
                {appConfig.marketName} Price Booster, which drives up the price
                of the {appConfig.marketName}.
              </InfoHoverCard>
            </div>
            <div className="inline-flex items-center text-sm font-semibold text-white">
              {formatPercentageValue(
                tokenDetails?.revenueShareInPercentage || 0,
                0,
                false
              )}
            </div>
          </div>
        </li>
        <li className="py-1">
          <div className="flex items-center">
            <div className="flex-1 min-w-0 text-cool-gray-300">
              <InfoHoverCard title="Total Boost to Date">
                This is the total amount the creator has contributed to the{' '}
                {appConfig.marketName}
                Price Booster from their earnings from the RepubliK SOCIAL app
                so far. The more the creator contributes to the Price Booster,
                the more the price of the {appConfig.marketName} will increase.
              </InfoHoverCard>
            </div>
            <div className="inline-flex items-center text-sm font-semibold text-white">
              {formatCurrency(String(tokenDetails?.totalBoost || 0))}{' '}
              {appConfig.currency}
            </div>
          </div>
        </li>
      </ul>
    </Card>
  )
}
