import { useQuery } from '@tanstack/react-query'

import API from '@/core/services/api'
import { SocialMedia } from '@/core/types'
import { useNetwork } from '@/core/hooks/useNetwork'

export interface IPortfolio {
  change: number
  changePercentage: number | null
  currentPortfolio: number
  portfolio24h: number
}

export interface IDataItem {
  userId: string
  tokenId: string
  username: string
  socials: {
    [key in SocialMedia]: {
      followers: number
      username: string
    }
  }
  price: number
  priceChange24h: number
  priceChange7d: number
  priceChange30d: number
  holders: number
  createdAt: Date
  updatedAt: Date
  amount: number
  liquidationValue: number
}

export interface DataItemsResponse {
  items: IDataItem[]
  portfolio?: IPortfolio
}

export const useVirtualList = ({
  walletId,
  enabled = true,
}: {
  walletId: `0x${string}` | null | undefined
  enabled?: boolean
}) => {
  const { isOnline } = useNetwork()

  const { data, isError, refetch, isLoading, isSuccess } = useQuery<
    DataItemsResponse,
    Error
  >({
    queryKey: ['portfolio', walletId],
    queryFn: () => API.get(`/market/tokens/users/${walletId}`),
    enabled: isOnline && !!walletId && enabled,
  })

  return {
    data: {
      items: data?.items?.sort(
        (a, b) => b.liquidationValue - a.liquidationValue
      ),
      portfolio: data?.portfolio,
    },
    isError,
    refetch,
    isLoading,
    unwatch: () => null,
    isSuccess,
  }
}
