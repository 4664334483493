interface Window {
  NREUM?: {
    setErrorHandler: (handler: (error: Error) => boolean) => void
    addRelease: (version: string, tag: string) => void
    setApplicationVersion: (tag: string) => void
  }
}

interface ErrorWithOptionalStack extends Error {
  stack?: string
}

if ((window as Window)?.NREUM) {
  const tag = window?.VERSION
  ;(window as Window).NREUM?.addRelease('v' + tag, tag)
  ;(window as Window).NREUM?.setApplicationVersion(tag)
  const exactMessages: string[] = ['ua', 'Script error.']
  const includedPhrases: string[] = [
    'ServiceWorker',
    'Cannot redefine property: ethereum',
    'isMetaMask',
    'Talisman extension',
  ]

  ;(window as Window).NREUM?.setErrorHandler(
    (error: ErrorWithOptionalStack) => {
      if (exactMessages.some((message) => error.message === message)) {
        return true
      }

      if (includedPhrases.some((phrase) => error.message?.includes(phrase))) {
        return true
      }

      if (error.stack && error.stack.includes('chrome-extension://')) {
        return true
      }

      return false
    }
  )
}
