import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ChevronRightIcon } from '@radix-ui/react-icons'

import { appConfig } from '@/config'

export const ProcessingToken: FC = () => {
  return (
    <div className="w-full flex flex-col items-center text-center px-6">
      <div className="text-xl md:text-2xl mt-[15%]">
        Getting your{' '}
        <span className="animated-purple-text">{appConfig.marketName}</span>{' '}
        ready
      </div>
      <div className="max-w-md md:text-lg mt-[7%]">
        To be traded on the market. This may take a few minutes. Almost there!
        🚀
      </div>
      <Link
        className="flex flex-row items-center font-bold mt-[10%] cursor-pointer"
        to="/"
      >
        Go to the {appConfig.marketName} Market
        <ChevronRightIcon className="w-5 h-5 text-primary" />
      </Link>
    </div>
  )
}
