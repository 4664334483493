import { FC, useEffect } from 'react'
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'

import PullToRefresh from '@/core/components/PullToRefresh'

import { LeaderboardResponse } from '../hooks/useLeaderboard'
import { LeaderboardBanner } from './LeaderboardBanner'
import { Row } from './Row'
import { LeaderboardSkeleton } from './skeletons/LeaderboardSkeleton'
import { RowSkeleton } from './skeletons/RowSkeleton'

import { appConfig } from '@/config'
type StickyListProps = {
  data?: LeaderboardResponse
  isLoading: boolean
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<LeaderboardResponse, Error>>
}

const testElement = document.createElement('div')
testElement.style.paddingBottom = 'env(safe-area-inset-bottom)'
document.body.appendChild(testElement)
const safeAreaBottom = parseFloat(
  window.getComputedStyle(testElement).paddingBottom
)

export const rowHeight = 48

export const StickyList: FC<StickyListProps> = ({
  data,
  isLoading,
  refetch,
}) => {
  const activeTrader = data?.trader
  const el = document.querySelector('#sticky-row')
  const viewportHeight = window.innerHeight
  const rootMarginValue = viewportHeight - rowHeight - safeAreaBottom - 2

  useEffect(() => {
    if (!el) return

    const observer = new IntersectionObserver(
      ([e]) => {
        e.target.classList.toggle(
          'safe-area-bottom',
          e.intersectionRatio > 0.99
        )
      },
      {
        rootMargin: `-${rootMarginValue}px 0px 0px 0px`,
        threshold: [1],
      }
    )

    observer.observe(el)

    return () => {
      observer.disconnect()
    }
  }, [el, rootMarginValue, viewportHeight])

  return (
    <PullToRefresh
      onRefresh={refetch}
      isPullable={true}
      canFetchMore={true}
      fetchMoreThreshold={100}
      pullDownThreshold={67}
      maxPullDownDistance={95}
      resistance={1}
    >
      <div className="w-full flex flex-col">
        {isLoading ? (
          <LeaderboardSkeleton />
        ) : (
          <LeaderboardBanner
            end={data?.end}
            prizePool={appConfig.leaderboardPrizePool}
          />
        )}
        <div className="w-full relative flex flex-col divide-y divide-cool-gray-850">
          <div className="w-full flex flex-row items-center text-sm text-cool-gray-400 bg-cool-gray-900 py-2 z-20 sticky safe-top-[116px] md:safe-top-[124px]">
            <div className="w-24">Rank</div>
            <div className="w-full flex flex-row justify-between">
              <span>Trader</span>
              <span className="mr-6">
                Trading volume ({appConfig.currency})
              </span>
            </div>
          </div>
          {isLoading || !data
            ? [...Array(15)].map((_: unknown, index: number) => (
                <RowSkeleton key={index} />
              ))
            : data?.items
                ?.sort((a, b) => a.rank - b.rank)
                .map((row) => (
                  <Row
                    key={row.rank}
                    item={row}
                    isActiveTrader={row.address === activeTrader?.address}
                  />
                ))}
          {activeTrader && activeTrader.rank > 100 && (
            <div className="safe-area-bottom">
              <Row
                key={activeTrader.rank}
                item={activeTrader}
                isActiveTrader={true}
              />
            </div>
          )}
        </div>
      </div>
    </PullToRefresh>
  )
}
