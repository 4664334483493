import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom'

import { MainLayout } from './core/layouts/MainLayout'
import { Error } from './core/pages/Error'

import { History } from './modules/history/pages'
import { Leaderboard } from './modules/leaderboard/pages'
import { Portfolio } from './modules/portfolio/pages'
import { TokenDetails } from './modules/tokenDetails/pages'
import { TokenList } from './modules/tokenList/pages'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<MainLayout />} errorElement={<Error />}>
      <Route index element={<TokenList />} />
      <Route path="/portfolio" element={<Portfolio />}>
        <Route path=":walletId" element={<Portfolio />} />
      </Route>
      <Route path="/leaderboard" element={<Leaderboard />} />
      <Route path="/history" element={<History />}>
        <Route path=":walletId" element={<History />} />
      </Route>
      <Route path="/:username" element={<TokenDetails />} />
    </Route>
  )
)
