import { useState } from 'react'

import { Button } from '@/core/components/common/Button'
import { SubHeader as CoreSubHeader } from '@/core/components/SubHeader'

import { Socials } from '../hooks/useTokenDetails'
import { ShareDialog } from './ShareDialog'

import { ShareArrowIcon } from '@/assets/ShareArrowIcon'

export const SubHeader = ({
  username,
  socials,
}: {
  username?: string
  socials?: Socials
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  return (
    <CoreSubHeader
      right={
        <>
          {username && (
            <ShareDialog
              isOpen={isDialogOpen}
              socials={socials}
              username={username}
              setIsOpen={setIsDialogOpen}
            />
          )}
          <Button
            disabled={!username}
            variant="link"
            size="md"
            className="cursor-pointer flex"
            onClick={() => setIsDialogOpen(true)}
          >
            <ShareArrowIcon className="text-white w-7 h-7 p-1 -ml-1" />
            Share
          </Button>
        </>
      }
    />
  )
}
