import { useMemo } from 'react'

import { Card } from '@/core/components/common/Card'
import { Skeleton } from '@/core/components/common/Skeleton'
import { usePrice } from '@/core/hooks/usePrice'
import { cn } from '@/core/utils/classNames'
import { formatCurrency } from '@/core/utils/formatCurrency'
import { formatPercentageValue } from '@/core/utils/formatPercentageValue'

import { useVirtualList } from '../hooks/useVirtualList'

import { appConfig } from '@/config'

export const CurrentValueCard = ({
  walletId,
}: {
  walletId: `0x${string}` | null | undefined
}) => {
  const { convertToUsd } = usePrice()
  const { data, isLoading, isError } = useVirtualList({
    walletId,
    enabled: false,
  })

  const priceChange = useMemo(
    () => Number(Number(data?.portfolio?.changePercentage || 0)?.toFixed(2)),
    [data]
  )
  const change = useMemo(
    () => Number(Number(data?.portfolio?.change || 0)?.toFixed(2)),
    [data]
  )

  const changeColorClass = useMemo(
    () =>
      change === 0 ? 'text-white' : change > 0 ? 'text-success' : 'text-danger',
    [change]
  )
  const changePercentageColorClass = useMemo(
    () =>
      priceChange === 0
        ? 'text-white'
        : priceChange > 0
          ? 'text-success'
          : 'text-danger',
    [priceChange]
  )

  if (isError) return null

  return (
    <Card className="w-full sm:py-4">
      <ul className="md:flex md:items-center md:justify-around" role="list">
        <li>
          <div className="flex items-center">
            <div className="flex-1 min-w-0">
              <span className="flex flex-1 text-sm text-cool-gray-300 md:mr-4">
                {isLoading || !walletId ? (
                  <Skeleton className="w-28 h-3.5 my-1" />
                ) : (
                  'Current Value'
                )}
              </span>
            </div>
            <div className="flex flex-col justify-end text-right text-sm font-semibold text-white">
              {isLoading || !walletId ? (
                <>
                  <Skeleton className="w-28 h-3.5 my-1" />
                  <Skeleton className="self-end w-20 h-2 my-1" />
                </>
              ) : (
                <>
                  <span>
                    {formatCurrency(
                      String(data?.portfolio?.currentPortfolio || '0'),
                      2
                    )}{' '}
                    {appConfig.currency}
                  </span>
                  <span className="text-xs text-cool-gray-300">
                    {convertToUsd(data?.portfolio?.currentPortfolio || 0)}
                  </span>
                </>
              )}
            </div>
          </div>
        </li>
        <li>
          <div className="flex items-center">
            <div className="flex-1 min-w-0">
              <span className="flex flex-1 text-sm text-cool-gray-300 md:mr-4">
                {isLoading || !walletId ? (
                  <Skeleton className="w-28 h-3" />
                ) : (
                  '24H Change'
                )}
              </span>
            </div>
            <div className="inline-flex space-x-4items-center text-sm font-semibold text-white">
              {isLoading || !walletId ? (
                <Skeleton className="w-28 h-3" />
              ) : (
                <>
                  <span className={cn(changeColorClass)}>
                    {`${change > 0 ? '+' : ''}${formatCurrency(String(data?.portfolio?.change || 0), 2)}`}{' '}
                    {appConfig.currency}
                  </span>
                  <span className={cn('pl-1', changePercentageColorClass)}>
                    {`(${formatPercentageValue(Number(data?.portfolio?.changePercentage?.toFixed(2)) || 0)})`}
                  </span>
                </>
              )}
            </div>
          </div>
        </li>
      </ul>
    </Card>
  )
}
