import { useInfiniteQuery } from '@tanstack/react-query'

import API from '@/core/services/api'
import { flattenPages } from '@/core/utils/flattenPages'

export type Activity = {
  time: string
  holder: string
  price: number
  side: 'BUY' | 'SELL'
  txHash: string
  amount: number
  value: number
}

interface ActivitiesResponse {
  data: Activity[]
  next?: number
}

export const useActivities = ({ tokenId }: { tokenId?: number }) => {
  const { data, error, refetch, fetchNextPage, isLoading } = useInfiniteQuery<
    ActivitiesResponse,
    Error
  >({
    queryKey: ['activities', String(tokenId)],
    queryFn: () => API.get(`/market/activities/${tokenId}`),
    initialPageParam: 0,
    getNextPageParam: (lastPage: ActivitiesResponse) =>
      lastPage.next ?? undefined,
    enabled: tokenId != null,
  })

  return {
    data: flattenPages(data, 'items') as Activity[],
    fetchNextPage,
    error,
    refetch,
    isLoading: tokenId == null || isLoading,
  }
}
