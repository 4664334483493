import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'

import { Button } from '@/core/components/common/Button'
import PullToRefresh from '@/core/components/PullToRefresh'

import { LeaderboardResponse } from '../hooks/useLeaderboard'

interface EmptyListProps {
  title: string
  subtitle?: string
  withRedirectButton?: boolean
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<LeaderboardResponse, Error>>
}

export const EmptyList: FC<EmptyListProps> = ({
  title,
  subtitle,
  withRedirectButton = true,
  refetch,
}) => {
  const navigate = useNavigate()

  return (
    <PullToRefresh
      onRefresh={refetch}
      isPullable={true}
      canFetchMore={true}
      fetchMoreThreshold={100}
      pullDownThreshold={67}
      maxPullDownDistance={95}
      resistance={1}
    >
      <div className="flex flex-col items-center pt-8 mx-8 text-center gap-2">
        <h4 className="text-2xl font-bold w-56 md:w-full">{title}</h4>
        <span className="max-w-96">{subtitle}</span>
        {withRedirectButton && (
          <Button
            className="mt-4 px-6 py-1"
            size="sm"
            variant="purpleAnimatedOutline"
            onClick={() => navigate('/')}
          >
            See all STOX
          </Button>
        )}
      </div>
    </PullToRefresh>
  )
}
