import { Card } from '@/core/components/common/Card'
import { Skeleton } from '@/core/components/common/Skeleton'

export const BoosterCardSkeleton = () => {
  return (
    <Card>
      <div className="flex flex-row justify-between items-end">
        <div className="flex flex-col my-1">
          <Skeleton className="w-24 h-3 mb-1" />
          <Skeleton className="w-24 h-3" />
        </div>
        <Skeleton className="w-20 h-7 mb-1" />
      </div>
    </Card>
  )
}
