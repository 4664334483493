import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import API from '@/core/services/api'

export interface TransactionData {
  amount: bigint
  ceiling?: bigint
  creatorFee?: bigint
  delegate?: boolean
  maker: string
  protocolFee?: bigint
  revenueContribute?: bigint
  side: number
  tokenId: bigint
  value: bigint
}

export const useTransactionTicker = () => {
  const [index, setIndex] = useState(0)

  const { data, isLoading } = useQuery<{ items: TransactionData[] }, Error>({
    queryKey: ['transactionTicker'],
    queryFn: () => API.get('/market/activities/users', { first: String(25) }),
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (!data?.items) return
      setIndex((prevIndex) => {
        return prevIndex === data?.items?.length - 1 ? 0 : prevIndex + 1
      })
    }, 700)

    return () => clearInterval(interval)
  }, [data?.items])

  return {
    isLoading,
    data: data?.items?.[index],
    totalCount: data?.items?.length,
  }
}
