import { FC, useCallback } from 'react'

import { Button } from './common/Button'
import { Dialog, DialogContent, DialogTitle } from './common/Dialog'

import address from '@/assets/address-bar.png'
import { appConfig } from '@/config'

interface WarningDialogProps {
  isOpenDialog: boolean
  setIsOpenWarningDialog: (isOpen: boolean) => void
}

export const WarningDialog: FC<WarningDialogProps> = ({
  isOpenDialog,
  setIsOpenWarningDialog,
}) => {
  const onAck = useCallback(() => {
    setIsOpenWarningDialog(false)
    localStorage.setItem('hasAckWarning', 'true')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDismiss = useCallback(
    (open: boolean) => {
      setIsOpenWarningDialog(open)
    },
    [setIsOpenWarningDialog]
  )

  return (
    <Dialog open={isOpenDialog} onOpenChange={handleDismiss}>
      <DialogTitle></DialogTitle>
      <DialogContent className="bg-white">
        <div className="flex flex-col text-left text-cool-gray-900 p-4">
          <p className="font-lg font-bold">GM GM,</p>
          <p>
            Before proceeding, please ensure you are on the official{' '}
            {appConfig.marketName} market dApp at:
            <img src={address} alt="" className="py-2" />
            Scammers often replicate websites/dApps to steal funds.
          </p>
          <p>
            Always double-check the URL to verify authenticity. When in doubt,
            use the links provided on the official republik.gg website or visit
            our official Telegram and X accounts.
          </p>
          <br />
          <p>Protect your assets. Stay vigilant. 🚀</p>
        </div>
        <div className="flex justify-center items-center pb-8">
          <Button variant="black" onClick={() => onAck()}>
            Acknowledge
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
