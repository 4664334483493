import { FC } from 'react'

import { Card } from '@/core/components/common/Card'
import { Skeleton } from '@/core/components/common/Skeleton'

export const BoosterDetailsCardSkeleton: FC = () => {
  return (
    <Card>
      <Skeleton className="w-36 h-4 mb-4 mx-auto" />
      <div className="flex justify-between items-center mb-2">
        <Skeleton className="w-32 h-4" />
        <Skeleton className="w-10 h-4" />
      </div>
      <div className="flex justify-between items-center">
        <Skeleton className="w-32 h-4" />
        <Skeleton className="w-20 h-4" />
      </div>
    </Card>
  )
}
