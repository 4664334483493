import { Dispatch, FC, SetStateAction, useCallback } from 'react'

import { ShareDialog as ShareCoreDialog } from '@/core/components/ShareDialog'

import { useShare } from '../hooks/useShare'

type ShareDialogProps = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const ShareDialog: FC<ShareDialogProps> = ({ isOpen, setIsOpen }) => {
  const {
    title,
    url,
    tags,
    onShareClick,
    randomizeTitle,
    onNativeShareClick,
    hasNativeShare,
  } = useShare()

  const handleDismiss = useCallback(
    (open: boolean) => {
      setIsOpen(open)
    },
    [setIsOpen]
  )

  const handleShareClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>, urlOnly?: boolean) => {
      onShareClick(e, urlOnly)
    },
    [onShareClick]
  )

  return (
    <ShareCoreDialog
      isOpen={isOpen}
      handleDismiss={handleDismiss}
      randomizeTitle={randomizeTitle}
      hasNativeShare={hasNativeShare}
      title={title}
      url={url}
      tags={tags}
      onNativeShareClick={onNativeShareClick}
      handleShareClick={handleShareClick}
    />
  )
}
