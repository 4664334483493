import { ErrorDecoder } from 'ethers-decode-error'

export const parseError = async (error: unknown) => {
  const errorDecoder = ErrorDecoder.create()
  const { reason } = await errorDecoder.decode(error)

  if (!reason) return

  const regex = /ERC20: (.*?)\n/
  const match = regex.exec(reason)

  return match ? match[1] : reason.split('\n')[0]
}
