import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/core/utils/classNames'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-cool-gray-700 text-primary-foreground shadow hover:bg-primary/90',
        destructive:
          'bg-danger text-destructive-foreground shadow-sm hover:bg-destructive/90',
        outline:
          'border border-input border-cool-gray-300 text-cool-gray-300 bg-background shadow-sm hover:bg-transparent/10',
        secondary:
          'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80',
        white: 'bg-white border text-cool-gray-900 shadow-sm hover:bg-white/80',
        black: 'bg-black border text-cool-gray-100 shadow-sm hover:bg-black/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-cool-gray-100 underline-offset-4 hover:underline',
        purple:
          'text-cool-gray-100 border border-purple-100 bg-gradient-to-r from-purple-100 to-purple-200 ',
        purpleOutline:
          'text-cool-gray-100 border border-purple-100 bg-transparent',
        purpleAnimatedOutline:
          'text-cool-gray-100 border border-purple-100 animate-linear bg-gradient-to-r from-purple-200 via-purple-100 to-purple-200 bg-[length:200%_auto] !font-bold',
        success:
          'bg-success text-white !font-bold text-primary-foreground shadow hover:bg-success/90',
        danger:
          'bg-danger text-white !font-bold text-primary-foreground shadow hover:bg-danger/90',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-8 rounded-md px-3 text-xs',
        md: 'h-8 rounded-md px-3 text-md',
        lg: 'h-10 rounded-md px-8',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  variant?:
    | 'default'
    | 'destructive'
    | 'outline'
    | 'secondary'
    | 'white'
    | 'black'
    | 'ghost'
    | 'link'
    | 'purple'
    | 'purpleOutline'
    | 'purpleAnimatedOutline'
    | 'success'
    | 'danger'
  size?: 'default' | 'sm' | 'md' | 'lg' | 'icon'
  children: React.ReactNode | string
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant = 'default', size, asChild = false, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = 'Button'

export { Button }
