import { FC, useCallback, useEffect, useState } from 'react'

import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@/core/components/common/Dialog'
import { HowToEarnVideo } from '@/core/components/HowToEarnVideo'

export const HowToEarnDialog: FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const hasSeenModal = localStorage.getItem('hasSeenHowToEarnModal')

    if (!hasSeenModal) setIsOpen(true)
  }, [])

  const handleDismiss = useCallback(() => {
    setIsOpen(false)
    localStorage.setItem('hasSeenHowToEarnModal', 'true')
  }, [])

  return (
    <Dialog open={isOpen} onOpenChange={handleDismiss}>
      <DialogContent>
        <DialogTitle>
          <HowToEarnVideo />
        </DialogTitle>
      </DialogContent>
    </Dialog>
  )
}
