import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ChevronRightIcon } from '@radix-ui/react-icons'

import { appConfig } from '@/config'

export const NotFound: FC = () => {
  return (
    <div className="w-full flex flex-col items-center text-center px-6">
      <span className="text-7xl md:text-8xl mt-[15%]">🦥</span>
      <span className="text-6xl md:text-7xl font-bold text-primary my-4">
        404
      </span>
      <span className="text-base md:text-lg">
        Oops! This page may have been moved or is broken
      </span>
      <Link
        className="flex flex-row items-center font-bold mt-[10%] cursor-pointer"
        to="/"
      >
        Go to the {appConfig.marketName} Market
        <ChevronRightIcon className="w-5 h-5 text-primary" />
      </Link>
    </div>
  )
}
