import { FC, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

import { SubHeaderType } from '@/core/layouts/MainLayout'
import { SubHeader } from '@/core/components/SubHeader'

import { VirtualList } from '../components'

export const History: FC = () => {
  const { setSubHeader } = useOutletContext<SubHeaderType>()

  useEffect(() => {
    setSubHeader(<SubHeader title="Trades" />)
  }, [setSubHeader])

  return <VirtualList />
}
