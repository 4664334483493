import { FC, useCallback, useMemo } from 'react'

import { SocialMedia } from '@/core/types'
import { Badge } from '@/core/components/common/Badge'
import { getSocialMediaUrl } from '@/core/utils/getSocialMediaUrl'
import { compactNumbers } from '@/core/utils/utils'

import { Socials } from '../hooks/useTokenDetails'

import { InstagramIcon } from '@/assets/InstagramIcon'
import { RepublikIcon } from '@/assets/RepublikIcon'
import { TikTokIcon } from '@/assets/TikTokIcon'
import { XIcon } from '@/assets/XIcon'

type SocialMediaDetailsProps = {
  socials: Socials
}

export const SocialMediaDetails: FC<SocialMediaDetailsProps> = ({
  socials,
}) => {
  const parsedSocials = useMemo(() => {
    if (!socials) return []

    const sortedEntries = Object.entries(socials).sort(
      ([, a], [, b]) =>
        (b as Socials[SocialMedia]).followers -
        (a as Socials[SocialMedia]).followers
    )

    return sortedEntries
  }, [socials])

  const getIcon = useCallback((socialMedia: SocialMedia) => {
    if (socialMedia === 'instagram') return <InstagramIcon className="mr-1" />
    if (socialMedia === 'tiktok') return <TikTokIcon className="mr-1" />
    if (socialMedia === 'twitter') return <XIcon className="mr-1" />
    if (socialMedia === 'republik') return <RepublikIcon className="mr-1" />
  }, [])

  return (
    <div className="flex flex-wrap gap-x-1 gap-y-0.5">
      {parsedSocials.map((item) => {
        const socialMedia = item[0] as SocialMedia
        const data = item?.[1] as Socials[SocialMedia]

        return (
          <a
            key={socialMedia}
            href={getSocialMediaUrl(socialMedia, data.username)}
            target="_blank"
            rel="noreferrer"
          >
            <Badge
              variant="outline"
              className="!rounded-full !px-2.5 !py-1 cursor-pointer"
            >
              {getIcon(socialMedia)}
              {socialMedia === 'republik'
                ? 'RepubliK'
                : compactNumbers(data.followers)}
            </Badge>
          </a>
        )
      })}
    </div>
  )
}
