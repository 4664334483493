import { FC } from 'react'

import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@/core/components/common/Alert'

import { Button } from './common/Button'

export const NetworkErrorAlert: FC = () => {
  return (
    <div className="fixed top-[40%] left-1/2 translate-x-[-50%] z-10">
      <Alert className="w-72 md:w-80 bg-cool-gray-850 border-cool-gray-700">
        <AlertTitle>Oooops!</AlertTitle>
        <AlertDescription className="flex flex-col justify-center">
          Something went wrong
          <Button
            className="mt-3"
            variant="purpleOutline"
            size="sm"
            onClick={() => window.location.reload()}
            type="button"
          >
            Reload the page
          </Button>
        </AlertDescription>
      </Alert>
    </div>
  )
}
