import { FC } from 'react'

import { formatDurationFromSeconds } from '@/core/utils/formatDurationFromSeconds'

interface CountdownProps {
  timeLeft: bigint
}

export const Countdown: FC<CountdownProps> = ({ timeLeft }) => {
  return <>{formatDurationFromSeconds(timeLeft)}</>
}
