import { NavLink } from 'react-router-dom'
import truncateEthAddress from 'truncate-eth-address'
import { formatUnits } from 'viem'

import { Skeleton } from '@/core/components/common/Skeleton'
import { formatCurrency } from '@/core/utils/formatCurrency'

import { useTransactionTicker } from '../hooks/useTransactionTicker'

import { appConfig } from '@/config'

export const TransactionTicker = () => {
  const { data, totalCount, isLoading } = useTransactionTicker()

  if (isLoading) {
    return <Skeleton className="h-8 w-full" />
  }

  if (!data) {
    return null
  }

  return (
    <div
      className="text-xs h-8 flex items-center bg-cool-gray-850 rounded-md px-3 justify-center"
      title={`Last ${totalCount} transactions`}
    >
      <div className="truncate">
        <NavLink className="underline" to={`/portfolio/${data.maker}`}>
          {truncateEthAddress(data.maker)}
        </NavLink>
        {data.side === 0 ? (
          <span className="text-success mx-1 font-bold">bought</span>
        ) : (
          <span className="text-danger mx-1 font-bold">sold</span>
        )}
        {data.amount?.toString()} STOX for{' '}
        {formatCurrency(String(formatUnits(data.value, 18)), 2)}{' '}
        {appConfig.currency}
      </div>
    </div>
  )
}
