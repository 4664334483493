import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { VirtualItem } from '@tanstack/react-virtual'

import { StockPill } from '@/core/components/StockPill'
import { TokenAvatar } from '@/core/components/TokenAvatar'
import { usePrice } from '@/core/hooks/usePrice'
import { formatCurrency } from '@/core/utils/formatCurrency'
import { formatPercentageValue } from '@/core/utils/formatPercentageValue'
import { compactNumbers, rem } from '@/core/utils/utils'

import { IDataItem } from '../hooks/useVirtualList'

import { InstagramIcon } from '@/assets/InstagramIcon'
import { TikTokIcon } from '@/assets/TikTokIcon'
import { XIcon } from '@/assets/XIcon'

type RowProps = {
  item: IDataItem | undefined
  virtualRow: VirtualItem
}

export const Row: FC<RowProps> = ({ item, virtualRow }) => {
  const navigate = useNavigate()
  const { convertToUsd } = usePrice()

  const topSocialMedia = useMemo(() => {
    if (!item?.socials) return

    const sortedEntries = Object.entries(item?.socials).sort(
      ([, a], [, b]) => b.followers - a.followers
    )
    const topSocialMedia = {
      name: sortedEntries[0][0],
      ...sortedEntries[0][1],
    }

    return topSocialMedia
  }, [item?.socials])

  if (!item) return null

  return (
    <div
      key={virtualRow.index}
      className="flex items-center text-xs md:text-sm gap-x-2 cursor-pointer"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: rem(virtualRow.size),
        transform: `translateY(${rem(virtualRow.start)})`,
      }}
      onClick={() => navigate(`/@${item.username}`)}
    >
      <div
        className="flex items-center flex-auto"
        style={{ height: rem(virtualRow.size) }}
      >
        <TokenAvatar
          userId={item?.userId}
          username={item?.username}
          className="-ml-2"
        />
        <div className="flex flex-col justify-start">
          <div>
            {item?.username}
            <span className="pl-[0.125rem] text-cool-gray-300 md:hidden">
              ({item?.amount})
            </span>
          </div>
          <div className="flex flex-row items-center text-xxs text-cool-gray-300 mt-1/2 md:hidden">
            <span
              className={`md:hidden text-xs pl-[0.125rem] ${item?.priceChange24h > 0 ? 'text-success' : 'text-danger'}`}
            >
              {formatPercentageValue(item?.priceChange24h)}
            </span>
          </div>
        </div>
      </div>
      <div className="flex-none w-10 md:w-20 text-right hidden md:block">
        <div className="flex flex-row items-center justify-end">
          <span className="pr-[2px]">
            {topSocialMedia?.name === 'instagram' && <InstagramIcon />}
            {topSocialMedia?.name === 'tiktok' && <TikTokIcon />}
            {topSocialMedia?.name === 'twitter' && <XIcon />}
          </span>
          {topSocialMedia?.followers !== undefined
            ? compactNumbers(topSocialMedia?.followers)
            : ''}
        </div>
      </div>
      <div className="flex-none md:w-16 text-right hidden md:block">
        {item?.amount}
      </div>
      <div className="flex-none md:w-24 text-right hidden md:flex flex-col justify-end items-end">
        {formatCurrency(String(item?.price), 2)}
        <span className="flex text-xs text-cool-gray-300">
          {convertToUsd(item?.price)}
        </span>
      </div>
      <div className="flex-col md:w-24 text-right hidden md:flex justify-end items-end">
        {formatCurrency(String(item?.liquidationValue), 2)}
        <span className="flex text-xs text-cool-gray-300">
          {convertToUsd(item?.liquidationValue)}
        </span>
      </div>
      <div className="flex-none md:w-20 text-right md:hidden">
        <div className="font-bold">
          {formatCurrency(String(item?.price), 2)}
        </div>
        <div className="text-xs text-cool-gray-300">
          {convertToUsd(item?.price)}
        </div>
      </div>
      <div className="flex-col w-20 md:w-32 text-right md:hidden justify-end items-end">
        {formatCurrency(String(item?.liquidationValue), 2)}
        <span className="flex text-xs text-cool-gray-300 text-right justify-end">
          {convertToUsd(item?.liquidationValue)}
        </span>
      </div>
      <div className="flex-none w-24 md:w-32 md:flex justify-end hidden">
        <StockPill value={item?.priceChange24h} />
      </div>
    </div>
  )
}
