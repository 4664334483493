import { FC } from 'react'

type TriangleDownProps = {
  className?: string
}

export const TriangleDown: FC<TriangleDownProps> = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 6H11L7.5 10.5L4 6Z" fill="currentColor"></path>
    </svg>
  )
}
