export const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <div className="safe-area-bottom w-full text-xs text-cool-gray-200 md:bg-cool-gray-950 md:border-t border-cool-gray-800 flex flex-grow items-center justify-center">
      <div className="max-w-3xl w-full flex flex-row px-2">
        <div className="pl-4 flex-2 py-2 hidden md:flex divide-x divide-cool-gray-600 space-x-2 items-center">
          <span>© {year} Rad RepubliK Pte Ltd. All Rights Reserved.</span>
          <span>
            <a
              href="https://staking.republik.gg"
              className="block pl-2 text-xs text-cool-gray-200 rounded md:bg-transparent"
              target="_blank"
              rel="noreferrer"
            >
              Staking App
            </a>
          </span>
          <span>
            <a
              href="https://app.republik.gg"
              className="block pl-2 text-xs text-cool-gray-200 rounded md:bg-transparent"
              target="_blank"
              rel="noreferrer"
            >
              Social App
            </a>
          </span>
        </div>
        <div className="pr-4 py-2 flex-1 hidden md:flex justify-end">
          <span className="rounded bg-cool-gray-900 text-cool-gray-200 py-1 px-2">
            v{window?.VERSION}
          </span>
        </div>
      </div>
    </div>
  )
}
