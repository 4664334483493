import { FC } from 'react'

type ShareArrowIconProps = {
  className?: string
}

export const ShareArrowIcon: FC<ShareArrowIconProps> = ({ className = '' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 1024 1024"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M640 85.333h256l42.667 42.88V384a42.667 42.667 0 1 1-85.334 0V230.997L456.832 627.5a42.667 42.667 0 0 1-60.33-60.331l396.5-396.501H640a42.667 42.667 0 1 1 0-85.334zm256 0L938.667 128c0-1.024 0-2.048-.128-3.072A42.496 42.496 0 0 0 896 85.333zM183.168 311.168a42.667 42.667 0 0 1 30.165-12.501h256a42.667 42.667 0 1 0 0-85.334h-256a128 128 0 0 0-128 128v469.334a128 128 0 0 0 128 128h469.334a128 128 0 0 0 128-128v-256a42.667 42.667 0 1 0-85.334 0v256a42.667 42.667 0 0 1-42.666 42.666H213.333a42.667 42.667 0 0 1-42.666-42.666V341.333a42.667 42.667 0 0 1 12.501-30.165z"
      ></path>
    </svg>
  )
}
