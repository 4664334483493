import { useEffect } from 'react'
import { ConnectButton as Connect } from '@rainbow-me/rainbowkit'
import { arbitrum, arbitrumSepolia } from 'viem/chains'
import { useAccount, useBalance } from 'wagmi'

import { formatCurrency } from '@/core/utils/formatCurrency'

import { Button } from '../common/Button'

import { appConfig, queryClient } from '@/config'

export const ConnectButton = ({
  className = '',
  onClick,
}: {
  className?: string
  onClick?: () => void
}) => {
  const { address, isConnected } = useAccount()
  const { data } = useBalance({
    chainId:
      appConfig.stage === 'production' ? arbitrum.id : arbitrumSepolia.id,
    address: address,
    token: appConfig.tokenAddress,
    query: {
      enabled: isConnected,
    },
  })

  useEffect(() => {
    if (!isConnected) {
      const queriesToKeep = [
        'tokens',
        'tokenDetails',
        'activities',
        'usdPrice',
        'readContracts',
        'portfolio',
      ]

      const allQueries = queryClient.getQueryCache().getAll()
      allQueries.forEach((query) => {
        if (!queriesToKeep.includes(String(query.queryKey[0]))) {
          queryClient.removeQueries({ queryKey: query.queryKey })
        }
      })
    }
  }, [isConnected])

  return (
    <Connect.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== 'loading'
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated')
        return (
          <div
            className={className}
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button
                    size="sm"
                    variant="purpleOutline"
                    onClick={() => {
                      openConnectModal()
                      onClick?.()
                    }}
                    type="button"
                  >
                    <span className="animate-pulse">Connect Wallet</span>
                  </Button>
                )
              }
              if (chain.unsupported) {
                return (
                  <Button
                    size="sm"
                    variant="destructive"
                    onClick={() => {
                      openChainModal()
                      onClick?.()
                    }}
                    type="button"
                  >
                    <span className="animate-pulse">Wrong network</span>
                  </Button>
                )
              }

              return (
                <div style={{ display: 'flex', gap: 12 }}>
                  <Button
                    variant="purpleOutline"
                    size="sm"
                    onClick={openAccountModal}
                    type="button"
                  >
                    <span className="pr-2 font-semibold">
                      {formatCurrency(data?.formatted || '0')}{' '}
                      {appConfig.currency}
                    </span>
                    <span className="text-cool-gray-300">
                      {account.displayName}
                    </span>
                  </Button>
                </div>
              )
            })()}
          </div>
        )
      }}
    </Connect.Custom>
  )
}
