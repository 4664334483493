import { useEffect, useMemo, useState } from 'react'
import { watchContractEvent } from '@wagmi/core'
import { formatUnits, MulticallParameters } from 'viem'
import { useReadContracts, useWalletClient } from 'wagmi'

import { ILog } from '@/core/types'
import { useNetwork } from '@/core/hooks/useNetwork'
import { ceilingMultiplier } from '@/core/utils/ceilingMultiplier'

import { creatorTokenAbi as abi } from '@/abi/creatorTokenAbi'
import { appConfig, transportConfig } from '@/config'

export interface PriceData {
  value: string | null
  platformFee: string | null
  total: string | null
  ceiling: bigint
}

export interface TransactionData {
  buyPrice: PriceData
  sellPrice: PriceData
  amountInBalance: number
  totalSupply: number
  distributionTimeElapsed: bigint
  pendingDistribution: bigint
  distributionTimePeriod: bigint
}

const normalizePriceData = ({
  contractData,
}: {
  contractData: (
    | { error?: undefined; result: unknown; status: 'success' }
    | { error: Error; result?: undefined; status: 'failure' }
  )[]
}): TransactionData | undefined => {
  const isError =
    contractData?.[0].status === 'failure' ||
    contractData?.[1].status === 'failure'
  if (isError || !contractData) {
    return
  }

  const buyPriceValue = BigInt(
    ((contractData?.[0] as { result: unknown[] })?.result?.[0] as bigint) || 0n
  )

  const sellPriceValue = BigInt(
    ((contractData?.[1] as { result: unknown[] })?.result?.[0] as bigint) || 0n
  )

  const buyPricePlatformFee =
    BigInt(
      ((contractData?.[0] as { result: unknown[] })?.result?.[1] as bigint) ||
        0n
    ) +
    BigInt(
      ((contractData?.[0] as { result: unknown[] })?.result?.[2] as bigint) ||
        0n
    ) +
    BigInt(
      ((contractData?.[0] as { result: unknown[] })?.result?.[3] as bigint) ||
        0n
    )
  const sellPricePlatformFee =
    BigInt(
      ((contractData?.[1] as { result: unknown[] })?.result?.[1] as bigint) ||
        0n
    ) +
    BigInt(
      ((contractData?.[1] as { result: unknown[] })?.result?.[2] as bigint) ||
        0n
    ) +
    BigInt(
      ((contractData?.[1] as { result: unknown[] })?.result?.[3] as bigint) ||
        0n
    )
  const distributionTimeElapsed =
    ((contractData?.[4] as { result: unknown[] })?.result?.[2] as bigint) || 0n
  const distributionTimePeriod =
    ((contractData?.[4] as { result: unknown[] })?.result?.[3] as bigint) || 0n
  const pendingDistribution =
    ((contractData?.[4] as { result: unknown[] })?.result?.[1] as bigint) || 0n

  return {
    buyPrice: {
      value: contractData ? formatUnits(buyPriceValue, 18) : null,
      platformFee: contractData ? formatUnits(buyPricePlatformFee, 18) : null,
      total: contractData
        ? formatUnits(buyPricePlatformFee + buyPriceValue, 18)
        : null,
      ceiling: ceilingMultiplier(buyPriceValue + buyPricePlatformFee, 'buy'),
    },
    sellPrice: {
      value: contractData ? formatUnits(sellPriceValue, 18) : null,
      platformFee: contractData ? formatUnits(sellPricePlatformFee, 18) : null,
      total: contractData
        ? formatUnits(sellPriceValue - sellPricePlatformFee, 18)
        : null,
      ceiling: ceilingMultiplier(sellPriceValue - sellPricePlatformFee, 'sell'),
    },
    amountInBalance: Number(
      (contractData?.[2] as { result: unknown[] })?.result || '0'
    ),
    totalSupply: Number(
      (contractData?.[3] as { result: unknown[] })?.result || '0'
    ),
    distributionTimeElapsed,
    distributionTimePeriod,
    pendingDistribution,
  }
}

export const useTransaction = ({
  tokenId,
}: {
  tokenId: number | undefined | null
}) => {
  const { isOnline } = useNetwork()
  const { data: walletData } = useWalletClient()
  const [data, setData] = useState<TransactionData>({
    buyPrice: {
      value: null,
      platformFee: null,
      total: null,
      ceiling: 0n,
    },
    sellPrice: {
      value: null,
      platformFee: null,
      total: null,
      ceiling: 0n,
    },
    amountInBalance: 0,
    totalSupply: 0,
    distributionTimeElapsed: 0n,
    distributionTimePeriod: 0n,
    pendingDistribution: 0n,
  })

  const creatorTokenContract = useMemo(() => {
    return {
      abi,
      address: appConfig.creatorTokenContractAddress,
    } as const
  }, [])

  const contracts = useMemo(
    () =>
      [
        {
          ...creatorTokenContract,
          functionName: 'getBuyValue',
          args: [tokenId, 1n],
        },
        {
          ...creatorTokenContract,
          functionName: 'getSellValue',
          args: [tokenId, 1n],
        },
        {
          ...creatorTokenContract,
          functionName: 'balance',
          args: [tokenId, walletData?.account.address as `0x${string}`],
        },
        {
          ...creatorTokenContract,
          functionName: 'totalSupply',
          args: [tokenId],
        },
        {
          ...creatorTokenContract,
          functionName: 'getCreatorDistribution',
          args: [tokenId],
        },
      ] as unknown as MulticallParameters['contracts'],
    [tokenId, walletData, creatorTokenContract]
  )

  const {
    data: contractData,
    isLoading,
    refetch,
  } = useReadContracts({
    contracts,
    query: { staleTime: 0, enabled: tokenId != null && isOnline },
  })

  useEffect(() => {
    if (contractData) {
      const normalizedData = normalizePriceData({
        contractData,
      })
      if (normalizedData) {
        setData(normalizedData)
      }
    }
  }, [contractData])

  useEffect(() => {
    if (tokenId == null) return

    const unwatch = watchContractEvent(transportConfig, {
      address: appConfig.creatorTokenContractAddress,
      abi,
      eventName: 'Execute',
      onLogs(logs: unknown) {
        if (
          Number((logs as unknown as ILog[])?.[0].args?.tokenId) === tokenId
        ) {
          refetch()
        }
      },
      onError(error) {
        console.error('Logs error', error)
      },
    })

    return () => {
      unwatch()
    }
  }, [tokenId, refetch])

  return {
    data,
    refetch,
    isLoading:
      isLoading ||
      tokenId == null ||
      !data.buyPrice.value ||
      !data.sellPrice.value,
  }
}
