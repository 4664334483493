import { useCallback, useState } from 'react'
import { readContract, readContracts } from '@wagmi/core'
import { encodeBytes32String, MaxUint256, Signature } from 'ethers'
import { encodeFunctionData, formatUnits } from 'viem'
import {
  useSignTypedData,
  useWaitForTransactionReceipt,
  useWalletClient,
  useWriteContract,
} from 'wagmi'

import { useToast } from '@/core/hooks/useToast'
import { calculateDeadline } from '@/core/utils/calculateDeadline'
import { ceilingMultiplier } from '@/core/utils/ceilingMultiplier'
import { parseError } from '@/core/utils/parseError'

import { PriceData } from './useTransaction'

import { creatorTokenAbi } from '@/abi/creatorTokenAbi'
import { tokenAbi } from '@/abi/tokenAbi'
import { appConfig, transportConfig } from '@/config'

interface IExecuteParams {
  type: 'sell' | 'buy'
  ceiling: bigint
  amount: number
  totalPrice: string
}

interface IOnExecuteParams
  extends IExecuteParams,
    Omit<IUsePermitAndExecuteParams, 'onSuccessfulTransaction'> {
  noncesData: bigint
  eip712DomainData:
    | readonly [
        `0x${string}`,
        string,
        string,
        bigint,
        `0x${string}`,
        `0x${string}`,
        readonly bigint[],
      ]
    | undefined
  allowanceNeeded: boolean
}

interface IUsePermitAndExecuteParams {
  tokenId: string
  onSuccessfulTransaction?: ({
    totalPrice,
    amount,
    walletId,
  }: {
    totalPrice: string
    amount: number
    walletId: `0x${string}`
  }) => void
}

interface IGetPriceParams
  extends Omit<IUsePermitAndExecuteParams, 'onSuccessfulTransaction'> {
  amount: number
  type: 'sell' | 'buy'
}

export const usePermitAndExecute = ({
  tokenId,
  onSuccessfulTransaction,
}: IUsePermitAndExecuteParams) => {
  const { toast } = useToast()
  const [isTransactionAborted, setTransactionAborted] = useState(false)
  const [isSigned, setSigned] = useState(false)
  const [isGetPriceLoading, setIsGetPriceLoading] = useState(false)
  const [calculatedPrice, setCalculatedPrice] = useState<PriceData>({
    value: null,
    platformFee: null,
    total: null,
    ceiling: 0n,
  })
  const [hash, setHash] = useState<string | null>(null)
  const { data: walletData } = useWalletClient()

  const { writeContract } = useWriteContract()
  const { signTypedData } = useSignTypedData()

  const { isLoading: isTransactionLoading } = useWaitForTransactionReceipt({
    hash: (hash as `0x${string}`) ?? undefined,
    pollingInterval: 5_000,
    query: {
      enabled: !!hash,
    },
  })

  const execute = useCallback(
    async ({
      type,
      tokenId,
      ceiling,
      allowanceNeeded,
      amount,
      noncesData,
      eip712DomainData,
      totalPrice,
    }: IOnExecuteParams) => {
      const deadline = calculateDeadline()

      if (
        !eip712DomainData ||
        !walletData ||
        !walletData.account ||
        !walletData.chain ||
        !amount
      ) {
        console.error('Missing required data')
        return
      }

      const domain = {
        name: (eip712DomainData as unknown as string[])?.[1],
        version: (eip712DomainData as unknown as string[])?.[2],
        chainId: Number((eip712DomainData as unknown as string[])?.[3]),
        verifyingContract:
          (eip712DomainData as unknown as `0x${string}`[])?.[4] || '0x0',
      }

      const executeParams = encodeFunctionData({
        abi: creatorTokenAbi,
        functionName: 'execute',
        args: [
          {
            order: {
              side: type === 'buy' ? 0 : 1,
              maker: walletData.account.address,
              tokenId,
              amount: BigInt(amount),
              ceiling,
            },
            r: encodeBytes32String(''),
            s: encodeBytes32String(''),
            v: 0,
          },
        ],
      })

      if (allowanceNeeded) {
        signTypedData(
          {
            domain,
            types: {
              Permit: [
                { name: 'owner', type: 'address' },
                { name: 'spender', type: 'address' },
                { name: 'value', type: 'uint256' },
                { name: 'nonce', type: 'uint256' },
                { name: 'deadline', type: 'uint256' },
              ],
            },
            primaryType: 'Permit',
            message: {
              owner: walletData?.account.address as `0x${string}`,
              spender: appConfig.creatorTokenContractAddress as `0x${string}`,
              value: MaxUint256,
              nonce: noncesData as bigint,
              deadline,
            },
          },
          {
            onSuccess: async (signature: string) => {
              const { v, r, s } = Signature.from(signature)
              setSigned(true)

              const permitParams = encodeFunctionData({
                abi: creatorTokenAbi,
                functionName: 'permit',
                args: [
                  {
                    owner: walletData?.account.address as `0x${string}`,
                    value: MaxUint256,
                    deadline,
                    v,
                    r,
                    s,
                  },
                ],
              })

              writeContract(
                {
                  chainId: walletData?.chain?.id,
                  address: appConfig.creatorTokenContractAddress,
                  abi: creatorTokenAbi,
                  functionName: 'multicall',
                  args: [[permitParams, executeParams]],
                },
                {
                  onSuccess: (hash) => {
                    setHash(hash)
                    toast({
                      title: 'Success',
                      description: 'Transaction submitted',
                    })
                    onSuccessfulTransaction?.({
                      totalPrice,
                      amount,
                      walletId: walletData.account.address,
                    })
                  },
                  onError: (error) => {
                    setTransactionAborted(true)
                    parseError(error).then((parsedError) =>
                      toast({
                        variant: 'destructive',
                        title: 'Uh oh!, transaction aborted',
                        description: parsedError || 'An error occurred',
                      })
                    )
                  },
                }
              )
            },
            onError: (error) => {
              setTransactionAborted(true)
              parseError(error).then((parsedError) =>
                toast({
                  variant: 'destructive',
                  title: 'Permit aborted',
                  description: parsedError || 'An error occurred',
                })
              )
            },
          }
        )
        return
      }

      writeContract(
        {
          chainId: walletData?.chain?.id,
          address: appConfig.creatorTokenContractAddress,
          abi: creatorTokenAbi,
          functionName: 'multicall',
          args: [[executeParams]],
        },
        {
          onSuccess: (hash) => {
            setHash(hash)
            toast({
              title: 'Success',
              description: 'Transaction submitted',
            })
            onSuccessfulTransaction?.({
              totalPrice,
              amount,
              walletId: walletData.account.address,
            })
          },
          onError: (error) => {
            setTransactionAborted(true)
            parseError(error).then((parsedError) =>
              toast({
                variant: 'destructive',
                title: 'Uh oh!, transaction aborted',
                description: parsedError || 'An error occurred',
              })
            )
          },
        }
      )
    },
    [walletData, writeContract, signTypedData, toast, onSuccessfulTransaction]
  )

  const onExecute = useCallback(
    async ({ type, ceiling, amount, totalPrice }: IExecuteParams) => {
      setHash(null)

      const result = await readContracts(transportConfig, {
        contracts: [
          {
            address: appConfig.tokenAddress,
            abi: tokenAbi,
            functionName: 'nonces',
            args: [walletData?.account.address as `0x${string}`],
          },
          {
            abi: tokenAbi,
            address: appConfig.tokenAddress,
            functionName: 'allowance',
            args: [
              walletData?.account.address as `0x${string}`,
              appConfig.creatorTokenContractAddress,
            ],
          },
          {
            abi: tokenAbi,
            address: appConfig.tokenAddress,
            functionName: 'eip712Domain',
          },
        ],
      })

      const noncesData = result?.[0]?.result as bigint
      const allowanceData = result?.[1]?.result as bigint
      const eip712DomainData = result?.[2]?.result

      await execute({
        noncesData,
        eip712DomainData,
        type,
        tokenId,
        ceiling,
        allowanceNeeded: !(ceiling <= (allowanceData || 0n)),
        amount,
        totalPrice,
      })
    },
    [walletData, tokenId, execute]
  )

  const getPrice = useCallback(
    async ({ tokenId, amount, type }: IGetPriceParams) => {
      try {
        setIsGetPriceLoading(true)
        const result = (await readContract(transportConfig, {
          address: appConfig.creatorTokenContractAddress,
          abi: creatorTokenAbi,
          functionName: type === 'buy' ? 'getBuyValue' : 'getSellValue',
          args: [tokenId, BigInt(amount)],
        })) as bigint[]

        const priceValue = BigInt((result?.[0] as unknown as bigint) || 0n)

        const pricePlatformFee =
          BigInt((result?.[1] as unknown as bigint) || 0n) +
          BigInt((result?.[2] as unknown as bigint) || 0n) +
          BigInt((result?.[3] as unknown as bigint) || 0n)

        if (type === 'buy') {
          setCalculatedPrice({
            value: result ? formatUnits(result[0], 18) : null,
            platformFee: result ? formatUnits(pricePlatformFee, 18) : null,
            total: result
              ? formatUnits(pricePlatformFee + priceValue, 18)
              : null,
            ceiling: ceilingMultiplier(priceValue + pricePlatformFee, 'buy'),
          })
        } else {
          setCalculatedPrice({
            value: result ? formatUnits(priceValue, 18) : null,
            platformFee: result ? formatUnits(pricePlatformFee, 18) : null,
            total: result
              ? formatUnits(priceValue - pricePlatformFee, 18)
              : null,
            ceiling: ceilingMultiplier(priceValue - pricePlatformFee, 'sell'),
          })
        }
      } catch (error) {
        setTransactionAborted(true)
        parseError(error).then((parsedError) =>
          console.error(parsedError || 'An error occurred')
        )
      }
      setIsGetPriceLoading(false)
    },
    []
  )

  const resetCalculatedPrice = useCallback(() => {
    setCalculatedPrice({
      value: null,
      platformFee: null,
      total: null,
      ceiling: 0n,
    })
  }, [])

  return {
    onExecute,
    isTransactionLoading,
    isTransactionAborted,
    setTransactionAborted,
    isSigned,
    setSigned,
    getPrice,
    calculatedPrice,
    resetCalculatedPrice,
    isGetPriceLoading,
  }
}
