import { FC, useCallback } from 'react'

import { ShareDialog as ShareCoreDialog } from '@/core/components/ShareDialog'

import { useShare } from '../hooks/useShare'
import { Socials } from '../hooks/useTokenDetails'

type ShareDialogProps = {
  username?: string
  socials: Socials | undefined
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

export const ShareDialog: FC<ShareDialogProps> = ({
  username,
  isOpen,
  setIsOpen,
  socials,
}) => {
  const {
    title,
    url,
    tags,
    onShareClick,
    randomizeTitle,
    onNativeShareClick,
    hasNativeShare,
  } = useShare({
    username,
    socials,
  })

  const handleDismiss = useCallback(
    (open: boolean) => {
      setIsOpen(open)
    },
    [setIsOpen]
  )

  const handleShareClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>, urlOnly?: boolean) => {
      onShareClick(e, urlOnly)
    },
    [onShareClick]
  )

  return (
    <ShareCoreDialog
      isOpen={isOpen}
      handleDismiss={handleDismiss}
      randomizeTitle={randomizeTitle}
      hasNativeShare={hasNativeShare}
      title={title}
      url={url}
      tags={tags}
      onNativeShareClick={onNativeShareClick}
      handleShareClick={handleShareClick}
    />
  )
}
