import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { cx } from 'class-variance-authority'
import { motion } from 'framer-motion'
import { formatUnits } from 'viem'

import { formatCurrency } from '@/core/utils/formatCurrency'

import { appConfig } from '@/config'
interface PriceCountdownProps {
  price: bigint
  timeLeft: bigint | null
}

export const PriceCountdown: FC<PriceCountdownProps> = ({
  price,
  timeLeft,
}) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const animationIntervalRef = useRef<NodeJS.Timeout | null>(null)
  const [animationKey, setAnimationKey] = useState(0)
  const [currentPrice, setCurrentPrice] = useState<bigint>(price)

  const [priceDifference, setPriceDifference] = useState(0n)

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    const pricePerSecond = timeLeft ? BigInt(currentPrice / timeLeft) : 0n

    intervalRef.current = setTimeout(() => {
      setCurrentPrice(() => {
        const newPrice = currentPrice - pricePerSecond

        setPriceDifference(currentPrice - newPrice)

        if (!timeLeft) {
          return 0n
        }

        return newPrice
      })
    }, 1000)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [timeLeft, currentPrice])

  useEffect(() => {
    if (animationIntervalRef.current) {
      clearInterval(animationIntervalRef.current)
    }

    animationIntervalRef.current = setInterval(() => {
      setAnimationKey((prevKey) => prevKey + 1)
    }, 1500)

    return () => {
      if (animationIntervalRef.current) {
        clearInterval(animationIntervalRef.current)
      }
    }
  }, [])

  const formattedPriceDifference = useMemo(() => {
    return formatCurrency(String(formatUnits(priceDifference, 18)), 0, 6)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationKey])

  const formattedCurrentPrice = useMemo(() => {
    const isLessThanOne = currentPrice < BigInt(1e18)
    return formatCurrency(
      String(formatUnits(currentPrice, 18)),
      0,
      isLessThanOne ? 4 : 2
    )
  }, [currentPrice])

  return (
    <div className="flex flex-col justify-center relative">
      <motion.div
        key={animationKey}
        className={cx('text-sm text-success text-center', {
          invisible: formattedPriceDifference === '0',
        })}
        initial={{ opacity: 1, y: 0 }}
        animate={{ opacity: 0, y: -5 }}
        transition={{
          opacity: { delay: 0.4, duration: 1 },
          y: { duration: 1.5 },
        }}
      >
        +{formattedPriceDifference} {appConfig.currency}
      </motion.div>
      <div
        className={
          Number(timeLeft) > 0
            ? `animate-linear font-bold
            bg-gradient-to-r bg-clip-text text-transparent 
            from-cool-gray-300 via-purple-500 to-success
            animate-text border-1 bg-[length:200%_auto] text-right`
            : 'text-white text-right'
        }
      >
        {formattedCurrentPrice} {appConfig.currency}
      </div>
    </div>
  )
}
