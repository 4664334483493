import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@/core/components/common/Alert'

import { ConnectButton } from './TopNavBar/ConnectButton'

export const ConnectWalletAlert = ({
  walletId,
}: {
  walletId: `0x${string}` | undefined
}) => {
  return (
    !walletId && (
      <div className="absolute w-full h-full z-40 items-center justify-center flex">
        <div className="h-full flex items-center justify-center pb-40 pt-10">
          <Alert className="bg-cool-gray-850 border-cool-gray-700">
            <AlertTitle>Oooops!</AlertTitle>
            <AlertDescription className="flex flex-col justify-center">
              Looks like your wallet is not connected.
              <ConnectButton className="flex flex-col justify-center pt-3" />
            </AlertDescription>
          </Alert>
        </div>
      </div>
    )
  )
}
