import { FC } from 'react'

import { Skeleton } from '@/core/components/common/Skeleton'
import { SubHeader as CoreSubHeader } from '@/core/components/SubHeader'

import { LeaderboardResponse } from '../hooks/useLeaderboard'
import { formatTimestamp } from '../utils/formatTimestamp'
import { RaceSelector } from './RaceSelector'

type SubHeaderProps = {
  data?: LeaderboardResponse
  isLoading: boolean
  isError: boolean
}

export const SubHeader: FC<SubHeaderProps> = ({ data, isLoading, isError }) => {
  return (
    <CoreSubHeader
      title="Leaderboard"
      right={<RaceSelector validRaces={data?.validRaces} isError={isError} />}
      bottom={
        <span className="h-4 sm:h-5 text-xxs sm:text-sm text-cool-gray-200 font-medium">
          {isLoading ? (
            <Skeleton className="w-40 sm:w-64 h-2 mt-1 sm:mt-2" />
          ) : data?.start && data?.end ? (
            `${formatTimestamp(data?.start)} - ${formatTimestamp(data?.end)}`
          ) : (
            ''
          )}
        </span>
      }
    />
  )
}
