export const fixString = (value: string, fractionDigits = 2): number => {
  const fractions = fractionDigits
  const amount =
    value.split('.')[0] +
    '.' +
    (value.split('.')?.[1] || '0')?.slice(0, fractions)
  return Number.parseFloat(String(Number(amount)))
}

export const formatCurrency = (
  value: string,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2
) => {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(fixString(value, maximumFractionDigits))
}
