import { useCallback, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { formatCurrency } from '../utils/formatCurrency'

import { appConfig } from '@/config'

const fetchPrice = async (): Promise<number> => {
  const response = await fetch(
    'https://api.coingecko.com/api/v3/simple/price?ids=republik&vs_currencies=usd'
  )

  console.log('fetch rpk/usd price from coingecko')

  const data: { republik: { usd: number } } = await response.json()
  return data.republik.usd
}

export const usePrice = () => {
  const {
    data: usd,
    error,
    isLoading,
  } = useQuery<number, Error>({
    queryKey: ['usdPrice'],
    queryFn: fetchPrice,
    staleTime: 1000 * 60 * 60, // 60 minutes
  })

  const convertToUsd = useCallback(
    (amount: number | null): JSX.Element | null => {
      if (!usd || !amount) return null
      return (
        <span title={`1 ${appConfig.currency} ≈ ${usd} USD`}>
          ≈{formatCurrency(String(amount * usd))}
          <span className="pl-[1px] text-[9px]">USD</span>
        </span>
      )
    },
    [usd]
  )

  const currentPrice = useMemo<JSX.Element | null>(() => {
    if (!usd) return null
    return (
      <>
        {formatCurrency(String(usd), 5, 5)}{' '}
        <span className="pl-[1px] text-[9px]">USD</span>
      </>
    )
  }, [usd])

  return { usd, error, isLoading, currentPrice, convertToUsd }
}
