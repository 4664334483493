import { FC, useEffect, useMemo, useState } from 'react'

interface UsernameCountdownProps {
  distributionTimeElapsed: bigint
  distributionTimePeriod: bigint
  item: string
}

export const CountdownContainer: FC<UsernameCountdownProps> = ({
  distributionTimeElapsed,
  distributionTimePeriod,
  item,
}) => {
  const elapsedTime = useMemo(
    () => distributionTimeElapsed,
    [distributionTimeElapsed]
  )

  const [timeLeft, setTimeLeft] = useState(0n)

  useEffect(() => {
    if (
      distributionTimePeriod &&
      distributionTimePeriod > 0n &&
      elapsedTime >= 0
    ) {
      const initialTimeLeft = BigInt(distributionTimePeriod - elapsedTime)
      setTimeLeft(initialTimeLeft)

      const intervalId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft > 0n) {
            return prevTimeLeft - 1n
          } else {
            clearInterval(intervalId)
            return 0n
          }
        })
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [distributionTimePeriod, elapsedTime])

  return (
    <span
      className={`${
        Number(timeLeft) > 0
          ? `animate-linear font-bold
            bg-gradient-to-r bg-clip-text text-transparent 
            from-cool-gray-300 via-purple-500 to-success
            animate-text border-1 bg-[length:200%_auto] text-right`
          : 'text-white text-right'
      }`}
    >
      {Number(timeLeft) > 0 ? '⚡' : ''}
      {item}
    </span>
  )
}
