import { SocialMedia } from '../types'

import { appConfig } from '@/config'

export const getSocialMediaUrl = (
  socialMedia: SocialMedia,
  username: string
) => {
  if (socialMedia === 'instagram')
    return `https://www.instagram.com/${username}`
  if (socialMedia === 'tiktok') return `https://www.tiktok.com/@${username}`
  if (socialMedia === 'twitter') return `https://x.com/${username}`
  if (socialMedia === 'republik')
    return `${appConfig.clientAppBaseUrl}@${username}`
}
