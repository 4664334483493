import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { VirtualItem } from '@tanstack/react-virtual'

import { StockPill } from '@/core/components/StockPill'
import { TokenAvatar } from '@/core/components/TokenAvatar'
import { usePrice } from '@/core/hooks/usePrice'
import { formatCurrency } from '@/core/utils/formatCurrency'
import { compactNumbers, rem } from '@/core/utils/utils'

import { TokenModel } from '../../hooks/useVirtualList'
import { getTotalFollowers } from '../../utils/getTotalFollowers'
import { CountdownContainer } from '../CountdownContainer'

import { InstagramIcon } from '@/assets/InstagramIcon'
import { TikTokIcon } from '@/assets/TikTokIcon'
import { XIcon } from '@/assets/XIcon'

type RowProps = {
  item: TokenModel
  virtualRow: VirtualItem
}

export const Row: FC<RowProps> = ({ item, virtualRow }) => {
  const navigate = useNavigate()
  const { totalFollowers, connectedSocials } = getTotalFollowers(item?.socials)
  const { convertToUsd } = usePrice()

  if (!item) return null

  return (
    <div
      key={virtualRow.index}
      className="flex items-center text-sm md:text-sm gap-x-2 cursor-pointer"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: rem(virtualRow.size),
        transform: `translateY(${rem(virtualRow.start)})`,
      }}
      onClick={() => navigate(`/@${item.username}`)}
    >
      <div
        className="flex items-center flex-auto"
        style={{ height: rem(virtualRow.size) }}
      >
        <TokenAvatar
          userId={item?.userId}
          username={item?.username}
          className="-ml-2"
        />
        <div className="flex flex-col justify-start">
          {item?.username}
          <div className="flex flex-row items-center text-xxs text-cool-gray-300 mt-1/2 md:hidden">
            {compactNumbers(totalFollowers)}
            <div className="flex flex-row items-center ml-1.5 gap-x-[1px]">
              {connectedSocials?.map((social) => {
                if (social === 'instagram')
                  return <InstagramIcon key={social} className="w-3 h-3" />
                if (social === 'tiktok')
                  return <TikTokIcon key={social} className="w-2.5 h-2.5" />
                if (social === 'twitter')
                  return <XIcon key={social} className="w-3 h-3" />
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:flex md:flex-row w-10 md:w-20 items-center justify-end text-right">
        {compactNumbers(totalFollowers)}
        <div className="flex flex-row items-center text-cool-gray-300 gap-x-0.5 ml-1">
          {connectedSocials?.map((social) => {
            if (social === 'instagram')
              return <InstagramIcon key={social} className="w-3.5 h-3.5" />
            if (social === 'tiktok')
              return <TikTokIcon key={social} className="w-3 h-3" />
            if (social === 'twitter')
              return <XIcon key={social} className="w-3.5 h-3.5" />
          })}
        </div>
      </div>
      <div className="flex-none md:w-16 text-right hidden md:block">
        {compactNumbers(item?.totalSupply)}
      </div>
      <div className="flex-none md:w-24 text-right hidden md:block">
        {compactNumbers(item?.total24hVolume)}
      </div>
      <div className="flex-none md:w-24 text-right hidden md:block">
        <div className="font-bold">
          <CountdownContainer
            distributionTimeElapsed={item?.booster?.distributionTimeElapsed}
            distributionTimePeriod={item?.booster?.distributionTimePeriod}
            item={formatCurrency(String(item?.price), 2)}
          />
        </div>
        <div className="text-xs text-cool-gray-300">
          {convertToUsd(item?.price)}
        </div>
      </div>
      <div className="flex-none md:w-20 text-right md:hidden">
        <div className="font-bold">
          <CountdownContainer
            distributionTimeElapsed={item?.booster?.distributionTimeElapsed}
            distributionTimePeriod={item?.booster?.distributionTimePeriod}
            item={formatCurrency(String(item?.price), 2)}
          />
        </div>
        <div className="text-xs text-cool-gray-300">
          {compactNumbers(item?.totalSupply)}
        </div>
      </div>
      <div className="flex-none w-24 md:w-28 flex justify-end">
        <StockPill value={item?.priceChange24h} />
      </div>
    </div>
  )
}
