import { useEffect, useRef } from 'react'
import { createChart, LastPriceAnimationMode, Time } from 'lightweight-charts'

export const ChartSkeleton = () => {
  const chartContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const chart = createChart(chartContainerRef.current || '', {
      autoSize: true,
      layout: {
        background: { color: '#131820' },
      },
      grid: {
        vertLines: { visible: false },
        horzLines: { visible: false },
      },
      timeScale: { visible: false },
      rightPriceScale: {
        borderVisible: false,
        visible: false,
      },
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: false,
        },
        vertLine: { visible: false },
      },
      handleScale: false,
      handleScroll: false,
    })

    const areaSeries = chart.addAreaSeries({
      lineColor: '#384761',
      topColor: '#252F41',
      bottomColor: '#252F41',
      lastPriceAnimation: LastPriceAnimationMode.Disabled,
      priceLineVisible: false,
      crosshairMarkerVisible: false,
      lastValueVisible: false,
    })
    areaSeries.setData([
      { time: 1719222649, value: 36 },
      { time: 1719222933, value: 64 },
      { time: 1719222986, value: 25 },
      { time: 1719487182, value: 67 },
      { time: 1719493895, value: 58 },
      { time: 1719494875, value: 77 },
      { time: 1719494899, value: 58 },
      { time: 1719494972, value: 77 },
      { time: 1719505558, value: 58 },
      { time: 1719505654, value: 77 },
      { time: 1719505699, value: 58 },
      { time: 1719505785, value: 77 },
      { time: 1719506420, value: 58 },
      { time: 1719506464, value: 77 },
      { time: 1719506480, value: 91 },
      { time: 1719506499, value: 67 },
      { time: 1719585981, value: 91 },
      { time: 1719586122, value: 106 },
      { time: 1719834338, value: 124 },
      { time: 1719834455, value: 166 },
      { time: 1719835586, value: 275 },
      { time: 1719836226, value: 91 },
      { time: 1719860602, value: 216 },
    ] as unknown as {
      time: Time
      value: number
    }[])
    chart.timeScale().fitContent()

    return () => chart.remove()
  }, [])

  return (
    <div className="w-full h-40 animate-pulse mb-5" ref={chartContainerRef} />
  )
}
