import { FC, useCallback, useState } from 'react'

import { useAlchemyPayUrl } from '../hooks/useAlchemyPayUrl'
import { Button } from './common/Button'
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from './common/Drawer'

import creditCardIcon from '@/assets/credit-card-icon.svg'
import uniswapIcon from '@/assets/uniswap-logo.svg'
import { appConfig } from '@/config'

type GetCurrencyDrawerProps = {
  className?: string
}

export const GetCurrencyDrawer: FC<GetCurrencyDrawerProps> = ({
  className = '',
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { refetch, isFetching } = useAlchemyPayUrl()

  const handleOnClick = useCallback(async () => {
    const { data } = await refetch()
    setTimeout(() => {
      if (data?.url) window.open(data?.url)
    }, 1)
  }, [refetch])

  return (
    <Drawer open={isOpen} onOpenChange={setIsOpen} shouldScaleBackground>
      <DrawerTrigger asChild onClick={() => setIsOpen(true)}>
        <Button className={className} size="sm" variant="purpleAnimatedOutline">
          GET {appConfig.currency}
        </Button>
      </DrawerTrigger>
      <DrawerContent onOverlayClick={() => setIsOpen(false)}>
        <div className="mx-auto w-full max-w-sm">
          <DrawerHeader className="flex flex-col justify-center">
            <DrawerTitle className="capitalize text-center md:text-xl">
              Buy {appConfig.currency}
            </DrawerTitle>
            <div className="flex flex-col md:mb-2 md:mt-1 items-start -ml-4 md:-ml-6">
              <Button
                variant="link"
                size="md"
                className="md:!text-lg md:mb-3"
                onClick={handleOnClick}
                disabled={isFetching}
              >
                <img
                  src={creditCardIcon}
                  className="w-5 md:w-10 h-5 md:h-10 mr-1 md:mr-2"
                />
                Credit card (Alchemy Pay)
              </Button>
              <Button
                variant="link"
                size="md"
                className="flex items-center md:!text-lg"
                onClick={() => window.open(appConfig.uniswapUrl)}
              >
                <img
                  src={uniswapIcon}
                  className="w-5 md:w-10 h-5 md:h-10 mr-1 md:mr-2"
                />
                Uniswap
              </Button>
            </div>
          </DrawerHeader>
        </div>
      </DrawerContent>
    </Drawer>
  )
}
