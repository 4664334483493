import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react'

import { Button } from '@/core/components/common/Button'
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@/core/components/common/Dialog'

import { Socials } from '../hooks/useTokenDetails'
import { ShareDialog } from './ShareDialog'

import { appConfig } from '@/config'

type SpreadTheHypeDialogProps = {
  username?: string
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  socials: Socials | undefined
}

export const SpreadTheHypeDialog: FC<SpreadTheHypeDialogProps> = ({
  username,
  socials,
  isOpen,
  setIsOpen,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleDismiss = useCallback(
    (open: boolean) => {
      setIsOpen(open)
    },
    [setIsOpen]
  )

  const handleShareClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()

      setIsDialogOpen(true)
      setIsOpen(false)
    },
    [setIsDialogOpen, setIsOpen]
  )

  return (
    <>
      <ShareDialog
        username={username}
        socials={socials}
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
      />
      <Dialog open={isOpen} onOpenChange={handleDismiss}>
        <DialogContent className="max-w-[27rem]">
          <DialogTitle className="">
            <div className="absolute -z-10 w-full h-full bg-cool-gray-850 bg-[url('/src/assets/mobile-bg@2x.png')] md:bg-[url('/src/assets/desktop-bg@2x.png')] bg-cover bg-center bg-no-repeat" />
            <div className="flex flex-col justify-center items-center text-center py-8 px-7 md:px-12">
              <span className="text-lg font-bold mb-7">Spread the Hype!</span>
              <span className="text-sm mb-7">
                Share the link to this {appConfig.marketName}. The more traders
                you bring in, the more the STOX price can pump 🚀
              </span>
              <Button
                className="gtm-spread-the-hype-button"
                variant="purpleAnimatedOutline"
                onClick={handleShareClick}
              >
                Share now
              </Button>
            </div>
          </DialogTitle>
        </DialogContent>
      </Dialog>
    </>
  )
}
