import { FC, ReactNode } from 'react'
import { isMobile } from 'react-device-detect'
import { InfoCircledIcon } from '@radix-ui/react-icons'

import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from './common/Drawer'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from './common/HoverCard'

interface InfoHoverCardProps {
  children: ReactNode
  title: string
}

export const InfoHoverCard: FC<InfoHoverCardProps> = ({ children, title }) => {
  if (isMobile) {
    return (
      <Drawer>
        <DrawerTrigger asChild>
          <button className="text-sm flex flex-row items-center float-left">
            <span className="pr-1">
              <InfoCircledIcon />
            </span>
            <span>{title}</span>
          </button>
        </DrawerTrigger>
        <DrawerContent>
          <div className="mx-auto w-full max-w-sm text-cool-gray-200">
            <DrawerHeader>
              <DrawerTitle>{title}</DrawerTitle>
              <DrawerDescription>{children}</DrawerDescription>
            </DrawerHeader>
          </div>
        </DrawerContent>
      </Drawer>
    )
  }

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <button className="text-sm flex flex-row items-center float-left">
          <span className="pr-1">
            <InfoCircledIcon />
          </span>
          <span>{title}</span>
        </button>
      </HoverCardTrigger>
      <HoverCardContent className="w-80">
        <h4 className="text-sm font-semibold">{title}</h4>
        <p className="text-sm">{children}</p>
      </HoverCardContent>
    </HoverCard>
  )
}
