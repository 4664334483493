import { FC } from 'react'

import { Badge } from '@/core/components/common/Badge'

import { formatPercentageValue } from '../utils/formatPercentageValue'

type StockPillProps = {
  value?: number
}

export const StockPill: FC<StockPillProps> = ({ value }) => {
  const formattedValue = Number(value?.toFixed(2))
  return (
    <Badge
      className="w-[5.5rem] h-[1.8rem] justify-center items-center"
      variant={
        formattedValue === 0
          ? 'neutral'
          : formattedValue > 0
            ? 'success'
            : 'danger'
      }
    >
      {formatPercentageValue(value || 0)}
    </Badge>
  )
}
