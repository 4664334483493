import { Skeleton } from '@/core/components/common/Skeleton'

export const LeaderboardSkeleton = () => {
  return (
    <div className="w-full aspect-[2.94] flex flex-row items-center">
      <div className="h-full w-full md:pb-11 pb-6 pt-3">
        <Skeleton className="w-full rounded-xl flex md:rounded-3xl h-full" />
      </div>
    </div>
  )
}
