import { useLocation, useNavigate } from 'react-router-dom'
import { ChevronLeftIcon } from '@radix-ui/react-icons'

import { Button } from './common/Button'

export const BackButton = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleGoBack = () => {
    if (location.key === 'default') return navigate('/')

    navigate(-1)
  }

  return (
    <Button variant="link" size="md" onClick={handleGoBack}>
      <ChevronLeftIcon className="-ml-4" />
      Back
    </Button>
  )
}
