import { useEffect, useMemo, useState } from 'react'

interface useCountdownProps {
  distributionTimeElapsed: bigint
  distributionTimePeriod: bigint
}

export const useCountdown = ({
  distributionTimeElapsed,
  distributionTimePeriod,
}: useCountdownProps) => {
  const elapsedTime = useMemo(
    () => distributionTimeElapsed,
    [distributionTimeElapsed]
  )

  const [timeLeft, setTimeLeft] = useState<bigint | null>(null)

  useEffect(() => {
    if (
      distributionTimePeriod &&
      distributionTimePeriod > 0n &&
      elapsedTime >= 0
    ) {
      const initialTimeLeft = BigInt(distributionTimePeriod - elapsedTime)
      setTimeLeft(initialTimeLeft)

      const intervalId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft == null) return null
          if (prevTimeLeft > 0n) {
            return prevTimeLeft - 1n
          } else {
            clearInterval(intervalId)
            return 0n
          }
        })
      }, 1000)

      return () => clearInterval(intervalId)
    } else {
      setTimeLeft(0n)
    }
  }, [distributionTimePeriod, elapsedTime])

  return {
    timeLeft,
  }
}
