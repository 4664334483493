import { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

import { SubHeaderType } from '@/core/layouts/MainLayout'

import { VirtualList } from '../components/VirtualList'

export const TokenList = () => {
  const { setSubHeader } = useOutletContext<SubHeaderType>()

  useEffect(() => {
    setSubHeader(<></>)
  }, [setSubHeader])

  return <VirtualList />
}
