import { FC } from 'react'

type TriangleUpProps = {
  className?: string
}

export const TriangleUp: FC<TriangleUpProps> = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 9H11L7.5 4.5L4 9Z" fill="currentColor"></path>
    </svg>
  )
}
