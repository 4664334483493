import { FC } from 'react'

import { useNetwork } from '../hooks/useNetwork'

export const OfflineIndicator: FC = () => {
  const { isOnline } = useNetwork()
  if (isOnline) return null

  return (
    <>
      <div className="absolute h-safe-area-top top-0 w-full bg-red-700 z-10"></div>
      <div className="cursor-pointer w-full text-xs text-white bg-red-700 border-b border-red-500 items-center justify-center relative z-10">
        <div className="px-4 py-2 grow font-bold text-center">
          Oops! It seems like your device is currently offline.
        </div>
      </div>
    </>
  )
}
