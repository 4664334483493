import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { WagmiProvider } from 'wagmi'
import { arbitrum } from 'wagmi/chains'

import '@/core/utils/tracking/newrelic'
import { Toaster } from './core/components/common/Toaster'
import Loader from './core/components/Loader'
import { SafeAreaProvider } from './core/components/SafeAreaProvider'

import {
  persister,
  queryClient,
  rainbowKitTheme,
  wagmiProviderConfig,
} from './config'
import { router } from './router'

import './assets/index.scss'
import './polyfills'
import '@rainbow-me/rainbowkit/styles.css'

if (
  'screen' in window &&
  'orientation' in window.screen &&
  'lock' in window.screen.orientation &&
  typeof window.screen.orientation.lock === 'function'
) {
  ;(window.screen.orientation.lock('portrait') as Promise<void>).catch(
    (e: Error) => {
      console.log(e)
    }
  )
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Loader />
    <WagmiProvider config={wagmiProviderConfig}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
      >
        <RainbowKitProvider
          initialChain={arbitrum}
          theme={darkTheme(rainbowKitTheme)}
        >
          <SafeAreaProvider>
            <Toaster />
            <RouterProvider router={router} />
          </SafeAreaProvider>
        </RainbowKitProvider>
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-left"
        />
      </PersistQueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
)
