import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import {
  binanceWallet,
  bybitWallet,
  coinbaseWallet,
  krakenWallet,
  ledgerWallet,
  metaMaskWallet,
  okxWallet,
  trustWallet,
  uniswapWallet,
} from '@rainbow-me/rainbowkit/wallets'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { arbitrum, arbitrumSepolia } from '@wagmi/core/chains'
import { compress, decompress } from 'lz-string'
import { webSocket, WebSocketTransport } from 'viem'
import { createConfig, deserialize, serialize } from 'wagmi'
import { hashFn } from 'wagmi/query'

export interface IApplicationConfig {
  stage: string
  marketName: string
  projectId: string
  tokenAddress: `0x${string}`
  creatorTokenContractAddress: `0x${string}`
  currency: string
  ceiling: number
  apiBaseUrl: string
  uniswapUrl: string
  arbiscanUrl: string
  clientAppBaseUrl: string
  avatarBaseURl: string
  arbitrumTransportUrl: string
  zarklabApiBaseUrl: string
  isChatAIVisible: boolean
  arbitrumWebSocketTransportUrl: string
  showBuyCurrencyDrawer: boolean
  leaderboardPrizePool: number
}

const appConfig: IApplicationConfig = {
  stage: process.env.REACT_APP_STAGE || '',
  marketName: 'STOX',
  projectId: '06efe97ae59b0acdaa721a2cc4e277e5',
  tokenAddress: (process.env.REACT_APP_TOKEN_ADDRESS as `0x${string}`) || '',
  creatorTokenContractAddress:
    (process.env.REACT_APP_CREATOR_TOKEN_CONTRACT_ADDRESS as `0x${string}`) ||
    '',
  currency: 'RPK',
  ceiling: Number(process.env.REACT_APP_SLIPPAGE),
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '',
  uniswapUrl:
    'https://app.uniswap.org/swap?chain=arbitrum&outputCurrency=0x412f4b922bb9afdc65c7e9b669f4bec6e554452a',
  arbiscanUrl: process.env.REACT_APP_ARBISCAN_URL || '',
  clientAppBaseUrl: process.env.REACT_APP_CLIENT_APP_BASE_URL || '',
  avatarBaseURl: process.env.REACT_APP_AVATAR_BASE_URL || '',
  arbitrumTransportUrl: process.env.REACT_APP_ARBITRUM_TRANSPORT_URL || '',
  zarklabApiBaseUrl: process.env.REACT_APP_ZARKLAB_API_BASE_URL || '',
  isChatAIVisible: process.env.REACT_APP_CHAT_AI_VISIBLE === 'true' || false,
  arbitrumWebSocketTransportUrl:
    process.env.REACT_APP_ARBITRUM_WEBSOCKET_TRANSPORT_URL || '',
  showBuyCurrencyDrawer:
    process.env.REACT_APP_SHOW_BUY_RPK_DRAWER === 'true' || false,
  leaderboardPrizePool:
    Number(process.env.REACT_APP_LEADERBOARD_PRIZE_POOL) || 0,
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      networkMode: 'offlineFirst',
      queryKeyHashFn: hashFn,
    },
  },
})

const persister = createSyncStoragePersister({
  key: 'market-cache',
  serialize: (data) => compress(serialize(data)),
  storage: window.localStorage,
  deserialize: (data) => deserialize(decompress(data)),
})

const webSocketTransport = webSocket(appConfig.arbitrumWebSocketTransportUrl, {
  keepAlive: { interval: 45_000 },
})

const transports: Record<number, WebSocketTransport> = {
  [appConfig.stage === 'production' ? arbitrum.id : arbitrumSepolia.id]:
    webSocketTransport,
}

const wagmiProviderConfig = getDefaultConfig({
  appName: 'RepubliK Market',
  projectId: appConfig.projectId,
  chains: appConfig.stage === 'production' ? [arbitrum] : [arbitrumSepolia],
  transports,
  wallets: [
    {
      groupName: 'Wallets',
      wallets: [
        metaMaskWallet,
        okxWallet,
        trustWallet,
        binanceWallet,
        bybitWallet,
        coinbaseWallet,
        uniswapWallet,
        krakenWallet,
        ledgerWallet,
      ],
    },
  ],
})

const rainbowKitTheme = {
  accentColor: '#CCFF00',
  accentColorForeground: 'black',
  backgroundColor: '#131820',
  foregroundColor: 'white',
  textColor: 'white',
  fonts: {
    body: 'Roboto, Inter, sans-serif',
    heading: 'Roboto, Inter, sans-serif',
  },
}

const transportConfig = createConfig({
  chains: appConfig.stage === 'production' ? [arbitrum] : [arbitrumSepolia],
  transports,
})

export {
  queryClient,
  wagmiProviderConfig,
  rainbowKitTheme,
  appConfig,
  persister,
  transportConfig,
}
