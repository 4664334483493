import { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useWindowVirtualizer } from '@tanstack/react-virtual'

import { SortMethod } from '@/core/types'
import PullToRefresh from '@/core/components/PullToRefresh'
import { SortController } from '@/core/components/SortController'
import { TradingCompetitionBanner } from '@/core/components/TradingCompetitionBanner'
import { rem } from '@/core/utils/utils'

import { useVirtualList } from '../../hooks/useVirtualList'
import { SubHeader } from '../SubHeader'
import { Row } from './Row'
import { RowSkeleton } from './RowSkeleton'

import { appConfig } from '@/config'

export const VirtualList: FC = () => {
  const [searchParams] = useSearchParams()
  const listRef = useRef<HTMLDivElement | null>(null)
  const sortMethod = searchParams?.get('type')
  const navigate = useNavigate()

  const {
    data,
    refetch,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isSuccess,
  } = useVirtualList()

  const rowVirtualizer = useWindowVirtualizer({
    count: hasNextPage ? data.length + 1 : data.length,
    scrollMargin: 0,
    estimateSize: useCallback(() => 48, []),
    overscan: 5,
  })

  const changeSortMethod = useCallback(
    async (sortMethodOption: SortMethod) => {
      if (sortMethodOption && sortMethod !== sortMethodOption) {
        searchParams.set('type', sortMethodOption || '')
      } else {
        searchParams.delete('type')
      }

      await navigate({ search: searchParams.toString() })
      refetch()
    },
    [navigate, refetch, searchParams, sortMethod]
  )

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse()

    if (!lastItem) {
      return
    }

    if (
      lastItem.index >= data.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasNextPage,
    fetchNextPage,
    data.length,
    isFetchingNextPage,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    rowVirtualizer.getVirtualItems(),
  ])

  const showTableHeader = useMemo(() => {
    return isLoading || data?.length > 0
  }, [isLoading, data])

  const renderLoading = useCallback(
    () => (
      <div
        className="virtual-container divide-y divide-cool-gray-850"
        style={{
          height: rem(rowVirtualizer.getTotalSize()),
          width: '100%',
          position: 'relative',
        }}
      >
        {[...Array(15)].map((_: unknown, index: number) => (
          <RowSkeleton key={index} />
        ))}
      </div>
    ),
    [rowVirtualizer]
  )

  const renderEmpty = useCallback(
    () => (
      <div className="flex flex-col w-full items-center text-center pt-3 px-3">
        <img
          src="/no-results.png"
          alt="no-results"
          className="md:max-w-96 mb-5 px-16"
        />
        <div className="text-lg font-bold mb-3">
          We couldn&apos;t find any matching {appConfig.marketName} for your
          search
        </div>
        <div>Try using different keywords or check your spelling</div>
      </div>
    ),
    []
  )

  return (
    <div className="flex flex-col w-full top-0">
      <div className="flex flex-col items-center justify-between">
        <TradingCompetitionBanner prizePool={appConfig.leaderboardPrizePool} />
      </div>
      <div className="z-20 sticky bg-cool-gray-900 justify-between safe-top-[4px] md:safe-top-[60px]">
        <SubHeader refetch={refetch} />
      </div>
      {isLoading ? (
        renderLoading()
      ) : isSuccess && data?.length === 0 ? (
        renderEmpty()
      ) : (
        <div ref={listRef} className="top-0 w-full h-full flex flex-col mb-3">
          {showTableHeader && (
            <div className="flex-col bg-cool-gray-900 z-20 sticky justify-between safe-top-[94px] md:safe-top-[108px]">
              <div className="flex w-full max-w-3xl mx-auto">
                <div className="top-0 flex items-center w-full text-xs text-cool-gray-400 py-2 gap-x-2">
                  <div className="flex flex-1">{appConfig.marketName}</div>
                  <div className="md:flex w-10 md:w-20 text-right hidden justify-end">
                    <SortController
                      title="Popularity"
                      columnName="followercount"
                      changeSortMethod={changeSortMethod}
                    />
                  </div>
                  <div className="hidden md:flex w-16 text-right justify-end">
                    <SortController
                      title="Supply"
                      columnName="supply"
                      changeSortMethod={changeSortMethod}
                    />
                  </div>
                  <div className="md:flex w-16 md:w-24 text-right justify-end hidden">
                    <SortController
                      title="24h Volume"
                      columnName="volume"
                      changeSortMethod={changeSortMethod}
                    />
                  </div>
                  <div className="md:flex w-16 md:w-24 text-right justify-end hidden">
                    <SortController
                      title={`Price ${appConfig.currency}`}
                      columnName="price"
                      changeSortMethod={changeSortMethod}
                    />
                  </div>
                  <div className="flex justify-end w-16 md:w-20 text-right md:hidden">
                    <SortController
                      title={`Price(${appConfig.currency}) / Supply`}
                      columnName="price"
                      changeSortMethod={changeSortMethod}
                    />
                  </div>
                  <div className="flex w-24 justify-end md:w-28">
                    <SortController
                      title="Change 24h"
                      columnName="change-24h"
                      changeSortMethod={changeSortMethod}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <PullToRefresh
            onRefresh={refetch}
            isPullable={true}
            canFetchMore={true}
            fetchMoreThreshold={100}
            pullDownThreshold={67}
            maxPullDownDistance={95}
            resistance={1}
          >
            <div
              className="virtual-container overflow-hidden divide-y divide-cool-gray-850"
              style={{
                height: rem(rowVirtualizer.getTotalSize()),
                width: '100%',
                position: 'relative',
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                <Row
                  key={virtualRow.index}
                  item={data[virtualRow.index]}
                  virtualRow={virtualRow}
                />
              ))}
            </div>
          </PullToRefresh>
        </div>
      )}
    </div>
  )
}
