import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const formatTimestamp = (timestamp?: number) => {
  if (timestamp == null) return ''

  return dayjs.unix(timestamp).utc().format('DD.MM.YYYY hh:mm A [UTC]')
}
