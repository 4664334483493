import { FC, useCallback, useEffect, useState } from 'react'
import { useChainModal } from '@rainbow-me/rainbowkit'
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import debounce from 'lodash/debounce'
import { GetBalanceErrorType } from 'viem'
import { useAccount, useConfig } from 'wagmi'

import { Button } from '@/core/components/common/Button'
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/core/components/common/Drawer'
import { LoadingDots } from '@/core/components/common/LoadingDots'
import { NumberSelector } from '@/core/components/common/NumberSelector'
import { GetCurrencyButton } from '@/core/components/GetCurrencyButton'
import { GetCurrencyDrawer } from '@/core/components/GetCurrencyDrawer'
import { InfoHoverCard } from '@/core/components/InfoHoverCard'
import { ConnectButton } from '@/core/components/TopNavBar/ConnectButton'
import { TransactionDialog } from '@/core/components/TransactionDialog'
import { WarningDialog } from '@/core/components/WarningDialog'
import { cn } from '@/core/utils/classNames'
import { formatCurrency } from '@/core/utils/formatCurrency'
import { Tracking, TrackingEvent } from '@/core/utils/tracking'

import { usePermitAndExecute } from '../hooks/usePermitAndExecute'
import { Socials } from '../hooks/useTokenDetails'
import { PriceData } from '../hooks/useTransaction'
import { SpreadTheHypeDialog } from './SpreadTheHypeDialog'

import { appConfig, queryClient } from '@/config'

interface TransactionDrawerProps {
  triggerClassName?: string
  type: 'buy' | 'sell'
  price: PriceData
  tokenId: number | undefined
  amountInBalance: number
  convertToUsd: (amount: number | null) => JSX.Element | null
  isConnected: boolean
  userId?: string
  username?: string
  accountBalance?: string
  refetchBalance: (options?: RefetchOptions) => Promise<
    QueryObserverResult<
      {
        decimals: number
        formatted: string
        symbol: string
        value: bigint
      },
      GetBalanceErrorType
    >
  >
  socials: Socials | undefined
  refetchTransaction: () => void
}

export const TransactionDrawer: FC<TransactionDrawerProps> = ({
  triggerClassName,
  type,
  price,
  tokenId,
  amountInBalance,
  convertToUsd,
  isConnected,
  userId,
  username,
  accountBalance = '0',
  refetchBalance,
  socials,
  refetchTransaction,
}) => {
  const { openChainModal } = useChainModal()
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenWarningDialog, setIsOpenWarningDialog] = useState(false)
  const [isSpreadHypeDialogOpen, setIsSpreadHypeDialogOpen] = useState(false)
  const [amount, setAmount] = useState(1)
  const [open, setOpen] = useState(false)
  const { chains: wagmiChains } = useConfig()
  const { chainId, address } = useAccount()
  const isCurrentChainSupported = wagmiChains.some(
    (chain) => chain.id === chainId
  )

  const onSuccessfulTransaction = useCallback(
    async ({
      totalPrice,
      amount,
      walletId,
    }: {
      totalPrice: string
      amount: number
      walletId: `0x${string}`
    }) => {
      if (type === 'buy') {
        const hasSeenModal = localStorage.getItem('hasSeenSpreadHypeModal')

        if (!hasSeenModal) {
          setIsSpreadHypeDialogOpen(true)
          localStorage.setItem('hasSeenSpreadHypeModal', 'true')
        }
      }

      Tracking.triggerEvent(
        type === 'buy' ? TrackingEvent.Buy : TrackingEvent.Sell,
        {
          userId: String(userId),
          username: String(username),
          tokenId: String(tokenId),
          walletId,
          totalPrice: Number(parseFloat(totalPrice).toFixed(2)),
          amount,
        }
      )

      setTimeout(() => {
        queryClient.refetchQueries({
          queryKey: ['tokenDetails', String(`@${username}`)],
        })
        queryClient.refetchQueries({
          queryKey: ['activities', String(tokenId)],
        })
        refetchTransaction()
        refetchBalance()
      }, 5000)
    },
    [type, userId, username, tokenId, refetchBalance, refetchTransaction]
  )

  const {
    onExecute,
    getPrice,
    calculatedPrice,
    resetCalculatedPrice,
    isGetPriceLoading,
    isTransactionLoading,
    isTransactionAborted,
    setTransactionAborted,
  } = usePermitAndExecute({ tokenId: String(tokenId), onSuccessfulTransaction })

  const isWithinBudget =
    Number(accountBalance) >=
    Number(calculatedPrice?.total || price.total || '0')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callPrice = useCallback(
    debounce(() => {
      getPrice({ tokenId: String(tokenId), amount, type })
    }, 500),
    [tokenId, amount, type]
  )

  useEffect(() => {
    setIsLoading(true)
  }, [amount])

  useEffect(() => {
    setIsLoading(false)
  }, [calculatedPrice])

  useEffect(() => {
    callPrice()
    return () => {
      callPrice.cancel()
    }
  }, [amount, callPrice])

  useEffect(() => {
    setIsOpenDialog(false)
  }, [isTransactionLoading])

  useEffect(() => {
    if (isTransactionAborted) {
      setIsOpenDialog(false)
      setTransactionAborted(false)
    }
  }, [isTransactionAborted, setTransactionAborted])

  return (
    <>
      <TransactionDialog
        isOpen={isOpenDialog}
        isStepTwo={true}
        onOpenChange={setIsOpenDialog}
      />
      <WarningDialog
        isOpenDialog={isOpenWarningDialog}
        setIsOpenWarningDialog={setIsOpenWarningDialog}
      />
      <SpreadTheHypeDialog
        username={username}
        socials={socials}
        isOpen={isSpreadHypeDialogOpen}
        setIsOpen={setIsSpreadHypeDialogOpen}
      />
      <Drawer
        open={open}
        onOpenChange={(isOpen) => setOpen(isOpen)}
        shouldScaleBackground
      >
        <DrawerTrigger asChild onClick={() => setOpen(true)}>
          <div className="cursor-pointer bg-cool-gray-800 rounded-lg">
            <div
              className={cn(
                `pt-4 pb-4 rounded-lg rounded-b-none`,
                type === 'buy' ? 'bg-success/5' : 'bg-danger/5',
                Number(price?.value) > 100_000 ? 'text-sm' : 'text-xl'
              )}
            >
              <div className="flex justify-center">
                <div className="flex flex-col">
                  <div className="">
                    <span className="h-3">
                      {price?.value
                        ? formatCurrency(String(price?.value), 2)
                        : ''}
                    </span>
                    <span className="text-xs pl-[1px] text-cool-gray-200">
                      {appConfig.currency}
                    </span>
                  </div>
                  <div className="text-xs font-normal text-cool-gray-300 text-right">
                    {convertToUsd(Number(price?.value))}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Button
                variant={type == 'buy' ? 'success' : 'danger'}
                className={triggerClassName}
                onClick={() => {
                  setAmount(1)
                  resetCalculatedPrice()
                  setIsOpenWarningDialog(false)
                  Tracking.triggerEvent(TrackingEvent.ClickBuyOrSellButton, {
                    userId: String(userId),
                    username: String(username),
                    tokenId: String(tokenId),
                    walletId: address,
                    amount,
                    type,
                  })
                }}
              >
                {type == 'buy' ? 'Buy' : 'Sell'}
              </Button>
            </div>
          </div>
        </DrawerTrigger>
        <DrawerContent onOverlayClick={() => setOpen(false)}>
          <div className="mx-auto w-full max-w-sm">
            <DrawerHeader>
              <DrawerTitle className="capitalize text-center">
                {type} <span className="normal-case">{username}&apos;s </span>
                {appConfig.marketName}
              </DrawerTitle>
            </DrawerHeader>
            <div className="px-4 w-full">
              <ul role="list" className="px-4">
                <li className="text-cool-gray-200 text-sm">
                  <div className="flex items-center">
                    <NumberSelector
                      label="Amount"
                      value={amount}
                      min={1}
                      max={type === 'sell' ? amountInBalance : 100}
                      onChange={(value) => {
                        setAmount(value)
                      }}
                    />
                  </div>
                </li>
                <li className="text-cool-gray-200 text-sm">
                  <div className="flex items-center">
                    <div className="flex-1 min-w-0">Price</div>
                    <div className="inline-flex items-center">
                      {formatCurrency(
                        String(calculatedPrice?.value || price.value)
                      )}{' '}
                      {appConfig.currency}
                    </div>
                  </div>
                </li>
                <li className="text-cool-gray-200 text-sm">
                  <div className="flex items-center">
                    <div className="flex-1 min-w-0">Platform Fee</div>
                    <div className="inline-flex items-center">
                      {formatCurrency(
                        String(
                          calculatedPrice?.platformFee || price.platformFee
                        )
                      )}{' '}
                      {appConfig.currency}
                    </div>
                  </div>
                </li>
                <li className="text-cool-gray-200 text-sm">
                  <div className="flex items-center">
                    <div className="flex-1 min-w-0">Amount you hold</div>
                    <div className="inline-flex items-center">
                      {formatCurrency(String(amountInBalance))}{' '}
                      {appConfig.marketName}
                    </div>
                  </div>
                </li>
                <li className="pb-4 text-cool-gray-200 text-sm">
                  <div className="flex items-center">
                    <div className="flex-1 min-w-0">
                      <InfoHoverCard title="Slippage">
                        Slippage is the difference between the price you expect
                        for a trade and the price you get because of asset
                        volatility and liquidity depth. If the actual slippage
                        exceeds the set percentage, the transaction won’t go
                        through.
                      </InfoHoverCard>
                    </div>
                    <div className="inline-flex items-center">
                      {appConfig.ceiling * 100}%
                    </div>
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <div className="flex-1 min-w-0">
                      <p className="text-lg font-bold">Total Price</p>
                    </div>
                    <div className="inline-flex items-center text-2xl">
                      <span className="font-bold">
                        {formatCurrency(
                          String(calculatedPrice?.total || price.total)
                        )}{' '}
                        {appConfig.currency}
                      </span>
                    </div>
                  </div>
                </li>
                <li className="flex justify-end">
                  <span className="flex text-xs text-cool-gray-300">
                    {convertToUsd(
                      Number(calculatedPrice?.total || price.total)
                    )}
                  </span>
                </li>
              </ul>
            </div>
            <DrawerFooter className="px-8">
              {!isConnected ? (
                <ConnectButton
                  className="flex w-full justify-center"
                  onClick={() => {
                    setOpen(false)
                    Tracking.triggerEvent(
                      TrackingEvent.DrawerConnectWalletButton,
                      {
                        userId: String(userId),
                        username: String(username),
                        tokenId: String(tokenId),
                        walletId: address,
                        amount,
                        type,
                      }
                    )
                  }}
                />
              ) : !isWithinBudget && type == 'buy' ? (
                appConfig.showBuyCurrencyDrawer ? (
                  <GetCurrencyDrawer className="!h-10" />
                ) : (
                  <GetCurrencyButton
                    onClick={() => {
                      Tracking.triggerEvent(TrackingEvent.DrawerGetRPK, {
                        userId: String(userId),
                        username: String(username),
                        tokenId: String(tokenId),
                        walletId: address,
                        amount,
                        type,
                      })
                    }}
                    className="!h-10"
                  />
                )
              ) : isLoading || (isGetPriceLoading && amount > 1) ? (
                <Button variant={type == 'buy' ? 'success' : 'danger'} disabled>
                  <LoadingDots />
                </Button>
              ) : !isCurrentChainSupported ? (
                <Button
                  variant="destructive"
                  onClick={() => {
                    openChainModal?.()
                  }}
                >
                  Switch Network
                </Button>
              ) : (
                <Button
                  variant={type == 'buy' ? 'success' : 'danger'}
                  onClick={() => {
                    if (localStorage.getItem('hasAckWarning') !== 'true') {
                      setIsOpenWarningDialog(true)
                      return
                    }
                    setIsOpenDialog(true)
                    onExecute({
                      type,
                      ceiling:
                        BigInt(calculatedPrice?.ceiling) || price?.ceiling,
                      amount,
                      totalPrice: calculatedPrice?.total || price.total || '0',
                    })
                    setOpen(false)
                    Tracking.triggerEvent(TrackingEvent.DrawerWithinBudget, {
                      userId: String(userId),
                      username: String(username),
                      tokenId: String(tokenId),
                      walletId: address,
                      amount,
                      type,
                    })
                  }}
                  disabled={amountInBalance === 0 && type === 'sell'}
                >
                  Continue
                </Button>
              )}
            </DrawerFooter>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  )
}
