import { useQuery } from '@tanstack/react-query'
import { useAccount } from 'wagmi'

import API from '@/core/services/api'
import { useNetwork } from '@/core/hooks/useNetwork'

export interface IDataItem {
  rank: number
  volume: number
  address: string
}

export interface LeaderboardResponse {
  items: IDataItem[]
  race: number
  start: number
  end: number
  trader: IDataItem
  validRaces: number[] | undefined
}

export const useLeaderboard = ({
  race,
  enabled = true,
}: {
  race?: string
  enabled?: boolean
}) => {
  const { address } = useAccount()
  const { isOnline } = useNetwork()

  const { data, isError, refetch, isLoading, isSuccess } = useQuery<
    LeaderboardResponse,
    Error
  >({
    queryKey: ['leaderboard', race, address].filter(Boolean),
    queryFn: async () => {
      const searchParams = new URLSearchParams()
      if (race) searchParams.append('round', race)
      if (address) searchParams.append('wallet', address)

      const response: LeaderboardResponse = await API.get(
        '/market/leaderboard/traders',
        searchParams
      )

      return {
        ...response,
        validRaces: response?.validRaces?.sort((a, b) => b - a),
      }
    },
    enabled: enabled && isOnline,
  })

  return {
    data,
    refetch,
    isLoading: isLoading || !isOnline,
    isSuccess,
    isError,
  }
}
