import { FC } from 'react'

interface CardProps {
  children: React.ReactNode
  id?: string
  className?: string
  cardRef?: React.RefObject<HTMLDivElement>
  hasAnimatedBg?: boolean
}

export const Card: FC<CardProps> = ({
  children,
  id,
  className = '',
  cardRef,
  hasAnimatedBg = false,
}) => {
  return (
    <div
      ref={cardRef}
      id={id}
      className={`${hasAnimatedBg ? 'rainbow-border' : 'border-[1px] border-cool-gray-700'} p-4 sm:p-6 sm:py-6 bg-cool-gray-850 relative rounded-2xl ${className}`}
    >
      {children}
    </div>
  )
}
