import { FC, memo } from 'react'

export interface RepublikIconProps {
  className?: string
}

const RepublikIconComponent: FC<RepublikIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.131348V11.4819L12.3592 11.4452C13.2939 11.4452 14.0066 11.6715 14.567 12.4604C14.5872 12.489 14.6164 12.5106 14.6384 12.5376C15.4342 13.5285 16.4415 13.9405 17.6443 13.8579C17.909 13.8395 18.0017 13.7326 17.9978 13.4594L17.9995 11.6693C18.0085 11.3269 17.8989 11.1882 17.5706 11.1946L15.0071 11.2152C15.0071 11.2152 13.5215 11.0559 12.8201 10.2453L12.5329 9.93755C11.8443 9.17508 11.0057 8.80465 10.0227 8.80681C8.9767 8.80897 7.9307 8.80735 6.88471 8.80789C5.29856 8.80789 3.51739 8.80141 1.93125 8.81437V7.05832C4.22559 7.06642 6.67168 7.06427 8.96659 7.06372C9.34542 7.06372 9.66804 7.17712 9.93333 7.44334C10.1812 7.69173 10.4437 7.92663 10.6961 8.17178C11.3492 8.80573 12.1417 9.15834 13.0708 9.17022C14.5889 9.1902 16.1076 9.17292 17.6257 9.18048C17.9079 9.18156 18.0034 9.06438 17.9984 8.81059C17.9888 8.30678 17.9956 7.80243 17.9944 7.29808C17.9933 6.84989 17.9871 6.84341 17.5274 6.84341C15.3792 6.84341 13.231 6.84233 11.0822 6.84341C9.5264 6.84449 9.70963 6.94439 8.61811 5.87089C7.81211 5.07819 6.8656 4.68183 5.69651 4.71477C4.50549 4.74825 3.12338 4.71855 1.93069 4.72719V2.80699H5.09735C5.47393 2.80105 5.77632 2.94577 6.03599 3.219C6.34232 3.54138 6.63515 3.87887 6.94709 4.19476C7.53333 4.78875 8.22185 5.1689 9.03347 5.17322C11.9017 5.18834 14.7699 5.17916 17.6381 5.18672C17.9006 5.18726 18.0029 5.07873 17.9984 4.79901C17.9894 4.22662 17.995 3.65424 17.9944 3.08185C17.9944 2.53862 17.9821 2.52458 17.4875 2.52458C14.1022 2.52458 10.7163 2.53214 7.33098 2.52188C5.47224 2.51648 5.90278 2.73409 4.58418 1.2961C3.86306 0.5088 2.94858 0.132428 1.93237 0.132428L0 0.131348Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const RepublikIcon = memo(RepublikIconComponent)
