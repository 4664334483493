import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { ExternalLinkIcon } from '@radix-ui/react-icons'
import { VirtualItem } from '@tanstack/react-virtual'
import truncateEthAddress from 'truncate-eth-address'

import { cn } from '@/core/utils/classNames'
import { formatCurrency } from '@/core/utils/formatCurrency'
import { getTimeDifferenceString } from '@/core/utils/getTimeDifferenceString'
import { rem } from '@/core/utils/utils'

import { Activity } from '../../hooks/useActivities'

import { appConfig } from '@/config'

type ActivityListRowProps = {
  item: Activity
  virtualRow: VirtualItem
  scrollMargin: number
  accountAddress?: string
}

export const ActivityListRow: FC<ActivityListRowProps> = ({
  item,
  virtualRow,
  scrollMargin,
  accountAddress,
}) => {
  return (
    <div
      className={cn(
        'flex mb-5 text-[0.8125rem] px-2',
        accountAddress?.toLowerCase() === item?.holder && 'bg-cool-gray-850'
      )}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: rem(virtualRow.size),
        backgroundColor:
          accountAddress?.toLowerCase() === item?.holder && item?.side === 'BUY'
            ? 'rgb(50 57 71)'
            : accountAddress?.toLowerCase() === item?.holder &&
                item?.side === 'SELL'
              ? 'rgb(49 49 67)'
              : '',
        transform: `translateY(${virtualRow.start - scrollMargin}px)`,
      }}
    >
      <span className="w-12 flex items-center">
        {getTimeDifferenceString(item?.time)}
      </span>
      <span className="flex flex-1 items-center underline">
        <NavLink to={`/portfolio/${item?.holder}`}>
          {truncateEthAddress(item?.holder)}
        </NavLink>
      </span>
      <span className="flex max-w-8 items-center">{item?.amount}</span>
      <div
        className={cn(
          `flex flex-row w-24 justify-end items-center`,
          item?.side === 'BUY' ? 'text-success' : 'text-danger'
        )}
      >
        <span className="flex max-w-24 overflow-hidden text-ellipsis items-center">
          {formatCurrency(String(item?.value), 2)}
        </span>
      </div>
      <div
        className="flex flex-row w-7 justify-end items-center text-cool-gray-400 cursor-pointer"
        onClick={() =>
          window.open(`${appConfig.arbiscanUrl}/tx/${item?.txHash}`)
        }
      >
        <ExternalLinkIcon />
      </div>
    </div>
  )
}
