import { FC } from 'react'
import { arbitrum, arbitrumSepolia } from 'viem/chains'
import { useAccount, useBalance } from 'wagmi'

import { Card } from '@/core/components/common/Card'
import { usePrice } from '@/core/hooks/usePrice'
import { formatCurrency } from '@/core/utils/formatCurrency'
import { formatPercentageValue } from '@/core/utils/formatPercentageValue'
import { compactNumbers } from '@/core/utils/utils'

import { TokenDetailsData } from '../hooks/useTokenDetails'
import { TransactionData } from '../hooks/useTransaction'
import { TransactionCardSkeleton } from './skeletons/TransactionCardSkeleton'
import { TransactionDrawer } from './TransactionDrawer'

import { appConfig } from '@/config'

interface TradingCardProps {
  tokenId: number | undefined
  tokenDetails?: TokenDetailsData
  transactionData: TransactionData
  isTransactionLoading: boolean
  refetchTransaction: () => void
}

export const TransactionCard: FC<TradingCardProps> = ({
  tokenId,
  tokenDetails,
  transactionData,
  isTransactionLoading,
  refetchTransaction,
}) => {
  const { convertToUsd } = usePrice()
  const account = useAccount()

  const {
    data: balance,
    isLoading,
    refetch: refetchBalance,
  } = useBalance({
    chainId:
      appConfig.stage === 'production' ? arbitrum.id : arbitrumSepolia.id,
    address: account.address,
    token: appConfig.tokenAddress,
    query: {
      enabled: account.isConnected,
    },
  })

  if (isTransactionLoading || isLoading) {
    return <TransactionCardSkeleton />
  }

  return (
    <Card>
      <h3 className="text-center pb-2">Current {appConfig.marketName} Price</h3>
      <ul role="list" className="-my-2">
        <li className="py-2">
          <div className="flex items-center">
            <div className="inline-flex items-center font-semibold text-white flex-row w-full gap-x-3">
              <div className="flex flex-col w-1/2">
                <TransactionDrawer
                  tokenId={tokenId}
                  price={transactionData?.buyPrice}
                  type="buy"
                  triggerClassName="w-full rounded-t-none"
                  amountInBalance={transactionData?.amountInBalance}
                  convertToUsd={convertToUsd}
                  isConnected={account?.isConnected}
                  userId={tokenDetails?.userId}
                  username={tokenDetails?.username}
                  socials={tokenDetails?.socials}
                  accountBalance={balance?.formatted}
                  refetchBalance={refetchBalance}
                  refetchTransaction={refetchTransaction}
                />
              </div>
              <div className="flex flex-col w-1/2">
                <TransactionDrawer
                  tokenId={tokenId}
                  price={transactionData?.sellPrice}
                  type="sell"
                  triggerClassName="w-full rounded-t-none"
                  amountInBalance={transactionData?.amountInBalance}
                  convertToUsd={convertToUsd}
                  isConnected={account?.isConnected}
                  userId={tokenDetails?.userId}
                  username={tokenDetails?.username}
                  socials={tokenDetails?.socials}
                  refetchBalance={refetchBalance}
                  refetchTransaction={refetchTransaction}
                />
              </div>
            </div>
          </div>
        </li>
        <li className="pt-2">
          <div className="flex items-center">
            <div className="flex-1 min-w-0">
              <p className="text-sm text-cool-gray-300">Price change (24h)</p>
            </div>
            <div className="inline-flex items-center text-2xl font-semibold">
              {Number(tokenDetails?.priceChange24h?.toFixed(2)) > 0 && (
                <span className="text-success">
                  {formatPercentageValue(
                    Number(tokenDetails?.priceChange24h),
                    0
                  )}
                </span>
              )}
              {Number(tokenDetails?.priceChange24h?.toFixed(2)) < 0 && (
                <span className="text-danger">
                  {formatPercentageValue(
                    Number(tokenDetails?.priceChange24h),
                    0
                  )}
                </span>
              )}
              {Number(tokenDetails?.priceChange24h?.toFixed(2)) === 0 && (
                <span className="text-cool-gray-300">
                  {formatPercentageValue(
                    Number(tokenDetails?.priceChange24h),
                    0
                  )}
                </span>
              )}
            </div>
          </div>
        </li>
        <li className="py-1">
          <div className="flex items-center">
            <div className="flex-1 min-w-0">
              <p className="text-sm text-cool-gray-300">Holders</p>
            </div>
            <div className="inline-flex items-center text-sm font-semibold text-white">
              {compactNumbers(Number(tokenDetails?.holders))}
            </div>
          </div>
        </li>
        <li className="py-1">
          <div className="flex items-center">
            <div className="flex-1 min-w-0">
              <p className="text-sm text-cool-gray-300">Current Supply</p>
            </div>
            <div className="inline-flex items-center text-sm font-semibold text-white">
              {compactNumbers(transactionData.totalSupply)}
            </div>
          </div>
        </li>
        <li>
          <hr className="border-1 border-cool-gray-700 pb-2 mt-2" />
        </li>
        <li className="py-1">
          <div className="flex items-center">
            <div className="flex-1 min-w-0">
              <p className="text-sm text-cool-gray-300">Amount you hold</p>
            </div>
            <div className="inline-flex items-center text-sm font-semibold text-white">
              {formatCurrency(String(transactionData.amountInBalance))}{' '}
              {appConfig.marketName}
            </div>
          </div>
        </li>
      </ul>
    </Card>
  )
}
