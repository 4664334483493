export const rem = (value: number) => `${value / 16}rem`

export const compactNumbers = (input: number): string => {
  const thresholds = [1_000_000_000_000, 1_000_000_000, 1_000_000, 1_000]

  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 1,
    notation: 'compact',
    compactDisplay: 'short',
  })

  for (const divisor of thresholds) {
    if (input >= divisor) {
      const adjusted = Math.floor(input / (divisor / 10)) * (divisor / 10)

      return formatter.format(adjusted)
    }
  }

  return formatter.format(input)
}
