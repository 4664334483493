import { FC, useCallback, useEffect } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { ScrollRestoration } from 'react-router-dom'

import { SubHeaderType } from '@/core/layouts/MainLayout'
import { NetworkErrorAlert } from '@/core/components/NetworkErrorAlert'
import PullToRefresh from '@/core/components/PullToRefresh'
import { TradingCompetitionBanner } from '@/core/components/TradingCompetitionBanner'

import { ActivityList } from '../components/Activity/ActivityList'
import { BoosterCard } from '../components/BoosterCard'
import { BoosterDetailsCard } from '../components/BoosterDetailsCard'
import { NotFound } from '../components/NotFound'
import { ProcessingToken } from '../components/ProcessingToken'
import { SubHeader } from '../components/SubHeader'
import { TokenInfo } from '../components/TokenInfo'
import { TransactionCard } from '../components/TransactionCard'
import { useActivities } from '../hooks/useActivities'
import { useTokenDetails } from '../hooks/useTokenDetails'
import { useTransaction } from '../hooks/useTransaction'

import { appConfig } from '@/config'

export const TokenDetails: FC = () => {
  const { username } = useParams()
  const { setSubHeader } = useOutletContext<SubHeaderType>()

  const {
    data,
    isLoading: isTokenDetailsLoading,
    error,
    refetch: refetchTokenDetails,
  } = useTokenDetails(username)
  const {
    data: activities,
    isLoading,
    refetch: refetchActivities,
  } = useActivities({ tokenId: data?.tokenId })
  const {
    data: transactionData,
    isLoading: isTransactionLoading,
    refetch: refetchTransaction,
  } = useTransaction({ tokenId: data?.tokenId })

  const handleRefetch = useCallback(
    () =>
      Promise.all([
        refetchActivities(),
        refetchTokenDetails(),
        refetchTransaction(),
      ]),
    [refetchActivities, refetchTokenDetails, refetchTransaction]
  )

  useEffect(() => {
    setSubHeader(
      <SubHeader username={data?.username} socials={data?.socials} />
    )
  }, [data, setSubHeader])

  if (error?.message === '404 - Not found') return <NotFound />
  if (error?.message === '202 - Accepted') return <ProcessingToken />

  return (
    <div className="flex flex-col w-full space-y-3">
      <div className="flex w-full flex-row items-center justify-between">
        <TradingCompetitionBanner prizePool={appConfig.leaderboardPrizePool} />
      </div>
      <div>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-8 w-full">
          <aside className="self-start md:sticky top-0 col-span-1 pt-[106px] -mt-[106px]">
            <PullToRefresh
              className="!h-auto !overflow-visible"
              onRefresh={handleRefetch}
              isPullable={true}
              canFetchMore={true}
              fetchMoreThreshold={100}
              pullDownThreshold={67}
              maxPullDownDistance={95}
              resistance={1}
            >
              <div className="w-full pt-0">
                <BoosterCard
                  transactionData={transactionData}
                  isTransactionLoading={isTransactionLoading}
                  refetchTransaction={refetchTransaction}
                />
                <br />
                <TokenInfo data={data} isLoading={isTokenDetailsLoading} />
                <div className="py-4 md:pt-0">
                  <TransactionCard
                    tokenId={data?.tokenId}
                    tokenDetails={data}
                    transactionData={transactionData}
                    isTransactionLoading={isTransactionLoading}
                    refetchTransaction={refetchTransaction}
                  />
                  <br />
                  <BoosterDetailsCard
                    tokenDetails={data}
                    isTransactionLoading={isTransactionLoading}
                  />
                </div>
              </div>
            </PullToRefresh>
          </aside>
          <main className="col-span-1">
            <ActivityList
              activities={activities}
              isActivitiesLoading={isLoading}
            />
          </main>
          {error?.message && !data && <NetworkErrorAlert />}
        </div>
      </div>
      <ScrollRestoration />
    </div>
  )
}
