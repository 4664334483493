import { Card } from '@/core/components/common/Card'
import { Skeleton } from '@/core/components/common/Skeleton'

export const TransactionCardSkeleton = () => {
  return (
    <Card>
      <h3 className="pb-3">
        <Skeleton className="w-36 h-4 mt-1 mx-auto" />
      </h3>
      <ul role="list" className="-my-2">
        <li className="py-2">
          <div className="flex items-center">
            <div className="inline-flex items-center font-semibold text-white flex-row w-full gap-x-3">
              <div className="flex flex-col w-1/2">
                <Skeleton className="w-full h-[117px]" />
              </div>
              <div className="flex flex-col w-1/2">
                <Skeleton className="w-full h-[117px]" />
              </div>
            </div>
          </div>
        </li>
        <li className="py-1">
          <div className="flex items-center">
            <div className="flex-1 min-w-0">
              <div className="text-sm text-cool-gray-300">
                <Skeleton className="w-28 h-4 mt-2" />
              </div>
            </div>
            <div className="inline-flex items-center text-sm font-semibold text-white">
              <Skeleton className="w-20 h-4 mt-2" />
            </div>
          </div>
        </li>
        <li className="py-1">
          <div className="flex items-center">
            <div className="flex-1 min-w-0">
              <div className="text-sm text-cool-gray-300">
                <Skeleton className="w-14 h-4 mt-2" />
              </div>
            </div>
            <div className="inline-flex items-center text-sm font-semibold text-white">
              <Skeleton className="w-10 h-4 mt-2" />
            </div>
          </div>
        </li>
        <li className="py-1">
          <div className="flex items-center">
            <div className="flex-1 min-w-0">
              <div className="text-sm text-cool-gray-300">
                <Skeleton className="w-24 h-4 mt-2" />
              </div>
            </div>
            <div className="inline-flex items-center text-sm font-semibold text-white">
              <Skeleton className="w-14 h-4 mt-2" />
            </div>
          </div>
        </li>
        <li>
          <hr className="border-1 border-cool-gray-700 mt-2" />
        </li>
        <li className="pt-2 pb-1">
          <div className="flex items-center">
            <div className="flex-1 min-w-0">
              <div className="text-sm text-cool-gray-300">
                <Skeleton className="w-24 h-4 mt-2" />
              </div>
            </div>
            <div className="inline-flex items-center text-sm font-semibold text-white">
              <Skeleton className="w-14 h-4 mt-2" />
            </div>
          </div>
        </li>
      </ul>
    </Card>
  )
}
