import { FC, useMemo, useRef, useState } from 'react'
import ReactPlayer from 'react-player/file'
import { throttle } from 'lodash'

import { Button } from '../common/Button'
import { ProgressBar } from './ProgressBar'

type VideoPlayerProps = {
  className?: string
  url: string
  showControls?: boolean
  showProgressBar?: boolean
}

export const VideoPlayer: FC<VideoPlayerProps> = ({
  className = '',
  url,
  showControls,
  showProgressBar = true,
}) => {
  const videoRef = useRef<ReactPlayer>(null)
  const [playedPercentage, setPlayedPercentage] = useState<number>(0)
  const [isPlaying, setIsPlaying] = useState(false)

  const forceHLS = useMemo(
    () =>
      url?.split('.')?.slice(-1)?.[0] === 'm3u8' &&
      !document
        .createElement('video')
        .canPlayType('application/vnd.apple.mpegurl'),
    [url]
  )

  const onProgress = throttle((e: { played: number }) => {
    setPlayedPercentage(Math.floor(e.played * 100))
  }, 100)

  return (
    <>
      <div
        className={`relative ${className} md:w-auto flex justify-center`}
        onClick={() => setIsPlaying((prev) => !prev)}
      >
        <ReactPlayer
          className="react-player"
          ref={videoRef}
          autoPlay
          controls={showControls}
          playing={isPlaying}
          preload="auto"
          progressInterval={200}
          onProgress={onProgress}
          loop
          width="100%"
          height={'auto'}
          url={url}
          playsinline
          config={{
            forceHLS,
            hlsOptions: {
              maxBufferLength: 1,
              maxBufferSize: 0.2 * 1000 * 1000,
            },
          }}
        />
        {showProgressBar && (
          <div className="absolute bottom-0 w-full">
            <ProgressBar progress={playedPercentage} />
          </div>
        )}
        {!isPlaying && !showControls && (
          <Button
            size="lg"
            variant="purpleOutline"
            className="!bg-black/80 absolute p-4 md:p-6 flex bottom-[15%] justify-center items-end text-1xl md:text-2xl"
            onClick={(e) => {
              setIsPlaying(() => true)
              e.stopPropagation()
              ;(
                videoRef?.current?.getInternalPlayer() as HTMLVideoElement
              ).play()
            }}
          >
            Watch now
          </Button>
        )}
      </div>
    </>
  )
}
