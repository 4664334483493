import { FC } from 'react'

import { VideoPlayer } from './VideoPlayer/VideoPlayer'

import video from '@/assets/intro.mp4'

type HowToEarnVideoProps = {
  showControls?: boolean
  showProgressBar?: boolean
}

export const HowToEarnVideo: FC<HowToEarnVideoProps> = ({
  showControls,
  showProgressBar,
}) => {
  return (
    <VideoPlayer
      className="w-full h-auto max-w-xl bg-black"
      url={video}
      showControls={showControls}
      showProgressBar={showProgressBar}
    />
  )
}
