import { FC, useEffect, useState } from 'react'

import { formatDurationFromSeconds } from '@/core/utils/formatDurationFromSeconds'

interface CountdownProps {
  end?: number
}

export const Countdown: FC<CountdownProps> = ({ end }) => {
  const [timeLeft, setTimeLeft] = useState(0)

  useEffect(() => {
    setTimeLeft(0)

    if (!end) return

    const now = Math.floor(new Date().getTime() / 1000)
    const elapsedTime = end - now

    if (elapsedTime >= 0) {
      const initialTimeLeft = elapsedTime
      setTimeLeft(initialTimeLeft)

      const intervalId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft > 0n) {
            return prevTimeLeft - 1
          } else {
            clearInterval(intervalId)
            return 0
          }
        })
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [end])

  return <>{timeLeft ? formatDurationFromSeconds(timeLeft) : '-'}</>
}
