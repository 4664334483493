import { FC } from 'react'

import { Skeleton } from '@/core/components/common/Skeleton'

export const TokenDetailsSkeleton: FC = () => {
  return (
    <div className="w-full block md:py-4">
      <div className="flex relative">
        <div className="flex w-full flex-row justify-start">
          <div className="flex justify-center items-center w-[7rem] h-[7rem] float-start mr-4 p-2 rounded-full border border-cool-gray-800">
            <Skeleton className="w-[6rem] h-[6rem] !rounded-full" />
          </div>
          <div className="flex flex-col flex-1 mb-2.5">
            <Skeleton className="w-20 h-4 mt-1 mb-1" />
            <Skeleton className="w-28 h-8 mt-2 mb-[0.8125rem]" />
            <Skeleton className="w-4/5 h-10" />
          </div>
        </div>
      </div>
    </div>
  )
}
