import { useState } from 'react'
import { Outlet } from 'react-router-dom'

import { ChatAI } from '@/modules/chat/components'
import { HowToEarnDialog } from '@/modules/tokenList/components/HowToEarnDialog'

import { Footer } from '../components/Footer'
import { OfflineIndicator } from '../components/OfflineIndicator'
import { TopNavBar } from '../components/TopNavBar'
import { UpdateDetector } from '../components/UpdateDetector'

import chatboxIcon from '@/assets/chatbox.svg'
import desktopBg from '@/assets/desktop-bg@2x.png'
import mobileBg from '@/assets/mobile-bg@2x.png'
import { appConfig } from '@/config'

export type SubHeaderType = {
  setSubHeader: (args: React.ReactElement) => React.ReactElement
}

export const MainLayout = () => {
  const [subHeader, setSubHeader] = useState<React.ReactNode>(null)
  const [isOpenDialog, setIsOpenDialog] = useState(false)

  return (
    <div className="flex flex-col h-full min-h-[100vh] w-full">
      <header className="z-40 flex w-full top-0 grow-1 sticky transform-gpu bg-cool-gray-900">
        <div className="w-full h-full relative overflow-hidden safe-area-top">
          <HowToEarnDialog />
          <OfflineIndicator />
          <UpdateDetector />
          <picture className="w-full z-0 top-0 object-cover select-none touch-none absolute opacity-35">
            <source media="(max-width: 768px)" srcSet={mobileBg} />
            <source media="(min-width: 769px)" srcSet={desktopBg} />
            <img
              loading="lazy"
              fetchpriority="low"
              className="w-full"
              src={desktopBg}
              alt=""
            />
          </picture>
          <div className="pt-4 relative z-40">
            <div className="flex max-w-3xl relative mx-auto flex-col md:pb-3">
              <TopNavBar />
            </div>
            <div>{subHeader}</div>
          </div>
        </div>
      </header>
      <main className="flex flex-auto h-full w-full">
        <div className="flex justify-center max-w-3xl mx-auto px-4 md:px-6 h-auto w-full">
          <Outlet context={{ setSubHeader }} />
        </div>
      </main>
      <footer className="z-10">
        <Footer />
      </footer>
      {appConfig.isChatAIVisible && (
        <>
          <div
            className="fixed z-40 bottom-4 right-4 w-12 h-12 cursor-pointer"
            onClick={() => setIsOpenDialog(true)}
          >
            <img src={chatboxIcon} />
          </div>
          <ChatAI isOpen={isOpenDialog} onOpenChange={setIsOpenDialog} />
        </>
      )}
    </div>
  )
}
