import { createSearchParams } from 'react-router-dom'

import { appConfig } from '@/config'

const API = {
  get: async (
    endpoint: string,
    searchParams?: URLSearchParams | Record<string, string>
  ) => {
    try {
      const params = searchParams
        ? '?' + createSearchParams(searchParams).toString()
        : ''
      const response = await fetch(
        `${appConfig.apiBaseUrl}${endpoint}${params}`
      )

      if (response.status === 404) {
        throw new Error('404 - Not found')
      }

      if (response.status === 202) {
        throw new Error('202 - Accepted')
      }

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`)
      }
      return await response.json()
    } catch (error) {
      console.error('GET request failed', error)
      throw error
    }
  },
  post: async (
    endpoint: string,
    body: { [key: string]: unknown },
    externalApiBaseUrl?: string
  ) => {
    try {
      const url = externalApiBaseUrl
        ? `${externalApiBaseUrl}${endpoint}`
        : `${appConfig.apiBaseUrl}${endpoint}`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`)
      }
      return await response.json()
    } catch (error) {
      console.error('POST request failed', error)
      throw error
    }
  },
}

export default API
