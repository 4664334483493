import { useQuery } from '@tanstack/react-query'
import { useAccount } from 'wagmi'

import API from '../services/api'

export const useAlchemyPayUrl = () => {
  const { address } = useAccount()

  return useQuery<{ url: string }>({
    queryKey: ['alchemyPayUrl'],
    queryFn: () => {
      const searchParams = {
        redirect: window.location.href,
      }
      if (address) Object.assign(searchParams, { wallet: address })

      return API.get('/alchemypay/onrampurl', searchParams)
    },
    enabled: false,
  })
}
