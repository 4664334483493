import { useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query'
import { multicall, watchContractEvent } from '@wagmi/core'
import { formatUnits, MulticallParameters, MulticallReturnType } from 'viem'

import API from '@/core/services/api'
import { ILog, SocialMedia } from '@/core/types'
import { flattenPages } from '@/core/utils/flattenPages'

import { IDataItem } from '@/modules/history/hooks/useVirtualList'

import { TransactionData } from './useTransactionTicker'

import { creatorTokenAbi as abi } from '@/abi/creatorTokenAbi'
import { appConfig, queryClient, transportConfig } from '@/config'

interface Booster {
  distributionTimeElapsed: bigint
  distributionTimePeriod: bigint
  pendingDistribution: bigint
}
export interface TokenModel {
  userId: string
  tokenId: string
  username: string
  socials: {
    [key in SocialMedia]: {
      followers: number
      username: string
    }
  }
  price: number
  priceChange24h: number
  priceChange7d: number
  priceChange30d: number
  holders: number
  createdAt: string
  updatedAt: string
  booster: Booster
  totalSupply: number
  volumeChange24h: number
  buy24hVolume: number
  sell24hVolume: number
  total24hVolume: number
}

export interface PageModel {
  items: TokenModel[]
}

const readPriceContract = async (
  tokenIds: string[]
): Promise<{ tokenId: string; value: string; booster: Booster }[] | null> => {
  try {
    const params = {
      address: appConfig.creatorTokenContractAddress,
      abi,
    }
    const contracts = tokenIds.map((tokenId) => ({
      args: [tokenId, 1n],
      functionName: 'getBuyValue',
      ...params,
    })) as unknown as MulticallParameters['contracts']
    const results = await multicall(transportConfig, {
      contracts,
    })
    const contractsBooster = tokenIds.map((tokenId) => ({
      args: [tokenId],
      functionName: 'getCreatorDistribution',
      ...params,
    })) as unknown as MulticallParameters['contracts']
    const resultsBooster = await multicall(transportConfig, {
      contracts: contractsBooster,
    })
    return (results as MulticallReturnType)?.map((multicallResult, index) => {
      const value = (multicallResult?.result as unknown[])?.[0] as bigint
      const booster = {
        distributionTimeElapsed: (
          resultsBooster[index]?.result as unknown[]
        )?.[2] as bigint,
        distributionTimePeriod: (
          resultsBooster[index]?.result as unknown[]
        )?.[3] as bigint,
        pendingDistribution: (
          resultsBooster[index]?.result as unknown[]
        )?.[1] as bigint,
      }
      return {
        tokenId: tokenIds[index],
        value: formatUnits(value, 18),
        booster,
      }
    })
  } catch (e) {
    console.log('Error reading contract', e)
  }
  return null
}

const updatePrice = async (tokenIds: string[]) => {
  const prices = await readPriceContract(tokenIds)
  if (!prices) return

  queryClient.setQueryData(
    ['tokens'],
    (
      oldData: InfiniteData<PageModel> | undefined
    ): InfiniteData<PageModel> | undefined => {
      if (!oldData) return oldData
      const updatedPages = oldData.pages.map((page) => {
        return {
          ...page,
          items: page.items.map((item) => {
            const priceUpdate = prices.find((price) => {
              return price.tokenId === String(item.tokenId)
            })
            if (priceUpdate) {
              return {
                ...item,
                price: parseFloat(priceUpdate.value),
                booster: priceUpdate.booster,
              }
            }
            return item
          }),
        }
      })
      return {
        ...oldData,
        pages: updatedPages,
      }
    }
  )
}

const filterTokenIds = (tokenIds: string[]): string[] => {
  const data: InfiniteData<PageModel> | undefined = queryClient.getQueryData([
    'tokens',
  ])

  if (!data) return []

  return tokenIds.filter((tokenId) => {
    return data.pages.some((page: PageModel) =>
      page.items.some((item: TokenModel) => {
        return String(item.tokenId) === tokenId
      })
    )
  })
}

interface DataItemsResponse {
  items: IDataItem[]
  pagination?: {
    page: number
    totalPages: number
  }
}

export const useVirtualList = () => {
  const [searchParams] = useSearchParams()

  if (!searchParams.get('type')) {
    searchParams.set('type', 'hot')
  }

  const {
    data,
    error,
    fetchNextPage,
    isLoading,
    hasNextPage,
    isSuccess,
    isFetchingNextPage,
  } = useInfiniteQuery<DataItemsResponse, Error>({
    queryKey: ['tokens'],
    queryFn: ({ pageParam = 1 }) => {
      const q = searchParams.get('q')
      if (q) searchParams.set('q', q.toLowerCase())
      searchParams.set('size', String(50))
      if (pageParam != 0) searchParams.set('page', String(pageParam))

      if (searchParams.get('type') === 'all')
        searchParams.set('type', 'price-desc')

      return API.get('/market/tokens', searchParams)
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage: DataItemsResponse) => {
      const nextPage = Number(lastPage?.pagination?.page) + 1
      return nextPage <= Number(lastPage?.pagination?.totalPages)
        ? nextPage
        : undefined
    },
  })

  const refetch = useCallback(async () => {
    await queryClient.setQueryData<{
      pages: unknown[]
      pageParams: (string | undefined)[]
    }>(['tokens'], (data) => ({
      pages: data?.pages?.slice(0, 1) || [],
      pageParams: data?.pageParams?.slice(0, 1) || [],
    }))
    await queryClient.invalidateQueries({ queryKey: ['tokens'] })
    window.scrollTo(0, 0)
  }, [])

  const flatData = useMemo(
    () => flattenPages(data, 'items') as TokenModel[],
    [data]
  )

  useEffect(() => {
    const unwatch = watchContractEvent(transportConfig, {
      address: appConfig.creatorTokenContractAddress,
      abi,
      eventName: 'Execute',
      onLogs(logs: unknown) {
        const tokenIds = (logs as unknown as ILog[])?.map((log) => {
          queryClient.setQueryData(
            ['transactionTicker'],
            (data: TransactionData[]) => {
              if (!log.args) return data
              return [log.args, ...data.slice(0, 9)]
            }
          )
          return String(log.args?.tokenId)
        })

        updatePrice(filterTokenIds(tokenIds))
      },
      onError(error) {
        console.error('Logs error', error)
      },
    })

    return () => {
      unwatch()
    }
  }, [])

  return {
    data: flatData,
    fetchNextPage,
    isSuccess,
    hasNextPage,
    isFetchingNextPage,
    error,
    refetch,
    isLoading,
  }
}
