import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/core/components/common/Select'

interface RaceSelectorProps {
  validRaces: number[] | undefined
  isError: boolean
}

export const RaceSelector: FC<RaceSelectorProps> = ({
  validRaces,
  isError,
}) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [value, setValue] = useState<string>()

  useEffect(() => {
    if (!validRaces) return

    const raceParam = searchParams?.get('race')

    if (raceParam && validRaces.includes(Number(raceParam))) {
      setValue(raceParam)
    } else {
      setValue(String(validRaces[0]))
    }
  }, [navigate, searchParams, validRaces, isError])

  const onRaceChange = useCallback(
    (value: string) => {
      if (value === String(validRaces?.[0])) {
        searchParams.delete('race')
      } else {
        searchParams.set('race', value)
      }

      navigate({ search: searchParams.toString() })
    },
    [navigate, searchParams, validRaces]
  )

  return (
    <Select onValueChange={onRaceChange} value={value}>
      <SelectTrigger className="!p-0 !w-20 !h-auto" disabled={isError}>
        <SelectValue placeholder="Race" />
      </SelectTrigger>
      <SelectContent className="!w-28">
        {validRaces?.map((race) => (
          <SelectItem key={race} value={String(race)}>
            Race {race}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
