import {
  ChangeEvent,
  FC,
  LegacyRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RefetchOptions } from '@tanstack/react-query'
import { cx } from 'class-variance-authority'
import { debounce } from 'lodash'

import { SortMethod } from '@/core/types'
import { Button } from '@/core/components/common/Button'
import { SearchInput } from '@/core/components/common/SearchInput'

import { TransactionTicker } from './TransactionTicker'

import { FireIcon } from '@/assets/FireIcon'
import { LeafIcon } from '@/assets/LeafIcon'

interface SubHeaderProps {
  refetch: (options?: RefetchOptions | undefined) => Promise<void>
}

export const SubHeader: FC<SubHeaderProps> = ({ refetch }) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const sortMethod = searchParams?.get('type')

  const inputRef = useRef<HTMLInputElement>()

  const [searchQuery, setSearchQuery] = useState(searchParams?.get('q') || '')

  const changeSortMethod = useCallback(
    async (sortMethodOption: SortMethod) => {
      if (
        sortMethodOption &&
        sortMethodOption !== 'hot' &&
        sortMethod !== sortMethodOption
      ) {
        searchParams.set('type', sortMethodOption || '')

        if (inputRef.current) inputRef.current.value = ''
        searchParams.delete('q')
        setSearchQuery('')
      } else {
        searchParams.delete('type')
      }

      await navigate({ search: searchParams.toString() })
      refetch()
    },
    [navigate, refetch, searchParams, sortMethod]
  )

  const onSearchQueryChange = async (searchQueryOption: string) => {
    setSearchQuery(searchQueryOption)
    if (searchQuery !== searchQueryOption) {
      searchParams.set('q', searchQueryOption)
      searchParams.delete('type')
    } else {
      searchParams.delete('q')
      setSearchQuery('')
    }

    if (searchQueryOption === '') searchParams.delete('q')

    await navigate({ search: searchParams.toString() })
    setTimeout(() => {
      refetch()
    }, 100)
  }

  useEffect(() => {
    setSearchQuery(searchParams.get('q') || '')
  }, [searchParams])

  return (
    <>
      <div className="max-w-3xl">
        <div className="flex w-full flex-col md:flex-row md:pt-2">
          <div className="flex grow pt-4 pb-2 md:py-0 order-2 md:order-1">
            <Button
              className={cx('border-b rounded-none', {
                'border-b-white': !sortMethod || sortMethod === 'hot',
                'border-b-cool-gray-700': sortMethod || sortMethod !== 'hot',
              })}
              variant="ghost"
              size="sm"
              onClick={() => changeSortMethod('hot')}
            >
              <FireIcon className="pr-1" isActive={false} />
              Hot
            </Button>
            <Button
              className={cx('border-b rounded-none', {
                'border-b-white': sortMethod === 'new',
                'border-b-cool-gray-700': sortMethod !== 'new',
              })}
              variant="ghost"
              size="sm"
              onClick={() => changeSortMethod('new')}
            >
              <LeafIcon className="pr-1" isActive={false} />
              New
            </Button>
            <Button
              className={cx('border-b rounded-none mr-4', {
                'border-b-white': sortMethod === 'all',
                'border-b-cool-gray-700': sortMethod !== 'all',
              })}
              variant="ghost"
              size="sm"
              onClick={() => changeSortMethod('all')}
            >
              All
            </Button>
            <SearchInput
              className="w-full md:w-auto"
              ref={inputRef as LegacyRef<HTMLInputElement>}
              searchQuery={searchQuery}
              handleClear={() => {
                if (inputRef?.current) {
                  inputRef.current.value = ''
                  onSearchQueryChange('')
                }
              }}
              onChange={debounce(
                (e: ChangeEvent<HTMLInputElement>) =>
                  onSearchQueryChange(e.target.value),
                500
              )}
              defaultValue={searchQuery}
            />
          </div>
          <div className="grow order-1 md:order-2 overflow-hidden relative md:w-72">
            <div className="pt-2 md:pb-2 md:pt-0">
              <TransactionTicker />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
