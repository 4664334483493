import { FC } from 'react'
import { Link } from 'react-router-dom'
import { cx } from 'class-variance-authority'
import truncateEthAddress from 'truncate-eth-address'

import { formatCurrency } from '@/core/utils/formatCurrency'

import { IDataItem } from '../hooks/useLeaderboard'
import { Rank } from './Rank'
import { rowHeight } from './StickyList'

type RowProps = {
  item: IDataItem
  isActiveTrader: boolean
}

export const Row: FC<RowProps> = ({ item, isActiveTrader }) => {
  return (
    <div
      id={isActiveTrader ? 'sticky-row' : undefined}
      className={cx(
        `w-full h-[${rowHeight}px] flex flex-row items-center bg-cool-gray-900 text-sm [&:nth-child(-n+4)]:rounded-md [&:nth-child(-n+3)]:mb-1`,
        {
          'sticky bottom-0': isActiveTrader,
          '!bg-cool-gray-700': item.rank <= 3,
        }
      )}
    >
      <div className="w-24 flex items-center">
        <Rank rank={item.rank} isActiveTrader={isActiveTrader} />
      </div>
      <div className="w-full h-[${rowHeight}px] flex flex-row justify-between items-center">
        <Link
          to={`/portfolio/${item.address}`}
          className="text-cool-gray-200 cursor-pointer font-medium underline"
        >
          {truncateEthAddress(item.address)}
        </Link>
        <span className="mr-6">{formatCurrency(String(item.volume), 2)}</span>
      </div>
    </div>
  )
}
