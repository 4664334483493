import { useCallback, useRef } from 'react'
import { useWindowVirtualizer } from '@tanstack/react-virtual'

import { ConnectWalletAlert } from '@/core/components/ConnectWalletAlert'
import { HowToEarnVideo } from '@/core/components/HowToEarnVideo'
import PullToRefresh from '@/core/components/PullToRefresh'
import { TradingCompetitionBanner } from '@/core/components/TradingCompetitionBanner'
import { cn } from '@/core/utils/classNames'
import { rem } from '@/core/utils/utils'

import { useVirtualList } from '../hooks/useVirtualList'
import { CurrentValueCard } from './CurrentValueCard'
import { Row } from './Row'
import { RowSkeleton } from './RowSkeleton'

import { appConfig } from '@/config'

export const VirtualList = ({ walletId }: { walletId: `0x${string}` }) => {
  const { data, refetch, isLoading, isError, isSuccess } = useVirtualList({
    walletId,
  })
  const listRef = useRef<HTMLDivElement | null>(null)

  const rowVirtualizer = useWindowVirtualizer({
    count: data?.items?.length || 0,
    scrollMargin: 0,
    estimateSize: useCallback(() => 48, []),
    overscan: 5,
  })

  const Skeleton = useCallback(() => {
    return (
      <div
        className="virtual-container divide-y divide-cool-gray-850"
        style={{
          height: rem(rowVirtualizer.getTotalSize()),
          width: '100%',
          position: 'relative',
        }}
      >
        {[...Array(15)].map((_: unknown, index: number) => (
          <RowSkeleton key={index} />
        ))}
      </div>
    )
  }, [rowVirtualizer])

  return (
    <div
      ref={listRef}
      className={cn(
        `top-0 w-full h-full flex flex-col relative mb-3`,
        isLoading || !walletId ? 'overflow-hidden' : ''
      )}
    >
      <div
        className={cn(
          'flex flex-col items-center justify-between',
          isLoading || !walletId ? 'z-41' : ''
        )}
      >
        <TradingCompetitionBanner prizePool={appConfig.leaderboardPrizePool} />
      </div>
      <div
        className={cn(
          'flex flex-col items-center pt-2 z-20 sticky justify-between bg-cool-gray-900',
          isLoading || !walletId
            ? 'safe-top-0'
            : 'safe-top-[92px] md:safe-top-[96px]'
        )}
      >
        <CurrentValueCard walletId={walletId} />
        {(!walletId || !!data?.items?.length || isLoading) && (
          <div
            className={cn(
              'flex-col bg-cool-gray-900 w-full sticky z-10 top-[104px] md:top-[108px]',
              isLoading || !walletId ? '!top-0' : ''
            )}
          >
            <div className="top-0 flex w-full text-xs text-cool-gray-400 py-2 gap-x-2">
              <div className="flex flex-1 items-center md:hidden">
                {appConfig.marketName} (Amount) / <br />
                Change 24h
              </div>
              <div className="md:flex flex-1 items-center hidden">
                {appConfig.marketName}
              </div>
              <div className="md:flex w-10 md:w-20 text-right hidden items-center justify-end">
                Popularity
              </div>
              <div className="md:flex w-16 md:w-16 text-right items-center justify-end hidden">
                Amount
              </div>
              <div className="md:flex w-16 md:w-24 text-right items-center justify-end hidden">
                Price({appConfig.currency})
              </div>
              <div className="md:flex w-16 md:w-24 text-right items-center justify-end hidden">
                Cash Out Value({appConfig.currency})
              </div>
              <div className="flex w-16 md:w-20 text-right items-center justify-end md:hidden">
                Price({appConfig.currency})
              </div>
              <div className="md:flex items-center justify-end w-24 md:w-32 hidden">
                Change 24h
              </div>
              <div className="flex w-20 text-right items-center justify-end md:hidden">
                Cash Out Value({appConfig.currency})
              </div>
            </div>
          </div>
        )}
      </div>
      <ConnectWalletAlert walletId={walletId} />
      {!walletId && <Skeleton />}
      {isLoading ? (
        <Skeleton />
      ) : (
        walletId && (
          <PullToRefresh
            onRefresh={refetch}
            //onFetchMore={fetchNextPage}
            isPullable={true}
            canFetchMore={true}
            fetchMoreThreshold={100}
            pullDownThreshold={67}
            maxPullDownDistance={95}
            resistance={1}
          >
            {isSuccess && data?.items?.length === 0 ? (
              <div className="flex flex-col items-center pt-8">
                <span className="text-lg mx-6 text-center">
                  Buy your first{' '}
                  <span className="animated-purple-text font-bold text-xl">
                    {appConfig.marketName}
                  </span>
                  <br />
                  to see them here
                </span>
                <div className="pt-8 pb-12">
                  <HowToEarnVideo showControls showProgressBar={false} />
                </div>
              </div>
            ) : isError ? (
              <div className="flex flex-col items-center pt-8">
                Something went wrong. Please try again
              </div>
            ) : (
              <div
                className="virtual-container divide-y divide-cool-gray-850"
                style={{
                  height: rem(rowVirtualizer.getTotalSize()),
                  width: '100%',
                  position: 'relative',
                }}
              >
                {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                  <Row
                    key={virtualRow.index}
                    item={data?.items?.[virtualRow.index]}
                    virtualRow={virtualRow}
                  />
                ))}
              </div>
            )}
          </PullToRefresh>
        )
      )}
    </div>
  )
}
