import { FC } from 'react'

import { Skeleton } from '@/core/components/common/Skeleton'

export const RowSkeleton: FC = () => {
  return (
    <div className="flex mb-5 px-2">
      <span className="w-12 flex items-center">
        <Skeleton className="w-8 h-2.5 !rounded-full" />
      </span>
      <span className="flex flex-1 items-center underline">
        <Skeleton className="w-28 h-2.5 !rounded-full" />
      </span>
      <span className="flex max-w-8 items-center">
        <Skeleton className="w-6 h-2.5 !rounded-full" />
      </span>
      <div className="flex flex-row w-24 justify-end items-center">
        <span className="flex max-w-24 overflow-hidden text-ellipsis items-center">
          <Skeleton className="w-20 h-2.5 !rounded-full" />
        </span>
      </div>
      <div className="flex flex-row w-7 justify-end items-center ">
        <Skeleton className="w-4 h-2.5 !rounded-full" />
      </div>
    </div>
  )
}
