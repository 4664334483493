export const FireIcon = ({
  isActive = false,
  className = '',
}: {
  isActive?: boolean
  className?: string
}) => {
  return (
    <svg
      className={className}
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_467_7072)">
        <path
          d="M9.18832 17.9996C9.18832 17.9996 9.18819 17.9999 9.18806 18C11.2738 16.9859 13.0713 15.1497 13.5224 12.8747C13.8077 11.4367 13.54 9.92913 12.9808 8.57392C12.4216 7.21871 11.583 5.99794 10.6954 4.83109C11.2121 6.38667 11.205 8.11126 10.6755 9.66244C10.8199 8.59224 10.5661 7.473 9.9743 6.56974C9.55318 5.9271 8.97195 5.39568 8.59483 4.7262C7.79479 3.30648 8.04921 1.51584 8.64747 0C7.2026 0.876035 5.859 1.96366 4.89007 3.34803C3.92114 4.73239 3.34895 6.43621 3.51783 8.11745C2.71714 7.59596 2.51858 6.43182 2.68308 5.49115C1.80937 6.70599 1.00636 7.87851 0.502156 9.30158C0.0360122 10.6171 -0.170675 12.0532 0.16645 13.4247C0.166966 13.4271 0.167611 13.4294 0.168127 13.4317C0.655946 15.4039 2.19655 16.9782 3.95404 17.9975C3.71071 17.2572 3.47435 16.5022 3.45048 15.7233C3.42662 14.9445 3.64091 14.1265 4.18976 13.5732C4.31233 14.345 4.87627 15.0309 5.60986 15.3003C5.21107 14.1058 5.23261 12.7755 5.66973 11.5945C6.10684 10.4135 6.95669 9.38983 8.03709 8.7428C7.75105 9.69392 7.88652 10.7637 8.40079 11.6135C8.7432 12.1792 9.23786 12.6417 9.57331 13.2117C10.4275 14.6632 10.0836 16.5727 9.18858 17.9994L9.18832 17.9996Z"
          fill="url(#paint0_linear_467_7072)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_467_7072"
          x1="1.60703e-05"
          y1="8.99994"
          x2="13.6379"
          y2="8.99994"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.02" stopColor={isActive ? '#000000' : '#FCED21'} />
          <stop offset="0.84" stopColor={isActive ? '#000000' : '#F15A24'} />
        </linearGradient>
        <clipPath id="clip0_467_7072">
          <rect width="13.6379" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
