import { useInfiniteQuery } from '@tanstack/react-query'

import API from '@/core/services/api'
import { SocialMedia } from '@/core/types'
import { useNetwork } from '@/core/hooks/useNetwork'
import { flattenPages } from '@/core/utils/flattenPages'

export interface IDataItem {
  userId: string
  tokenId: string
  username: string
  socials: {
    [key in SocialMedia]: {
      followers: number
      username: string
    }
  }
  price: number
  priceChange24h: number
  priceChange7d: number
  priceChange30d: number
  holders: number
  createdAt: string
  updatedAt: string
  time: string
  amount: number
  value: number
  side: 'BUY' | 'SELL'
  txHash: string
}

interface HistoryResponse {
  items: IDataItem[]
}

export const useVirtualList = ({
  walletId,
}: {
  walletId: `0x${string}` | undefined
}) => {
  const { isOnline } = useNetwork()
  const itemsPerPage = 50

  const {
    data,
    isError,
    refetch,
    fetchNextPage,
    isLoading,
    isSuccess,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<HistoryResponse, Error>({
    queryKey: ['my_trades'],
    queryFn: ({ pageParam = 0 }) =>
      API.get(`/market/activities/users/${walletId}`, {
        first: String(itemsPerPage),
        skip: String(pageParam),
      }),
    initialPageParam: 0,
    getNextPageParam: (
      lastPage: HistoryResponse,
      allPages: HistoryResponse[]
    ) => {
      const totalFetchedItems = allPages.flatMap((page) => page?.items).length

      return lastPage?.items?.length === itemsPerPage
        ? totalFetchedItems
        : undefined
    },
    enabled: isOnline && !!walletId,
  })

  return {
    data: flattenPages(data, 'items') as IDataItem[],
    fetchNextPage,
    refetch,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    isSuccess,
    isError,
  }
}
